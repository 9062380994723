import React, { useState } from 'react';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Card from '../../../../Common/Card';
import Button from '../../../../Common/Button';
import { useHistory } from 'react-router-dom';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import Label from '../../../../Common/Label';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DatePicker from '../../../../Common/DatePicker';
import { createAssetRentalRequestApi } from '../../../../../api/asset';
import useAsync from '../../../../../util/hooks/useAsync';
import AssetCategorySingleTreeSelect from './Components/AssetCategorySingleTreeSelect';
import DepartmentSingleTreeSelect from './Components/DepartmentSingleTreeSelect';
import cx from 'classnames';
import ValidationModal from '../../../Components/Modal/ValidationModal';
import { Col, Row } from 'reactstrap';
import TextInput from '../../../../Common/Input/TextInput';

const AssetRentalRequestApplication = () => {
    const t = useTranslation('AssetRentalRequest');
    const columnT = useTranslation('CommonColumn');
    const history = useHistory();
    const { groupNum, groupName } = useSelector(state => state.UserInfo.userInfo);

    const [newRentalRequest, setNewRentalRequest] = useState({
        categoryCode: null,
        count: 1,
        usingDepNum: null,
        ownDepNum: null,
    });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [validationStrings, setValidationStrings] = useState([]);
    const [openValidationFailModal, setOpenValidationFailModal] = useState(false);

    const { userInfo } = useSelector(state => state.UserInfo);

    const { promise: createAssetRentalRequest } = useAsync({
        promise: createAssetRentalRequestApi,
        resolve: () => {
            history.push('/asset-rental/request');
        },
    });

    const handleGoBackClick = () => {
        history.push('/asset-rental/request');
    };

    const handleValidationCheck = () => {
        let tempArr = [];
        if (!newRentalRequest.ownDepNum) {
            tempArr.push('Select the department');
        }
        if (!(startDate && endDate)) {
            tempArr.push('Select the rental period');
        }
        if (!newRentalRequest.categoryCode) {
            tempArr.push('Select the category');
        }
        if (newRentalRequest.count < 1 || newRentalRequest.count > 100000) {
            tempArr.push('Select more than one for quantity');
        }

        if (tempArr.length > 0) {
            setValidationStrings(tempArr);
            setOpenValidationFailModal(true);
        } else {
            setValidationStrings([]);
            toggleApplicationConfirmModal();
        }
    };

    const { toggleModal: toggleApplicationConfirmModal, Modal: ApplicationConfirmModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Confirm of the asset rental application'),
        },
        confirmText: t('Do you want to apply for the asset rental?'),
        okCallback: createAssetRentalRequest,
        callbackParam: { ...newRentalRequest, startDate: startDate, endDate: endDate, usingDepNum: groupNum },
    });

    return (
        <>
            <Card
                className={'application-grid-container'}
                bodyClassName={'overflow-visible'}
                header={{
                    title: t('Asset Rental Request Application'),
                    titleIcon: 'icon-create',
                    action: (
                        <>
                            <Button className={'btn-gray btn-back mr-1'} onClick={handleGoBackClick}>
                                {t('Back')}
                            </Button>
                            <div className="pnt-border border-h" />
                            <Button
                                className={'btn-icon btn-brand'}
                                iconClassName={'icon-check'}
                                onClick={handleValidationCheck}
                            >
                                {t('Submit')}
                            </Button>
                        </>
                    ),
                }}
            >
                <div className={'application-contents-container'}>
                    <Label
                        name={columnT('Applicant')}
                        value={userInfo.userName}
                        labelGroupClassName={'details-input-group'}
                        labelValueClassName={'label-dot'}
                    />

                    <Label
                        name={columnT('Requesting department')}
                        value={groupName}
                        labelGroupClassName={'details-input-group'}
                        labelValueClassName={'label-dot'}
                    />

                    <Label
                        name={columnT('Application date')}
                        value={moment().format('YYYY-MM-DD')}
                        labelGroupClassName={'details-input-group'}
                        labelValueClassName={'label-dot'}
                    />

                    <Label
                        name={columnT('Application status')}
                        value={t('Application')}
                        labelGroupClassName={'details-input-group'}
                        labelValueClassName={'label-dot'}
                    />

                    <Label
                        name={columnT('Lending department')}
                        labelGroupClassName={'details-input-group required-input'}
                        labelValueClassName={'label-dot color-brand'}
                        value={
                            <Row className={'w-100'}>
                                <Col md="12" lg="3">
                                    <DepartmentSingleTreeSelect
                                        onChange={selected => {
                                            setNewRentalRequest({ ...newRentalRequest, ownDepNum: selected.groupNum });
                                        }}
                                    />
                                </Col>
                            </Row>
                        }
                    />

                    <Label
                        name={columnT('Rental period')}
                        value={
                            <Row className={'w-100'}>
                                <Col md="12" lg="3">
                                    <DatePicker
                                        value={startDate}
                                        handleChange={selected => {
                                            setStartDate(selected);
                                        }}
                                        minDate={moment().valueOf()}
                                        maxDate={moment.unix(endDate).valueOf()}
                                        withoutTime
                                    />
                                </Col>
                                <div className={'Rental-date-picker'}>
                                    <div>~</div>
                                </div>
                                <Col md="12" lg="3">
                                    <DatePicker
                                        value={endDate}
                                        handleChange={selected => {
                                            setEndDate(selected);
                                        }}
                                        minDate={moment.unix(startDate).valueOf()}
                                        disabled={!startDate}
                                        withoutTime
                                    />
                                </Col>
                            </Row>
                        }
                        labelGroupClassName={cx('details-input-group rental-period-container')}
                        labelValueClassName={'label-dot validation color-brand'}
                    />

                    <Label
                        name={columnT('Category')}
                        labelGroupClassName={'details-input-group required-input'}
                        labelValueClassName={'label-dot color-brand'}
                        value={
                            <Row className={'w-100'}>
                                <Col md="12" lg="3">
                                    <AssetCategorySingleTreeSelect
                                        onChange={selected => {
                                            setNewRentalRequest({ ...newRentalRequest, categoryCode: selected.value });
                                        }}
                                    />
                                </Col>
                            </Row>
                        }
                    />

                    <Label
                        name={columnT('Quantity')}
                        value={
                            <div style={{ width: '70px' }}>
                                <TextInput
                                    name={'quantity'}
                                    type={'number'}
                                    className={'pnt-input w-100 form-must'}
                                    value={newRentalRequest.count}
                                    min={1}
                                    max={99999}
                                    handleChange={e => {
                                        const { value } = e.target;
                                        setNewRentalRequest({ ...newRentalRequest, count: parseInt(value) });

                                        if (value > 100000) {
                                            setNewRentalRequest({ ...newRentalRequest, count: 99999 });
                                        } else if (value < 1) {
                                            setNewRentalRequest({ ...newRentalRequest, count: 1 });
                                        }
                                    }}
                                />
                            </div>
                        }
                        labelGroupClassName={'details-input-group required-input'}
                        labelValueClassName={'label-dot'}
                    />
                </div>
            </Card>
            <ApplicationConfirmModal />
            <ValidationModal
                header={{ title: 'Request Fail' }}
                validationText={validationStrings}
                toggleModal={() => setOpenValidationFailModal(false)}
                okCallback={() => setOpenValidationFailModal(false)}
                initModal={openValidationFailModal}
            />
        </>
    );
};

export default AssetRentalRequestApplication;
