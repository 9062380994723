import React from 'react';
import { ErrorHandleSwitch } from '../Components/Router';
import { Route } from 'react-router-dom';
import CustomFilter from './CustomFilter';
import AssetDetail from './AssetDetail';
import QuickSearch from './QuickSearch';

const Assets = ({ match }) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/quickSearch`} component={QuickSearch} />
            <Route exact path={`${match.path}/list/:filterNum([0-9]+)`} component={CustomFilter} />
            <Route exact path={`${match.path}/detail/:targetNum([0-9]+)`} component={AssetDetail} />
        </ErrorHandleSwitch>
    );
};

export default Assets;
