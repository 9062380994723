import React, { createContext, useMemo, useEffect, useReducer, useState } from 'react';
import Card from '../../../../Common/Card';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Button from '../../../../Common/Button';
import { useHistory, useParams } from 'react-router-dom';
import Label from '../../../../Common/Label';
import useAsync from '../../../../../util/hooks/useAsync';
import {
    createFlowSchemeInfoApi,
    getFlowSchemeInfoApi,
    getFlowSchemeListInfoApi,
    getMappedStatusListInfoApi,
    getStatusFlowCategoryMapInfoApi,
    getStatusListInfoApi,
    getStatusSchemeListInfoApi,
    getTransitionListInfoApi,
    updateFlowSchemeInfosApi,
} from '../../../../../api/status';
import flowSchemeReducer, { initialState, setFlowScheme, setFlowStatusList, setFlowTransitionList } from './reducer';
import styled from 'styled-components';
import Switch from 'react-switch';
import TextInput from '../../../../Common/Input/TextInput';
import Select from '../../../../Common/Select';
import { useDispatch, useSelector } from 'react-redux';
import {
    setFlowSchemeListInfo,
    setMappedStatusListInfo,
    setStatusFlowCategoryMapInfo,
    setStatusListInfo,
    setStatusSchemeListInfo,
    setTransitionList,
} from '../../../../../reducers/Common/StatusInfo';
import FlowGraph from './FlowGraph';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import { trim } from '../../../../../util/common/util';
import SingleSelect from '../../../../Common/Select/SingleSelect';
import cx from 'classnames';
import AddTransitionModal from './FlowGraph/AddTransitionModal';

export const FlowSchemeStateContext = createContext();
export const FlowSchemeDispatchContext = createContext();

const StyledSwitch = styled(Switch)`
    .react-switch-bg {
        border-radius: 7px !important;
    }

    .react-switch-handle {
        border-radius: 7px !important;
    }
`;

const addIcon = icon => {
    const handle = document.querySelector('.react-switch-handle');
    if (icon) {
        handle.innerHTML = '<span class="icon-check icon-filter-white ml-1 mt-1"/>';
    } else {
        handle.innerHTML = '<span class="icon-close icon-filter-white ml-1 mt-1"/>';
    }
};

const INPUT_WIDTH = 350;

const sample = {
    nodes: [
        {
            id: 1,
            // title: 'Node A',
            x: 300,
            y: 100,
            type: 1,
        },
        {
            id: 2,
            // title: 'Node A',
            x: 600,
            y: 100,
            type: 2,
        },
        {
            id: 3,
            // title: 'Node A',
            x: 900,
            y: 100,
            type: 3,
        },
        {
            id: 4,
            // title: 'Node B',
            x: 300,
            y: 300,
            type: 4,
        },
        {
            id: 5,
            // title: 'Node B',
            x: 600,
            y: 300,
            type: 5,
        },
    ],
    edges: [
        {
            source: 1,
            target: 2,
            type: 1,
            handleText: 'test1',
        },
        {
            source: 2,
            target: 4,
            type: 2,
            handleText: 'test2',
        },
    ],
};

const FlowSchemeDetail = () => {
    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(flowSchemeReducer, initialState);
    const { flowSchemeInfo, flowStatusList, flowTransitionList, flowDrawJson } = state;
    const { flowNum } = useParams();
    const [savedFlowNum, setSavedFlowNum] = useState();
    const [flowSchemeDetailInfo, setFlowSchemeDetailInfo] = useState(flowSchemeInfo);
    const [transitionDetailModal, setTransitionDetailModal] = useState({ isOpen: false, transitionInfo: null });
    const toggleTransitionDetailModal = transitionInfo => {
        setTransitionDetailModal({
            isOpen: !transitionDetailModal.isOpen,
            transitionInfo,
        });
    };
    const t = useTranslation('Status');
    const history = useHistory();
    const { statusSchemeListInfo, flowSchemeListInfo, mappedStatusInfo, transitionList, statusListInfo } = useSelector(
        state => state.StatusInfo,
    );
    const statusSchemeList = statusSchemeListInfo.rows.map(v => ({
        value: v.schemeNum,
        label: v.schemeName,
    }));
    const graphData = useMemo(() => {
        let drawJson = { nodes: [], edges: [] };
        if (flowDrawJson && flowDrawJson.nodes) {
            drawJson = flowDrawJson;
        }
        const { nodes, edges } = drawJson;
        return {
            nodes: flowStatusList.map(v => ({
                ...nodes.find(draw => draw.id === v.statusNum),
                id: v.statusNum,
                type: v.statusNum,
                data: v,
            })),
            edges: flowTransitionList.map(v => ({
                ...edges.find(draw => draw.id === v.actionNum),
                source: v.startStatusNum,
                target: v.endStatusNum,
                type: v.actionNum,
                handleText: v.actionName,
                data: v,
            })),
        };
    }, [flowStatusList, flowTransitionList, flowDrawJson]);

    const handleChange = e => {
        const { value, name } = e.currentTarget;
        setFlowSchemeDetailInfo({ ...flowSchemeDetailInfo, [name]: value });
    };

    useAsync({
        promise: getStatusSchemeListInfoApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            storeDispatch(setStatusSchemeListInfo(res));
        },
    });
    useAsync({
        promise: getFlowSchemeListInfoApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            storeDispatch(setFlowSchemeListInfo(res));
        },
    });
    useAsync({
        promise: getStatusFlowCategoryMapInfoApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            storeDispatch(setStatusFlowCategoryMapInfo(res));
        },
    });

    useAsync({
        promise: getMappedStatusListInfoApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            storeDispatch(setMappedStatusListInfo(res));
        },
    });

    useAsync({
        promise: getStatusListInfoApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            storeDispatch(setStatusListInfo(res));
        },
    });

    useAsync({
        promise: getTransitionListInfoApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            storeDispatch(setTransitionList(res));
        },
    });

    const { promise: getFlowSchemeInfo } = useAsync({
        promise: getFlowSchemeInfoApi,
        resolve: res => {
            dispatch(setFlowScheme(res));
            // dispatch(setStatusSchemeListInfo(res));
        },
    });

    const { promise: createFlowSchemeInfo } = useAsync({
        promise: createFlowSchemeInfoApi,
        resolve: res => {
            const { pkName, pkValue } = res;
            if (pkName === 'flowNum') {
                setSavedFlowNum(pkValue);
                // getFlowSchemeInfo({ [pkName]: pkValue });
                toggleSaveCompleteModal();
            }
        },
        reject: err => {
            const {
                data: { code, errorResponse },
            } = err;
            if (code === '1101' && errorResponse === 'schemeNum') {
                toggleDuplicatedStatusSchemeModal();
            }
        },
    });

    const { promise: updateFlowSchemeInfo } = useAsync({
        promise: updateFlowSchemeInfosApi,
        resolve: res => {
            toggleSaveCompleteModal();
            getFlowSchemeInfo({ flowNum: flowSchemeInfo.flowNum });
        },
    });

    const { toggleModal: toggleSaveConfirmModal, Modal: SaveConfirmModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Do you want to save?'),
        okCallback: () => {
            const {
                statusCategoryName,
                schemeNum,
                comNum,
                modDate,
                modUserNum,
                regDate,
                regUserNum,
                mappedStatus,
                flowNum,
                flowName,
                isActive,
                description,
                ...detailInfo
            } = flowSchemeDetailInfo;
            if (flowNum) {
                updateFlowSchemeInfo({
                    description,
                    flowName: trim(flowName),
                    isActive,
                    flowNum,
                    schemeNum,
                });
            } else {
                createFlowSchemeInfo({
                    description,
                    flowName: trim(flowName),
                    isActive: isActive ? isActive : 'N',
                    schemeNum,
                });
            }
        },
    });
    const { toggleModal: toggleSaveCompleteModal, Modal: SaveCompleteModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Save is complete.'),
        okCallback: () => {
            if (savedFlowNum) {
                history.push(`/status/flow-scheme/${savedFlowNum}`);
            }
        },
    });
    const { toggleModal: toggleRequestRequiredModal, Modal: RequestRequiredModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Please fill in the required fields. ( Name, Status scheme, Activation )'),
    });

    const { toggleModal: toggleDuplicatedStatusSchemeModal, Modal: DuplicatedStatusSchemeModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('This is an already mapped status scheme. Please select a different status scheme.'),
    });

    useEffect(() => {
        const flowNumToNum = Number(flowNum);
        if (!isNaN(flowNumToNum)) {
            getFlowSchemeInfo({ flowNum: flowNumToNum });
        }
    }, [flowNum]);

    useEffect(() => {
        let additionalInfo = {};
        if (flowSchemeInfo.flowNum) {
            additionalInfo = flowSchemeListInfo.rows.find(v => v.flowNum === flowSchemeInfo.flowNum);

            if (transitionList.length) {
                dispatch(setFlowTransitionList(transitionList.filter(v => v.flowNum === flowSchemeInfo.flowNum)));
            }

            // 임시 : api 연동시 변경필요
            if (mappedStatusInfo[flowSchemeInfo.schemeNum]) {
                dispatch(
                    setFlowStatusList(
                        mappedStatusInfo[flowSchemeInfo.schemeNum].filter(v => v.flowNum === flowSchemeInfo.flowNum),
                    ),
                );
                // dispatch(setFlowStatusList(statusListInfo.rows));
            } else {
                dispatch(setFlowStatusList([]));
                // dispatch(setFlowStatusList(statusListInfo.rows));
            }
        }
        setFlowSchemeDetailInfo({ ...additionalInfo, ...flowSchemeInfo });
    }, [flowSchemeInfo, flowSchemeListInfo, transitionList, mappedStatusInfo]);

    useEffect(() => {
        addIcon(flowSchemeDetailInfo.isActive === 'Y');
    }, [flowSchemeDetailInfo.isActive]);

    useEffect(() => {
        if (transitionDetailModal.transitionInfo) {
            setTransitionDetailModal({
                ...transitionDetailModal,
                transitionInfo: transitionList.find(
                    v => v.actionNum === transitionDetailModal.transitionInfo.actionNum,
                ),
            });
        }
    }, [transitionList]);

    // useEffect(() => {
    //     if (mappedStatusInfo[flowSchemeDetailInfo.schemeNum]) {
    //         dispatch(setFlowStatusList(mappedStatusInfo[flowSchemeDetailInfo.schemeNum]));
    //     } else {
    //         dispatch(setFlowStatusList([]));
    //     }
    // }, [flowSchemeDetailInfo.schemeNum, mappedStatusInfo]);

    const selectedInfo = statusSchemeList.find(v => v.value === flowSchemeDetailInfo.schemeNum);

    return (
        <>
            <FlowSchemeDispatchContext.Provider value={dispatch}>
                <FlowSchemeStateContext.Provider value={state}>
                    <Card
                        header={{
                            title: `${t('Status flow scheme')} - ${t('Information')}`,
                            action: (
                                <>
                                    <Button className={'btn-gray flex-center-1'} onClick={() => history.goBack()}>
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        className={'btn-brand flex-center'}
                                        iconClassName={'icon-save'}
                                        onClick={() => {
                                            const { schemeNum, flowName } = flowSchemeDetailInfo;
                                            if (!trim(flowName) || !schemeNum) {
                                                toggleRequestRequiredModal();
                                            } else {
                                                toggleSaveConfirmModal();
                                            }
                                        }}
                                    >
                                        {t('Save')}
                                    </Button>
                                </>
                            ),
                        }}
                    >
                        <div
                            style={{
                                minHeight: 700,
                                maxHeight: 'calc(100vh - 260px)',
                                display: 'grid',
                                gridTemplateRows: 'auto 480px',
                            }}
                        >
                            <div>
                                <div className="mb-3 font-weight-bold font-size-lg">{`${t('Status')} ${t(
                                    'Detail',
                                )}`}</div>
                                <div className="p-0 d-flex row pnt-label-7">
                                    <Label
                                        name={t('Name')}
                                        labelValueClassName={'label-dot color-brand'}
                                        labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                        value={
                                            <TextInput
                                                // style={{ width: INPUT_WIDTH }}
                                                inputGroupClassName={'col-sm-8 input-form-group form-must'}
                                                type={'text'}
                                                maxlength={50}
                                                name={'flowName'}
                                                handleChange={handleChange}
                                                value={flowSchemeDetailInfo.flowName || ''}
                                            />
                                        }
                                    />
                                    <Label
                                        name={t('Status Scheme')}
                                        labelValueClassName={'label-dot color-brand'}
                                        labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                        value={
                                            <div style={{ padding: '0 15px' }}>
                                                <SingleSelect
                                                    className={cx(
                                                        { 'form-disable': !!flowSchemeInfo.flowNum },
                                                        ' form-must',
                                                    )}
                                                    disabled={!!flowSchemeInfo.flowNum}
                                                    value={(selectedInfo && selectedInfo.value) || null}
                                                    name={'schemeNum'}
                                                    options={statusSchemeList}
                                                    customControlStyles={{ width: 297 }}
                                                    customMenuStyles={{ width: 297 }}
                                                    onChange={selected => {
                                                        const { value, label } = statusSchemeList.find(
                                                            v => v.value === selected,
                                                        );
                                                        setFlowSchemeDetailInfo({
                                                            ...flowSchemeDetailInfo,
                                                            schemeNum: value,
                                                            schemeName: label,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        }
                                    />
                                    <Label
                                        name={t('Filter;Activation')}
                                        labelValueClassName={'label-dot color-brand'}
                                        labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                        value={
                                            <div
                                                className={'pnt-select--group form-must d-flex align-items-center'}
                                                style={{ margin: '0 15px' }}
                                            >
                                                <StyledSwitch
                                                    checked={flowSchemeDetailInfo.isActive === 'Y'}
                                                    borderRadius={1}
                                                    onChange={(checked, e) => {
                                                        setFlowSchemeDetailInfo({
                                                            ...flowSchemeDetailInfo,
                                                            isActive: checked ? 'Y' : 'N',
                                                        });
                                                        addIcon(checked);
                                                    }}
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    offHandleColor={'#cccccc'}
                                                    onHandleColor={'#fca558'}
                                                    onColor={'#FF7800'}
                                                />
                                            </div>
                                        }
                                    />
                                    <Label
                                        name={t('assigned assets')}
                                        labelValueClassName={'label-dot label-main'}
                                        labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                        value={
                                            <div style={{ padding: '0 15px' }}>
                                                {flowSchemeDetailInfo.itemCount ? flowSchemeDetailInfo.itemCount : 0}
                                            </div>
                                        }
                                    />
                                    <Label
                                        name={t('Description')}
                                        labelValueClassName={'label-dot label-main'}
                                        labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                        value={
                                            <TextInput
                                                // style={{ width: INPUT_WIDTH }}
                                                inputGroupClassName={'col-sm-8'}
                                                type={'text'}
                                                maxlength={200}
                                                name={'description'}
                                                handleChange={handleChange}
                                                value={flowSchemeDetailInfo.description || ''}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'grid', gridTemplateRows: 'min-content auto' }}>
                                <div className="mb-3 font-weight-bold font-size-lg">{`${t('Status')} & ${t(
                                    'Transition',
                                )}`}</div>
                                <FlowGraph
                                    key={JSON.stringify(flowTransitionList)}
                                    graphData={graphData}
                                    toggleTransitionDetailModal={toggleTransitionDetailModal}
                                />
                            </div>
                        </div>
                    </Card>
                    <AddTransitionModal
                        isOpen={transitionDetailModal.isOpen}
                        transitionInfo={transitionDetailModal.transitionInfo}
                        toggle={toggleTransitionDetailModal}
                    />
                </FlowSchemeStateContext.Provider>
            </FlowSchemeDispatchContext.Provider>

            <SaveConfirmModal />
            <SaveCompleteModal />
            <RequestRequiredModal />
            <DuplicatedStatusSchemeModal />
        </>
    );
};

export default FlowSchemeDetail;
