import React, { useEffect, useState } from 'react';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchFloorList } from '../../../../api/common';
import TreeSelect from '../../Components/Select/TreeSelectWithOption';
import { useTranslation } from 'react-i18next';

const FloorSearchableSelect = ({ selected, changeable, handleChange, hideOption, className }) => {
    const { t } = useTranslation();

    const [floorList, setFloorList] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);

    useAsync({
        promise: fetchFloorList,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            setFloorList(
                res.rows.map(v => {
                    return { ...v, floorIds: v.floorId };
                }),
            );
        },
    });

    useEffect(() => {
        if (!selected || !selected.length) {
            setSelectedOption([]);
        } else if (floorList.length) {
            setSelectedOption(selected);
        }
    }, [selected, floorList]);

    const handleSelectedOptionChange = (selected, changeable) => {
        handleChange(selected, changeable);
        setSelectedOption(selected);
    };

    return (
        <TreeSelect
            data={floorList}
            title={t('Filter;Floor')}
            valueKey={'floorId'}
            parentKey={'upperFloorId'}
            labelKey={'floorName'}
            selected={selectedOption}
            className={className ? className : 'mr-2 mb-2 mt-2'}
            onChange={handleSelectedOptionChange}
            changeable={changeable}
            hideOption={hideOption}
        />
    );
};

export default FloorSearchableSelect;
