import React, { useContext, useEffect } from 'react';
import Card from '../../../../../Common/Card';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import Button from '../../../../../Common/Button';
import Table from '../../../../../Common/Table';
import useColumns from '../../../../../../util/hooks/useColumns';
import * as column from '../../../../util/grid/column';
import useAsync from '../../../../../../util/hooks/useAsync';
import {
    addLoanableAsset,
    deleteLoanableAsset,
    setAssetList,
    setBorrowedAsset,
    setSelectedAssetRentalProcess,
} from '../assetRentalProcessViewReducer';
import { AssetRentalProcessViewDispatchContext, AssetRentalProcessViewStateContext } from '../index';
import useConfirmModal from '../../../../../Common/ConfirmModal/useConfirmModal';
import {
    getAssetRentalRequestApi,
    getAvailableAssetListApi,
    getBorrowedAssetListApi,
    updateAssetRentalStatusApi,
} from '../../../../../../api/asset';
import { useSelector } from 'react-redux';

const AssetRentalProcessList = () => {
    const t = useTranslation('AssetRentalProcess');
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { groupNum } = useSelector(state => state.UserInfo.userInfo);

    const dispatch = useContext(AssetRentalProcessViewDispatchContext);
    const { assetList, addedAssetList, selectedAssetRentalProcess, checkedInAssetCount } = useContext(
        AssetRentalProcessViewStateContext,
    );

    const columns = useColumns(
        [
            column.category({
                Cell: ({ value }) => {
                    const category = categoryList.find(({ categoryCode }) => categoryCode === value);
                    if (category) {
                        return <div>{category.categoryNamePath}</div>;
                    } else {
                        return <div>-</div>;
                    }
                },
            }),
            column.assetName(),
            column.assetNumber({
                accessor: 'targetNum',
            }),
            column.assetRentalRequestDepart({
                Cell: () => {
                    if (selectedAssetRentalProcess) {
                        return <div>{selectedAssetRentalProcess.usingDepNm}</div>;
                    } else {
                        return <div>-</div>;
                    }
                },
            }),
            column.assetRentalDepartment({
                accessor: 'ownDepName',
            }),
            column.rentalStatus({
                Cell: ({ value }) => {
                    if (value) {
                        return <div>{t(value, 'AssetRentalRequest')}</div>;
                    } else {
                        return <div>{t('allocation', 'AssetRentalRequest')}</div>;
                    }
                },
            }),
            column.action({
                width: 280,
                Cell: ({ row }) => {
                    const { targetNum } = row.original;

                    if (row.original.type === 'loanable') {
                        const selected = addedAssetList.rows.find(
                            addedAsset => addedAsset.targetNum === row.original.targetNum,
                        );
                        //이미 선택된 자산은 동작에 선택버튼 지우기
                        if (selected) {
                            return <div />;
                        } else {
                            return (
                                <div className={'table-btn-container'}>
                                    <button
                                        onClick={() => handleLoanableAssetAddClick(row.original)}
                                        className="pnt-txt txt-border txt-btn-org"
                                        type={'button'}
                                    >
                                        {t('Select', 'Button')}
                                    </button>
                                </div>
                            );
                        }

                        // 자산대여 신청상태가 승인 또는 대여 중일때
                    } else if (
                        row.original.type === 'selected' &&
                        selectedAssetRentalProcess &&
                        (selectedAssetRentalProcess.status === 'APPROVE' ||
                            selectedAssetRentalProcess.status === 'RENTAL')
                    ) {
                        //대여상태가 지정일경우
                        if (row.original.status === 'allocation' || !row.original.status) {
                            return (
                                <div className={'table-btn-container'}>
                                    <button
                                        onClick={() =>
                                            updateAssetRentalStatus({
                                                reqNum: selectedAssetRentalProcess.reqNum,
                                                targetNum: targetNum,
                                                status: 'cancel_allocation',
                                            })
                                        }
                                        className="pnt-txt txt-border txt-btn-org mr-2"
                                        type={'button'}
                                    >
                                        {t('cancel_allocation', 'AssetRentalRequest')}
                                    </button>
                                    <button
                                        onClick={() =>
                                            updateAssetRentalStatus({
                                                reqNum: selectedAssetRentalProcess.reqNum,
                                                targetNum: targetNum,
                                                status: 'check_out',
                                            })
                                        }
                                        className="pnt-txt txt-border txt-btn-org"
                                        type={'button'}
                                    >
                                        {t('check_out', 'AssetRentalRequest')}
                                    </button>
                                </div>
                            );
                        }
                        //대여상태가 반납일경우
                        else if (row.original.status === 'check_in') {
                            return (
                                <div className={'table-btn-container'}>
                                    <button
                                        onClick={() =>
                                            updateAssetRentalStatus({
                                                reqNum: selectedAssetRentalProcess.reqNum,
                                                targetNum: targetNum,
                                                status: 'confirm_check_in',
                                            })
                                        }
                                        className="pnt-txt txt-border txt-btn-org"
                                        type={'button'}
                                    >
                                        {t('confirm_check_in', 'AssetRentalRequest')}
                                    </button>
                                </div>
                            );
                        } else {
                            return <div />;
                        }
                    } else if (
                        row.original.type === 'selected' &&
                        selectedAssetRentalProcess &&
                        (selectedAssetRentalProcess.status === 'RETURN' ||
                            selectedAssetRentalProcess.status === 'REFUSAL' ||
                            selectedAssetRentalProcess.status === 'CANCEL')
                    ) {
                        return <div />;
                    } else if (row.original.type === 'selected') {
                        return (
                            <div className={'table-btn-container'}>
                                <button
                                    onClick={() => dispatch(deleteLoanableAsset(row.original))}
                                    className="pnt-txt txt-border txt-btn-org"
                                    type={'button'}
                                >
                                    {t('Remove')}
                                </button>
                            </div>
                        );
                    }
                },
            }),
        ],
        null,
        [selectedAssetRentalProcess, addedAssetList, categoryList],
    );

    // 자산의 대여상태가 반납확인으로 전부 다 변했을 때 신청상태를 변경해주는 API 호출
    useEffect(() => {
        if (checkedInAssetCount === 0 && selectedAssetRentalProcess.status === 'RENTAL') {
            getAssetRentalRequest({ reqNum: selectedAssetRentalProcess.reqNum });
        }
    }, [checkedInAssetCount]);

    const { promise: getAssetRentalRequest } = useAsync({
        promise: getAssetRentalRequestApi,
        resolve: res => {
            const category = categoryList.find(category => category.categoryCode === res.categoryCode);
            dispatch(
                setSelectedAssetRentalProcess({ ...res, categoryNamePath: category ? category.categoryNamePath : '-' }),
            );
        },
    });

    const { promise: getAvailableAssetList, state: assetListInfo } = useAsync({
        promise: getAvailableAssetListApi,
        immediate: true,
        deps: [selectedAssetRentalProcess],
        fixedParam: {
            pageSize: 20,
            categoryCode: selectedAssetRentalProcess ? selectedAssetRentalProcess.categoryCode : 'Asset',
            ownDepNum: selectedAssetRentalProcess ? selectedAssetRentalProcess.ownDepNum : null,
        },
        resolve: res => {
            dispatch(setAssetList(res));
        },
    });

    //대여 자산 내역
    const { promise: getBorrowedAssetList } = useAsync({
        promise: getBorrowedAssetListApi,
        fixedParam: {
            isAll: 'Y',
            ownDepNums: groupNum,
            reqNum: selectedAssetRentalProcess ? selectedAssetRentalProcess.reqNum : null,
        },
        resolve: res => {
            if (selectedAssetRentalProcess && selectedAssetRentalProcess.status !== 'APPLY') {
                dispatch(setBorrowedAsset(res));
            }
        },
    });

    useEffect(() => {
        if (selectedAssetRentalProcess && selectedAssetRentalProcess.reqNum) {
            getBorrowedAssetList();
        }
    }, [selectedAssetRentalProcess]);

    // 자산 상태 변경
    const { promise: updateAssetRentalStatus } = useAsync({
        promise: updateAssetRentalStatusApi,
        resolve: () => {
            getBorrowedAssetList();
        },
    });

    const { toggleModal: toggleQuantityLimitShowModal, Modal: QuantityLimitShowModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Notification'),
        },
        confirmText: t("You can't add anymore."),
        removeCancel: true,
    });

    const handleRefreshClick = () => {
        getAvailableAssetList();
    };

    const handleLoanableAssetAddClick = selected => {
        if (selectedAssetRentalProcess.count !== addedAssetList.rows.length) {
            dispatch(addLoanableAsset(selected));
        } else {
            toggleQuantityLimitShowModal();
        }
    };

    const handleAssetListPageChange = pageIndex => {
        getAvailableAssetList({ page: pageIndex });
    };

    return (
        <>
            {selectedAssetRentalProcess && selectedAssetRentalProcess.status === 'APPLY' && (
                <Card
                    className={'loanable-assets'}
                    header={{
                        title: t('Loanable assets'),
                        action: (
                            <Button
                                className={'btn-icon btn-danger'}
                                iconClassName={'icon-replay'}
                                onClick={handleRefreshClick}
                            >
                                {t('Refresh')}
                            </Button>
                        ),
                    }}
                >
                    <Table
                        onPageChange={handleAssetListPageChange}
                        columns={columns}
                        data={{ ...assetList, pageSize: 20 }}
                        loading={assetListInfo.isLoading}
                    />
                </Card>
            )}
            <Card className={'loanable-assets'} header={{ title: t('Rental asset list') }}>
                <Table columns={columns} data={{ ...addedAssetList }} />
            </Card>
            <QuantityLimitShowModal />
        </>
    );
};

export default AssetRentalProcessList;
