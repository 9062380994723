import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';

const WrappedAlertInfo = styled.div`
    display: flex;
    & > div {
        cursor: ${props => (props.clickable ? 'pointer' : 'default')};
    }
`;

const DefaultItemComponent = ({ event, statusColorInfo }) => {
    const { t } = useTranslation();

    return (
        <div>
            <div>
                <h4 className="timeline-title mr-2 cursor-pointer">
                    {event.targetName}
                    <span
                        className="timeline-title ml-2"
                        style={{ color: statusColorInfo && statusColorInfo[event.previousStatusNum] }}
                    >
                        {event.previousStatusName}
                    </span>
                    <span className="icon-next transform-scale-smaller" />
                    <span
                        className="timeline-title ml-2"
                        style={{ color: statusColorInfo && statusColorInfo[event.statusNum] }}
                    >
                        {event.statusName}
                    </span>
                </h4>
            </div>

            <b className={event.typeClassName}>{event.type}</b>

            <>
                <p>
                    {t('TimeLine;Location')} : {event.floorNamePath}
                </p>
                {event.properties && (
                    <p>
                        {t('TimeLine;Ownership')} :{event.properties.pic_id}
                    </p>
                )}
            </>
        </div>
    );
};

const TimeLineInJournal = ({ events = [], onClick, ItemComponent, withDate = true, statusColorInfo }) => {
    const { t } = useTranslation();
    const { lang } = useSelector(state => state.UserInfo);

    return events.length ? (
        <PerfectScrollbar>
            <VerticalTimeline layout="1-column">
                {events.map((event, i) => (
                    <VerticalTimelineElement
                        key={i}
                        style={{
                            cursor: 'default',
                        }}
                        className="vertical-timeline-item"
                        icon={
                            <i
                                style={{ backgroundColor: statusColorInfo && statusColorInfo[event.statusNum] }}
                                className={cx('badge badge-dot badge-dot-xl')}
                            >
                                {' '}
                            </i>
                        }
                        date={
                            <>
                                {withDate && (
                                    <>
                                        <span> {event.dateStr}</span>
                                        <br />
                                    </>
                                )}
                                <span>
                                    {' '}
                                    {moment(event.regDate * 1000)
                                        .locale(lang)
                                        .format('hh:mm A')}
                                </span>
                            </>
                        }
                    >
                        <WrappedAlertInfo
                            clickable={typeof onClick === 'function'}
                            onClick={() => {
                                if (typeof onClick === 'function') {
                                    onClick(event);
                                }
                            }}
                        >
                            {ItemComponent ? (
                                <ItemComponent event={event} />
                            ) : (
                                <DefaultItemComponent event={event} statusColorInfo={statusColorInfo} />
                            )}
                        </WrappedAlertInfo>
                    </VerticalTimelineElement>
                ))}
            </VerticalTimeline>
        </PerfectScrollbar>
    ) : (
        <div
            style={{
                color: '#666',
                textAlign: 'center',
                paddingTop: '1.5rem',
            }}
        >
            {t('TimeLine;No new notifications')}
        </div>
    );
};

export default TimeLineInJournal;
