import React, { useState, useMemo, createContext, useReducer, useEffect, useRef, useContext, useCallback } from 'react';
import Select from 'react-select';
import { defaultStyle } from '../../../../Common/Select/selectStyle';
import { Dropdown, ValueContainer, DropdownIndicator, TreeMenuList } from './Parts';
import { treeSelectReducer, updateFlattenData, updateTitle } from '../treeSelectReducer';

const selectStyles = {
    ...defaultStyle,
    container: (provided, state) => ({
        ...provided,
        minWidth: '11.5rem',
        // maxWidth: '14rem',
    }),
    control: provided => ({
        ...provided,
        // minWidth: '11.5rem',
        // maxWidth: '14rem',
        margin: 8,
    }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
    menuList: provided => ({
        ...provided,
        // maxWidth: '17rem',
    }),
};

export const TreeSelectContext = createContext();
export const TreeSelectDispatchContext = createContext();

const TreeSelect = ({
    className,
    data = [],
    selected = [],
    title,
    labelKey = 'label',
    valueKey = 'value',
    parentKey = 'parent',
    onChange,
    changeable = 'Y',
    hideOption,
}) => {
    const isMounted = useRef(false);
    const [state, dispatch] = useReducer(treeSelectReducer, {
        title,
        labelKey,
        valueKey,
        parentKey,
        flattenData: data,
        treeData: [],
        changeable,
        hideOption,
    });
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    const getOptionValue = option => option[valueKey];
    const getOptionLabel = option => option[labelKey];
    const filterOption = ({ label }, string) => ~label.toLowerCase().indexOf(string.toLowerCase());
    const selectedOptions = useMemo(() => {
        const selectedKeyArr = selected.map(v => {
            if (typeof v === 'object') {
                return v[valueKey];
            }
            return v;
        });
        return data.reduce((acc, curr) => {
            if ((selectedKeyArr || []).includes(curr[valueKey])) {
                acc.push(curr);
            }
            return acc;
        }, []);
    }, [data, selected, valueKey]);

    useEffect(() => {
        dispatch(updateFlattenData(data));
    }, [data]);

    useEffect(() => {
        if (isMounted.current) {
            if (typeof onChange === 'function') {
                onChange(
                    data.filter(v => selected.includes(v[valueKey])),
                    state.changeable,
                );
            }
        } else {
            isMounted.current = true;
        }
    }, [state.changeable]);

    useEffect(() => {
        dispatch(updateTitle(title));
    }, [title]);

    return (
        <TreeSelectDispatchContext.Provider value={dispatch}>
            <TreeSelectContext.Provider value={state}>
                <Dropdown
                    className={className}
                    isOpen={isOpen}
                    onClose={toggleOpen}
                    target={
                        <ValueContainer title={title} isOpen={isOpen} selected={selectedOptions} onClick={toggleOpen} />
                    }
                >
                    <Select
                        isMulti
                        // autoFocus
                        backspaceRemovesValue={false}
                        components={{
                            DropdownIndicator,
                            IndicatorSeparator: null,
                            MenuList: TreeMenuList,
                            Control: () => null,
                        }}
                        controlShouldRenderValue={false}
                        // hideSelectedOptions={false}
                        isClearable={false}
                        menuIsOpen
                        onChange={selected => {
                            onChange(selected, state.changeable);
                        }}
                        options={state.treeData}
                        styles={selectStyles}
                        tabSelectsValue={false}
                        getOptionValue={getOptionValue}
                        getOptionLabel={getOptionLabel}
                        value={selectedOptions}
                        filterOption={filterOption}
                    />
                </Dropdown>
            </TreeSelectContext.Provider>
        </TreeSelectDispatchContext.Provider>
    );
};

export default TreeSelect;
