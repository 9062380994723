import React, { useRef, useState, useReducer, createContext } from 'react';
import GridLayoutCard from '../Components/GridLayoutCard';
import Button from '../../Common/Button';
import { useTranslation } from 'react-i18next';
import useAsync from '../../../util/hooks/useAsync';
import { fetchHeatMapLocationLogList } from '../../../api/log';
import FilterSearchGroup from '../../Common/FilterSearchGroup';
import { FilterList, SelectGroup } from '../../Common/FilterSearchGroup/Components/Part';
import DatePicker from '../../Common/DatePicker';
import 'nouislider/distribute/nouislider.css';
import FloorTreeSelect from '../Components/FloorTreeSelect';
import { fetchFloorList, fetchSelectedFloorInfo } from '../../../api/common';
import { setFloorList } from '../../../reducers/Common/FloorInfo';
import { useDispatch, useSelector } from 'react-redux';
import PlayController from './Components/PlayController';
import heatMapReducer, {
    initialState,
    setDetectedTargets,
    setEndTime,
    setStartTime,
    setSelectedFloor,
    setLog,
} from './heatMapReducer';
import HeatMap from './Components/HeatMap';
import FilterGroup from '../../Common/FilterSearchGroup/Components/FilterGroup';
import useConfirmModal from '../../Common/ConfirmModal/useConfirmModal';
import moment from 'moment';

export const HeatMapDispatchContext = createContext();
export const HeatMapStateContext = createContext();

const AnalysisHeatMap = ({ widgetInfo, children, ...restProps }) => {
    const { t } = useTranslation();
    const widgetRef = useRef();
    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(heatMapReducer, initialState);
    const { floorList } = useSelector(state => state.FloorInfo);

    const { promise: getDetectedTargetList } = useAsync({
        immediate: true,
        promise: fetchHeatMapLocationLogList,
        resolve: res => {
            const { additionalDatas, rows } = res;
            dispatch(setDetectedTargets(additionalDatas.detectedTargets));
            dispatch(setLog(rows));
            if (rows.length) {
                dispatch(setStartTime(rows[rows.length - 1]['regDate']));
                dispatch(setEndTime(rows[0]['regDate']));
            }
        },
        reject: err => {
            console.log(err);
        },
    });

    useAsync({
        promise: fetchFloorList,
        resolve: res => {
            storeDispatch(setFloorList(res));
            const { rows } = res;
            if (rows.length) {
                getFloorInfo({ floorId: rows[0].floorId });
            }
        },
        reject: err => {
            console.log(err);
        },
        immediate: true,
    });

    const { promise: getFloorInfo } = useAsync({
        promise: fetchSelectedFloorInfo,
        resolve: res => {
            dispatch(setSelectedFloor(res));
        },
        reject: err => {
            console.log(err);
        },
    });

    const [showSearchArea, setShowSearchArea] = useState(false);
    const handleSearchAreaClick = () => {
        setShowSearchArea(!showSearchArea);
    };

    const [floor, setFloor] = useState(null);
    const [floorName, setFloorName] = useState('');
    const handleFloorChange = selected => {
        const { floorId, floorName } = selected[0];
        setFloor(floorId);
        setFloorName(floorName);
    };

    const [startDate, setStartDate] = useState(null);
    const handleStartDateChange = selected => {
        setStartDate(selected);
    };

    const [endDate, setEndDate] = useState(null);
    const handleEndDateChange = selected => {
        setEndDate(selected);
    };

    const { Modal: SearchResultModal, toggleModal: toggleSearchResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result') },
        confirmText: t('Please specify a start and end date'),
    });
    const handleSearchClick = () => {
        if (startDate && endDate) {
            getDetectedTargetList({ startDate, endDate, floor });
            getFloorInfo({ floorId: floor });
        } else {
            toggleSearchResultModal();
        }
    };

    const handleRefreshClick = () => {
        setStartDate(null);
        setEndDate(null);
        setFloor(null);
    };

    return (
        <>
            <HeatMapDispatchContext.Provider value={dispatch}>
                <HeatMapStateContext.Provider value={state}>
                    <GridLayoutCard
                        bodyClassName={'p-1'}
                        widgetInfo={widgetInfo}
                        ref={widgetRef}
                        headerAction={
                            <Button className={'btn-secondary'} onClick={handleSearchAreaClick}>
                                {t('Button;Search')}
                            </Button>
                        }
                        searchFilter={
                            showSearchArea && (
                                <FilterSearchGroup className={'card absolute-filter'}>
                                    <FilterGroup label={t('AssetCountStatus;Search')}>
                                        <FilterList className={'w-100'}>
                                            <SelectGroup>
                                                <DatePicker
                                                    value={startDate}
                                                    handleChange={handleStartDateChange}
                                                    showTimeSelect
                                                />
                                            </SelectGroup>
                                            <SelectGroup>
                                                <DatePicker
                                                    value={endDate}
                                                    handleChange={handleEndDateChange}
                                                    showTimeSelect
                                                    disabled={!startDate}
                                                    maxDate={moment().valueOf()}
                                                />
                                            </SelectGroup>
                                            <SelectGroup>
                                                <FloorTreeSelect
                                                    selectedFloorStr={floorName}
                                                    floorList={floorList}
                                                    mode={'radioSelect'}
                                                    handleChange={handleFloorChange}
                                                />
                                            </SelectGroup>
                                            <Button
                                                disabled={(!startDate && !endDate) || !floorName}
                                                className={'btn-brand btn-icon'}
                                                iconClassName={'icon-search'}
                                                onClick={handleSearchClick}
                                            >
                                                {t('Search;Search')}
                                            </Button>
                                            <Button
                                                className="btn-danger btn-icon-only"
                                                iconClassName={'icon-replay'}
                                                onClick={handleRefreshClick}
                                            />
                                        </FilterList>
                                    </FilterGroup>
                                </FilterSearchGroup>
                            )
                        }
                        {...restProps}
                    >
                        <HeatMap ref={widgetRef} />
                        {children}
                        <PlayController />
                    </GridLayoutCard>
                </HeatMapStateContext.Provider>
            </HeatMapDispatchContext.Provider>
            <SearchResultModal />
        </>
    );
};

export default AnalysisHeatMap;
