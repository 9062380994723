import { createSlice } from '@reduxjs/toolkit';
import { defaultStaticFilterInfo } from '../Components/MainPages/Filter/Option';
import { filterInfo as fixedFilterInfo } from '../Components/MainPages/Filters/Filter/filterReducer';
import cloneDeep from 'lodash/cloneDeep';
import { makeFilterParam } from '../Components/MainPages/Filter/reducer';

export const makeQueryParam = filterInfo => {
    const params = {};
    let dynamicCount = 0;
    for (let conditionId in filterInfo) {
        if (filterInfo.hasOwnProperty(conditionId)) {
            const values = filterInfo[conditionId];
            const filterConfig = fixedFilterInfo.find(v => v.id === conditionId);

            if (values.length) {
                if (filterConfig && filterConfig.type === 'dynamic') {
                    params['targetPropertiesSearch.categoryCode'] = 'ASSET';
                    // params[`targetPropertiesSearch.propertySearchs[${dynamicCount}].propertyId`] = conditionId;
                    // params[`targetPropertiesSearch.propertySearchs[${dynamicCount}].values`] = values.join(',');
                    // dynamicCount++;
                    values.map((value, index) => {
                        params[`targetPropertiesSearch.propertySearchs[${dynamicCount}].propertyId`] = conditionId;
                        params[`targetPropertiesSearch.propertySearchs[${dynamicCount}].values`] = value;
                        dynamicCount++;
                    });
                } else {
                    params[conditionId] = values.join(',');
                }
            }
        }
    }
    return params;
};

export const initialFilterInfo = {
    filterInfoCondition: defaultStaticFilterInfo,
};

const initialState = {
    filterList: [],
    originFilterInfo: {},
    filterInfo: initialFilterInfo,
    filterParam: {},
};

const { actions, reducer } = createSlice({
    name: 'filterInfo',
    initialState,
    reducers: {
        setFilterList: (state, action) => {
            state.filterList = action.payload;
        },
        setSelectedFilter: (state, action) => {
            let filterInfo = cloneDeep(action.payload);
            // let filterInfo = _.cloneDeep(action.payload);
            const { filterInfoCondition } = filterInfo;

            filterInfo.filterInfoCondition = filterInfoCondition.map(filterInfo => {
                const filter = initialFilterInfo.filterInfoCondition.find(
                    defaultFilter => filterInfo.conditionId === defaultFilter.conditionId,
                );
                return { ...filter, ...filterInfo };
            });

            state.originFilterInfo = filterInfo;
            state.filterInfo = filterInfo;
            if (filterInfo && filterInfo.filterInfoCondition) {
                state.filterParam = makeFilterParam(filterInfo.filterInfoCondition);
            } else {
                state.filterParam = {};
            }
        },
        updateFilterInfo: (state, action) => {
            const filterInfo = action.payload;
            if (filterInfo && filterInfo.filterInfoCondition) {
                state.filterInfo.filterInfoCondition = filterInfo.filterInfoCondition;
                state.filterParam = makeFilterParam(filterInfo.filterInfoCondition);
            } else {
                state.filterInfo.filterInfoCondition = initialFilterInfo.filterInfoCondition;
                state.filterParam = {};
            }
        },
        updateFilterParam: (state, action) => {
            const filterInfo = action.payload;
            if (filterInfo && filterInfo.filterInfoCondition) {
                state.filterParam = makeFilterParam(filterInfo.filterInfoCondition);
            } else {
                state.filterParam = {};
            }
        },
    },
});

export const { setFilterList, setSelectedFilter, updateFilterInfo, updateFilterParam } = actions;
export default reducer;
