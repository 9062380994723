import React, { useState } from 'react';
import 'react-checkbox-tree-enhanced/lib/react-checkbox-tree.css';
import '../../../../../assets/main/tree.scss';
import CheckboxTree from 'react-checkbox-tree-enhanced';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';

const TreeWrapper = styled.div`
    border: 1px solid #000;
    padding: 5px;
    .rct-text > label {
        display: flex;
        width: calc(100% - 10px);
    }
    .rct-title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: calc(100% - 25px);
        display: inline-block;
    }
`;

const Tree = ({ data, checked, setChecked }) => {
    const [expanded, setExpanded] = useState([]);

    return (
        <TreeWrapper>
            <CheckboxTree
                // checkModel={'all'} //default 'leaf'
                // nativeCheckboxes={true}
                // optimisticToggle={false}
                showNodeIcon={false}
                nodes={data}
                checked={checked}
                expanded={expanded}
                onCheck={setChecked}
                onExpand={expanded => setExpanded(expanded)}
                icons={{
                    check: (
                        <FontAwesomeIcon className="rct-icon rct-icon-check" icon="check-square" color={'#0075fe'} />
                    ),
                    uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} color={'#000'} />,
                    halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} color={'#000'} />,
                    // halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon="check-square" />,
                    expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon="chevron-right" />,
                    expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon="chevron-down" />,
                    expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon="plus-square" />,
                    collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon="minus-square" />,
                    parentClose: null,
                    parentOpen: null,
                    leaf: null,
                }}
            />
        </TreeWrapper>
    );
};

export default Tree;
