import React, { useState } from 'react';
import { CardHeader, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import Button from '../../../../Common/Button';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Label from '../../../../Common/Label';
import CustomInput from '../../../../Common/Input/CustomInput';
import styled from 'styled-components';

const StyledSwitch = styled(Switch)`
    margin-left: -5px;
    .react-switch-bg {
        border-radius: 7px !important;
    }

    .react-switch-handle {
        border-radius: 7px !important;
    }
`;

const AllocateAssetsCreate = () => {
    const t = useTranslation('Status');

    const history = useHistory();

    return (
        <>
            <CardHeader>
                <div className="card-header__title">
                    <div className={'icon-create mr-2'} />
                    <div>
                        <h3 className="title__main card-title">{`${t('Allocate Assets')} - ${t('Flow')} : ${t(
                            'Fixed asset',
                        )} - ${t('Add')}`}</h3>
                        <p className="title__sub">{t('You can allocate assets to Status flow Scheme.')}</p>
                    </div>
                </div>
                <div className="card-header__function">
                    <Button className={'btn-gray flex-center mr-1'} onClick={() => history.push('/status/allocate')}>
                        <div>{t('Cancel')}</div>
                    </Button>
                    <Button
                        className={'btn-orange flex-center'}
                        iconClassName={'icon-check'}
                        style={{ fontSize: '1rem' }}
                    >
                        <div>{t('Save')}</div>
                    </Button>
                </div>
            </CardHeader>
            <CardBody
                style={{
                    background: 'white',
                    minHeight: '500px',
                    maxHeight: 'calc(100vh - 260px)',
                    display: 'grid',
                    gridTemplateRows: 'min-content',
                }}
            >
                <div className="mb-3 font-weight-bold font-size-lg">{`${t('Status')} ${t('Detail')}`}</div>
                <div>
                    <div className="p-0 mb-4 d-flex row pnt-label-7">
                        <Label
                            name={t('Name')}
                            labelValueClassName={'label-dot label-must'}
                            labelGroupClassName={'col-xl-6 col-lg-12'}
                            value={
                                <CustomInput
                                    className={'border-orange'}
                                    type={'text'}
                                    name={'textInput'}
                                    placeholder={t('Please enter a name for the filter')}
                                    size={500}
                                />
                            }
                        />
                    </div>
                </div>
                <div className="pnt-border border-w" />
                <div className="mt-4 mb-3 font-weight-bold font-size-lg">{t('Set Assets')}</div>
            </CardBody>
        </>
    );
};

export default AllocateAssetsCreate;
