import React, { useState, forwardRef } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { deleteWidget } from '../../../reducers/Dashboards/DashboardEdit';
import ConfirmModal from '../../Common/ConfirmModal';
import { Card as RSCard, CardBody, CardHeader, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { getUsableWidgetInfo } from '../../MainPages/Dashboards/util';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../util/useSettings';

const HeaderButton = ({ children, ...restProps }) => {
    return (
        <Button
            outline
            className="border-0 btn-transition"
            color="light"
            style={{ padding: '1px 6px', marginBottom: '6px', transform: 'scaleY(1.5) scaleX(1.5)' }}
            {...restProps}
        >
            {children}
        </Button>
    );
};

const Card = (
    { children, subTitle, additionalTitle = '', widgetInfo, headerAction, className, bodyClassName, ...restProps },
    ref,
) => {
    children = [...children];
    const { id, type, config, name } = widgetInfo;
    const { t } = useTranslation();
    const resizeHandler = children.pop();
    const { settings: customSettings } = config;
    const settings = useSettings(config);
    const { SettingComponent } = getUsableWidgetInfo(type);
    const dispatch = useDispatch();
    const { mode } = useSelector(state => state.DashboardFrame);

    const [deleteModal, setDeleteModal] = useState(false);
    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };
    const handleDelete = e => {
        dispatch(deleteWidget(id));
    };

    const [settingModal, setSettingModal] = useState(false);
    const toggleSettingModal = () => {
        setSettingModal(!settingModal);
    };

    const displayName = () => {
        const { name } = settings;
        let title = '';
        if (customSettings && customSettings.name && customSettings.name !== t(`Widget;${name}`)) {
            title = customSettings.name;
        } else {
            title = type !== 'temp' ? `${name}` : t('Widget;New widget');
        }
        return title + additionalTitle;
    };

    return (
        <>
            <RSCard {...restProps} className={cx(className, 'widget-card')} innerRef={ref}>
                <CardHeader
                    className={cx('widget-header', mode !== 'edit' && settings.hiddenHeader && 'hidden-header')}
                >
                    <div className={'card-header__title'}>
                        <div className={'title-wrap'}>
                            <h3 className={'title__main'}>{displayName()}</h3>
                            {!!subTitle && <p className={'title__sub'}>{subTitle}</p>}
                        </div>
                    </div>
                    {headerAction && <div className={'card-header__function'}>{headerAction}</div>}
                    <div className={'widget-edit-btn'}>
                        {mode === 'edit' && SettingComponent && (
                            <HeaderButton onClick={toggleSettingModal}>
                                <FontAwesomeIcon icon={faBars} />
                            </HeaderButton>
                        )}
                        {mode === 'edit' && (
                            <button type="button" className="close" aria-label="Close" onClick={toggleDeleteModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        )}
                    </div>
                </CardHeader>
                <CardBody className={bodyClassName}>{children}</CardBody>
                {resizeHandler}
            </RSCard>
            {mode === 'edit' && (
                <ConfirmModal
                    initModal={deleteModal}
                    toggleModal={toggleDeleteModal}
                    header={{ title: t('ConfirmModal;Confirm') }}
                    confirmText={t('Widget;Do you want to delete the widget?')}
                    okCallback={handleDelete}
                />
            )}
            {SettingComponent && (
                <SettingComponent widgetInfo={widgetInfo} modal={settingModal} toggleModal={toggleSettingModal} />
            )}
        </>
    );
};

export default forwardRef(Card);
