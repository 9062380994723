import React, { useEffect, useRef, useState } from 'react';
import Page from '../../../Common/Page';
import useTranslation from '../../../../util/hooks/useTranslation';
import Card from '../../../Common/Card';
import Table from '../../../Common/Table';
import useColumns from '../../../../util/hooks/useColumns';
import * as column from '../../util/grid/column';
import Button from '../../../Common/Button';
import Search from '../../Components/Search';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchExcelLogApi } from '../../../../api/log';
import useConfirmModal from '../../../Common/ConfirmModal/useConfirmModal';
import moment from 'moment';
import { exportAssetInspectionApi, getAssetInspectionListApi } from '../../../../api/asset';
import DatePicker from '../../../Common/DatePicker';
import { useHistory } from 'react-router-dom';
import InspectionOwnershipSearchableSelect from '../FilterOption/InspectionOwnershipSearchableSelect';
import InspectionCategorySearchableSelect from '../FilterOption/InspectionCategorySearchableSelect';
import { assetDescription } from '../../util/grid/column';

const INTERVAL_TIME = 1000;

const AssetInspectionList = () => {
    const t = useTranslation('AssetInspectionList');

    const excelTimerRef = useRef();
    const history = useHistory();
    const [tableKey, setTableKey] = useState(0);
    const [selectedSearchOption, setSelectedSearchOption] = useState({
        usingDepartmentNum: [],
        categoryCodes: [],
    });
    const [assetInspectionList, setAssetInspectionList] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });
    const [startDate, setStartDate] = useState(moment().startOf('isoweek').unix() * 1000);
    const [endDate, setEndDate] = useState(moment().endOf('isoweek').unix() * 1000);
    const [keyword, setKeyword] = useState({});
    const [paramsForExcelToggle, setParamsForExcelToggle] = useState({});
    const columns = useColumns([
        column.assetCategoryCode(),
        column.assetName(),
        column.assetTargetNumber(),
        column.ownershipDepartmentName(),
        column.registeredDate(),
        column.inspectionStatus({
            Cell: ({ value }) => {
                return <div>{t(value)}</div>;
            },
        }),
        column.assetDescription(),
    ]);

    const { promise: getAssetInspectionList, state: assetInspectionListInfo } = useAsync({
        promise: getAssetInspectionListApi,
        fixedParam: { pageSize: 20 },
        keepState: true,
        resolve: res => {
            setAssetInspectionList(res);
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            if (res.dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();

                const elem = window.document.createElement('a');
                elem.href = res.filePath;
                elem.download = res.fileName;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            } else if (res.dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
            }
        },
    });

    const { promise: createAssetInspectionExcel } = useAsync({
        promise: exportAssetInspectionApi,
        fixedParam: {
            zipFileName: 'AssetInspectionList',
            fileName: 'AssetInspectionListExcel',
            columnMetas: [
                {
                    key: 'categoryCode',
                    name: t('Asset Category'),
                },
                {
                    key: 'targetName',
                    name: t('Asset Name'),
                },
                {
                    key: 'targetNum',
                    name: t('Asset Number'),
                },
                {
                    key: 'ownDepNm',
                    name: t('Ownership Department'),
                },
                {
                    key: 'regDateStr',
                    name: t('Registered Date'),
                },
                {
                    key: 'inspectStatus',
                    name: t('Inspection Status'),
                },
                {
                    key: 'description',
                    name: t('Description'),
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;

            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
    });

    const handlePageChange = pageIndex => {
        if (assetInspectionListInfo.request) {
            getAssetInspectionList({ ...assetInspectionListInfo.request, page: pageIndex });
        } else {
            getAssetInspectionList({
                startDate: moment(startDate).unix(),
                endDate: moment(endDate).unix(),
                page: pageIndex,
            });
        }
    };

    const handleSearchClick = keyword => {
        let param = setParams(keyword);
        setKeyword({ ...param });
        getAssetInspectionList({ ...param });
    };

    const setParams = keyword => {
        let param = {};
        if (keyword) {
            param = { ...param, opt: 'targetSearch', keyword: keyword };
        }
        if (selectedSearchOption.categoryCodes && selectedSearchOption.categoryCodes.length > 0) {
            param = { ...param, categoryCodes: selectedSearchOption.categoryCodes.join(',') };
        }
        if (selectedSearchOption.usingDepartmentNum && selectedSearchOption.usingDepartmentNum.length > 0) {
            param = {
                ...param,
                ownDepNums: selectedSearchOption.usingDepartmentNum.join(','),
            };
        }
        if (startDate && endDate) {
            param = {
                ...param,
                startDate: startDate / 1000,
                endDate: endDate / 1000,
            };
        }
        return param;
    };

    const handleRefreshClick = () => {
        setTableKey(prevState => prevState + 1);
        let startDate = moment().startOf('isoweek').unix() * 1000;
        let endDate = moment().endOf('isoweek').unix() * 1000;

        setSelectedSearchOption([]);
        setStartDate(startDate);
        setEndDate(endDate);
        setKeyword({
            startDate: startDate / 1000,
            endDate: endDate / 1000,
        });
        getAssetInspectionList({
            startDate: startDate / 1000,
            endDate: endDate / 1000,
        });
    };

    const handleDownloadClick = () => {
        setParamsForExcelToggle(keyword);
        handleExcelDownloadToggle();
    };

    const downloadWithURL = (url, reqNum) => {
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum: reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = () => {
        clearInterval(excelTimerRef.current);
        excelTimerRef.current = null;
    };

    const { toggleModal: handleExcelDownloadToggle, Modal: ExcelDownloadConfirmModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Download', 'ConfirmModal'),
        },
        confirmText: t('Do you want to download the excel?'),
        okCallback: createAssetInspectionExcel,
        callbackParam: {
            ...paramsForExcelToggle,
            isAll: 'Y',
        },
    });

    useEffect(() => {
        if (startDate && endDate) {
            getAssetInspectionList({
                startDate: startDate / 1000,
                endDate: endDate / 1000,
            });
            setKeyword({
                startDate: startDate / 1000,
                endDate: endDate / 1000,
            });
        }
    }, []);

    return (
        <Page menuPath={[t('Asset Inspection Management', 'Menu'), t('Asset Inspection List', 'Menu')]}>
            <div className="grid-layout-container">
                <Search
                    searchInputPlaceholder={t('Please enter the asset name or the asset id.')}
                    handleSearchClick={handleSearchClick}
                    handleRefreshClick={handleRefreshClick}
                >
                    <InspectionOwnershipSearchableSelect
                        handleChange={selected => {
                            setSelectedSearchOption(prevState => {
                                return { ...prevState, usingDepartmentNum: selected };
                            });
                        }}
                        selected={selectedSearchOption.usingDepartmentNum}
                    />

                    <InspectionCategorySearchableSelect
                        handleChange={selected =>
                            setSelectedSearchOption(prevState => {
                                return { ...prevState, categoryCodes: selected.map(v => v.categoryCode) };
                            })
                        }
                        changeable={'Y'}
                        selected={selectedSearchOption.categoryCodes}
                        hideOption
                    />
                    <DatePicker
                        value={startDate}
                        valueType={'ms'}
                        handleChange={selected => setStartDate(selected)}
                        withoutTime
                    />
                    <DatePicker
                        value={endDate}
                        valueType={'ms'}
                        minDate={startDate}
                        handleChange={selected => setEndDate(selected)}
                        withoutTime
                    />
                </Search>

                <Card
                    header={{
                        title: t('Asset Inspection List', 'Menu'),
                        action: (
                            <Button className={'btn-brand'} onClick={handleDownloadClick}>
                                {t('Download')}
                            </Button>
                        ),
                    }}
                >
                    <div className={'table-container'}>
                        <Table
                            key={tableKey}
                            data={{ ...assetInspectionList, pageSize: 20 }}
                            columns={columns}
                            onPageChange={handlePageChange}
                            onTrClick={info => history.push(`/asset-inspection/detail/${info.targetNum}`)}
                            loading={assetInspectionListInfo.isLoading}
                        />
                    </div>
                </Card>
            </div>

            <ExcelDownloadConfirmModal />
        </Page>
    );
};

export default AssetInspectionList;
