import React, { useContext, useEffect, useMemo, useState } from 'react';
import useTranslation from '../../../../../../../util/hooks/useTranslation';
import ReactSelect from '../../../../../../Common/Select';
import useAsync from '../../../../../../../util/hooks/useAsync';
import { fetchGeofenceList } from '../../../../../../../api/common';
import Button from '../../../../../../Common/Button';
import {
    addTrigger,
    deleteTrigger,
    MANUAL_GROUP_KEY,
    TRIGGER_SUB_TYPE_FLOOR,
    TRIGGER_SUB_TYPE_GEOFENCE,
    TRIGGER_SUB_TYPE_GROUP,
    TRIGGER_SUB_TYPE_USER,
    TRIGGER_TYPE_LOCATION,
    TRIGGER_TYPE_MANUAL,
    TRIGGER_VALUE_TYPE_IN,
    TRIGGER_VALUE_TYPE_OUT,
} from './triggerReducer';
import { TriggerDispatchContext, TriggerStateContext } from './index';
import { useSelector } from 'react-redux';
import GroupSingleTreeSelect from './GroupSingleTreeSelect';
import TextInput from '../../../../../../Common/Input/TextInput';

const LocationInputGroup = ({ groupKey }) => {
    const t = useTranslation('Status');
    const { floorList } = useSelector(state => state.FloorInfo);
    const dispatch = useContext(TriggerDispatchContext);
    const { triggerRelationJson } = useContext(TriggerStateContext);
    const { triggers } = triggerRelationJson.data[groupKey] ? triggerRelationJson.data[groupKey] : { triggers: [] };

    const [inAndOutSelected, setInAndOutSelected] = useState({ value: TRIGGER_VALUE_TYPE_IN, label: t('In') });
    const [locationTypeSelected, setLocationTypeSelected] = useState({
        value: TRIGGER_SUB_TYPE_FLOOR,
        label: t('Floor'),
    });

    const [geofenceOptions, setGeofenceOptions] = useState([]);
    const [selectedGeofence, setSelectedGeofence] = useState(null);
    const [floorOptions, setFloorOptions] = useState([]);
    const [selectedFloor, setSelectedFloor] = useState(null);
    const [focusedSelect, setFocusedSelect] = useState(false);

    const inAndOutOptions = useMemo(() => {
        return [
            { value: TRIGGER_VALUE_TYPE_IN, label: t('In') },
            { value: TRIGGER_VALUE_TYPE_OUT, label: t('Out') },
        ];
    }, [t]);

    const locationTypeOptions = useMemo(() => {
        return [
            { value: TRIGGER_SUB_TYPE_FLOOR, label: t('Floor') },
            { value: TRIGGER_SUB_TYPE_GEOFENCE, label: t('Geofence') },
        ];
    }, [t]);

    useAsync({
        promise: fetchGeofenceList,
        immediate: true,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const { rows } = res;
            const list = rows.map(geofence => {
                return { ...geofence, value: geofence.fcNum, label: geofence.fcName };
            });

            setGeofenceOptions(list);
        },
    });

    const handleAddManualClick = type => {
        if (type === TRIGGER_SUB_TYPE_GEOFENCE) {
            if (selectedGeofence) {
                dispatch(
                    addTrigger({
                        groupKey,
                        triggerType: TRIGGER_TYPE_LOCATION,
                        triggerSubType: locationTypeSelected.value,
                        triggerValue: { type: inAndOutSelected.value, value: selectedGeofence.fcNum },
                    }),
                );
                setFocusedSelect(false);
                setSelectedGeofence(null);
            } else {
                setFocusedSelect(true);
            }
        } else if (type === TRIGGER_SUB_TYPE_FLOOR) {
            if (selectedFloor) {
                dispatch(
                    addTrigger({
                        groupKey,
                        triggerType: TRIGGER_TYPE_LOCATION,
                        triggerSubType: locationTypeSelected.value,
                        triggerValue: { type: inAndOutSelected.value, value: selectedFloor.floorId },
                    }),
                );
                setFocusedSelect(false);
                setSelectedFloor(null);
                setFloorOptions([...floorList]);
            } else {
                setFocusedSelect(true);
            }
        }
    };

    useEffect(() => {
        setFloorOptions(floorList);
    }, [floorList]);

    return (
        <div>
            <div className={'location-input-group'}>
                <div>
                    <ReactSelect
                        name={'inAndOut'}
                        value={inAndOutSelected}
                        options={inAndOutOptions}
                        onChange={selected => setInAndOutSelected(selected)}
                    />
                </div>

                <div>
                    <ReactSelect
                        name={'locationType'}
                        value={locationTypeSelected}
                        options={locationTypeOptions}
                        onChange={selected => setLocationTypeSelected(selected)}
                    />
                </div>

                {locationTypeSelected.value === TRIGGER_SUB_TYPE_GEOFENCE ? (
                    <>
                        <div>
                            <ReactSelect
                                name={'geofenceSelect'}
                                value={selectedGeofence}
                                options={geofenceOptions.filter(geofence => {
                                    return !triggers.find(trigger => {
                                        return (
                                            trigger.triggerSubType === TRIGGER_SUB_TYPE_GEOFENCE &&
                                            trigger.triggerValue.value === geofence.value
                                        );
                                    });
                                })}
                                onChange={selected => setSelectedGeofence(selected)}
                                customControlStyles={
                                    focusedSelect ? { border: '2px solid #FF7800', width: '100%' } : { width: '100%' }
                                }
                                customOptionStyles={{ width: '100%' }}
                                customMenuStyles={{ width: '100%' }}
                            />
                        </div>
                        <Button
                            className={'btn-secondary btn-icon-only'}
                            iconClassName={'icon-plus'}
                            onClick={() => handleAddManualClick(TRIGGER_SUB_TYPE_GEOFENCE)}
                        />
                    </>
                ) : (
                    <>
                        <div>
                            <GroupSingleTreeSelect
                                warning={focusedSelect}
                                originData={floorOptions}
                                valueKey={'floorId'}
                                labelKey={'floorName'}
                                parentKey={'upperFloorId'}
                                childKey={'floorId'}
                                onChange={selected => {
                                    setSelectedFloor(selected);
                                }}
                            />
                        </div>
                        <Button
                            className={'btn-secondary btn-icon-only'}
                            iconClassName={'icon-plus'}
                            onClick={() => handleAddManualClick(TRIGGER_SUB_TYPE_FLOOR)}
                        />
                    </>
                )}
            </div>
            {triggers.map(trigger => {
                const { triggerKey, triggerValue, triggerSubType, groupKey } = trigger;
                let target;
                if (triggerSubType === TRIGGER_SUB_TYPE_GEOFENCE) {
                    const geofenceInfo = geofenceOptions.find(v => v.fcNum === triggerValue.value);
                    target = geofenceInfo ? geofenceInfo.fcName : '';
                } else {
                    const floorInfo = floorOptions.find(v => v.floorId === triggerValue.value);
                    target = floorInfo ? floorInfo.floorName : '';
                }
                return (
                    <div className={'location-input-group'} key={triggerKey}>
                        <TextInput
                            inputGroupClassName={'width-150px'}
                            type={'text'}
                            name={'manual'}
                            value={inAndOutOptions.find(v => v.value === triggerValue.type).label}
                            disabled
                        />
                        <TextInput
                            inputGroupClassName={'width-150px'}
                            type={'text'}
                            name={'type'}
                            value={locationTypeOptions.find(v => v.value === triggerSubType).label}
                            disabled
                        />
                        <TextInput
                            inputGroupClassName={'w-230px'}
                            type={'text'}
                            name={'target'}
                            value={target}
                            disabled
                        />
                        <Button
                            iconClassName={'icon-close'}
                            className={'btn-danger btn-icon-only'}
                            onClick={e => {
                                dispatch(deleteTrigger({ groupKey, triggerKey }));
                            }}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default LocationInputGroup;
