import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const SET_STATUS_LIST_INFO = 'common/statusInfo/SET_STATUS_LIST_INFO';
const SET_MAPPED_STATUS_LIST_INFO = 'common/statusInfo/SET_MAPPED_STATUS_LIST_INFO';
const SET_STATUS_SCHEME_LIST_INFO = 'common/statusInfo/SET_STATUS_SCHEME_LIST_INFO';
const SET_FLOW_SCHEME_LIST_INFO = 'common/statusInfo/SET_FLOW_SCHEME_LIST_INFO';
const SET_STATUS_CATEGORY_LIST_INFO = 'common/statusInfo/SET_STATUS_CATEGORY_LIST_INFO';
const SET_STATUS_FLOW_CATEGORY_MAP_INFO = 'common/statusInfo/SET_STATUS_FLOW_CATEGORY_MAP_INFO';
const SET_TRANSITION_LIST = 'common/statusInfo/SET_TRANSITION_LIST';

export const {
    setStatusListInfo,
    setMappedStatusListInfo,
    setStatusSchemeListInfo,
    setFlowSchemeListInfo,
    setStatusCategoryListInfo,
    setStatusFlowCategoryMapInfo,
    setTransitionList,
} = createActions(
    {
        SET_STATUS_LIST_INFO: statusListInfo => statusListInfo,
        SET_MAPPED_STATUS_LIST_INFO: mappedStatusListInfo => mappedStatusListInfo,
        SET_STATUS_SCHEME_LIST_INFO: statusSchemeListInfo => statusSchemeListInfo,
        SET_FLOW_SCHEME_LIST_INFO: flowSchemeListInfo => flowSchemeListInfo,
        SET_STATUS_CATEGORY_LIST_INFO: statusCategoryListInfo => statusCategoryListInfo,
        SET_STATUS_FLOW_CATEGORY_MAP_INFO: statusCategoryListInfo => statusCategoryListInfo,
        SET_TRANSITION_LIST: transitionList => transitionList,
    },
    {
        prefix: 'common/statusInfo',
    },
);

const defaultState = {
    statusListInfo: { rows: [] },
    mappedStatusInfo: {},
    flowCategoryMappedStatusInfo: { rows: [] },
    itemCntByFlow: {},
    statusSchemeListInfo: { rows: [] },
    flowSchemeListInfo: { rows: [] },
    statusCategoryListInfo: { rows: [] },
    transitionList: [],
};

const StatusInfo = handleActions(
    {
        [SET_STATUS_LIST_INFO]: (state, action) => {
            return produce(state, draft => {
                const statusListInfo = action.payload;
                draft.statusListInfo = statusListInfo;
                const { rows } = statusListInfo;

                if (rows && rows.length && state.statusCategoryListInfo.rows.length) {
                    const statusCategoryList = state.statusCategoryListInfo.rows;
                    draft.statusCategoryListInfo.rows = statusCategoryList.map(v => {
                        return {
                            ...v,
                            mappedStatus: rows.filter(status => status.statusCategoryNum === v.statusCategoryNum),
                        };
                    });
                }
            });
        },
        [SET_MAPPED_STATUS_LIST_INFO]: (state, action) => {
            return produce(state, draft => {
                const { rows } = action.payload;
                if (rows) {
                    draft.mappedStatusInfo = rows.reduce((acc, curr) => {
                        const schemeInfo = acc[curr.schemeNum];
                        if (schemeInfo) {
                            schemeInfo.push(curr);
                        } else {
                            acc[curr.schemeNum] = [curr];
                        }
                        return acc;
                    }, {});
                } else {
                    draft.mappedStatusInfo = {};
                }

                // 상태 스킴이 있다면, 매핑된 상태 정보 추가
                if (state.statusSchemeListInfo.rows.length) {
                    draft.statusSchemeListInfo.rows = state.statusSchemeListInfo.rows.map(v => {
                        return { ...v, mappedStatus: draft.mappedStatusInfo[v.schemeNum] };
                    });
                }
            });
        },
        [SET_STATUS_SCHEME_LIST_INFO]: (state, action) => {
            return produce(state, draft => {
                const statusSchemeListInfo = action.payload;
                draft.statusSchemeListInfo = statusSchemeListInfo;
                const { rows } = statusSchemeListInfo;

                if (rows && rows.length) {
                    // 플로우 스킴이 있다면, 상태 스킴에 매핑된 플로우 스킴 데이터 추가
                    if (state.flowSchemeListInfo.rows.length) {
                        const flowSchemeList = state.flowSchemeListInfo.rows;
                        draft.statusSchemeListInfo.rows = rows.map(v => {
                            const mappedFlowScheme = flowSchemeList.find(
                                flowScheme => flowScheme.schemeNum === v.schemeNum,
                            );
                            if (mappedFlowScheme) {
                                return { ...v, flowNum: mappedFlowScheme.flowNum, flowName: mappedFlowScheme.flowName };
                            }
                            return v;
                        });

                        draft.flowSchemeListInfo.rows = flowSchemeList.map(v => {
                            const mappedStatusScheme = rows.find(
                                statusScheme => statusScheme.schemeNum === v.schemeNum,
                            );
                            if (mappedStatusScheme) {
                                return { ...v, schemeName: mappedStatusScheme.schemeName };
                            }
                            return v;
                        });
                    }

                    // 매핑된 상태 정보가 있다면, 상태 스킴에 추가
                    if (Object.keys(state.mappedStatusInfo).length) {
                        draft.statusSchemeListInfo.rows = draft.statusSchemeListInfo.rows.map(v => {
                            return { ...v, mappedStatus: state.mappedStatusInfo[v.schemeNum] };
                        });
                    } else {
                        draft.statusSchemeListInfo.rows = draft.statusSchemeListInfo.rows.map(v => {
                            return { ...v, mappedStatus: [] };
                        });
                    }
                }
            });
        },
        [SET_FLOW_SCHEME_LIST_INFO]: (state, action) => {
            return produce(state, draft => {
                const flowSchemeListInfo = action.payload;
                flowSchemeListInfo.rows = flowSchemeListInfo.rows.map(v => {
                    const itemCount = state.itemCntByFlow[v.flowNum] || 0;
                    return { ...v, itemCount };
                });
                draft.flowSchemeListInfo = flowSchemeListInfo;
                const { rows } = flowSchemeListInfo;

                // 상태 스킴이 있다면, 상태 스킴에 매핑된 플로우 스킴 데이터 추가
                if (rows && rows.length && state.statusSchemeListInfo.rows.length) {
                    const schemeList = state.statusSchemeListInfo.rows;
                    draft.statusSchemeListInfo.rows = schemeList.map(v => {
                        const mappedFlowScheme = rows.find(flowScheme => flowScheme.schemeNum === v.schemeNum);
                        if (mappedFlowScheme) {
                            return { ...v, flowNum: mappedFlowScheme.flowNum, flowName: mappedFlowScheme.flowName };
                        }
                        return v;
                    });
                    draft.flowSchemeListInfo.rows = rows.map(v => {
                        const mappedStatusScheme = schemeList.find(
                            statusScheme => statusScheme.schemeNum === v.schemeNum,
                        );
                        if (mappedStatusScheme) {
                            return { ...v, schemeName: mappedStatusScheme.schemeName };
                        }
                        return v;
                    });
                }
            });
        },
        [SET_STATUS_CATEGORY_LIST_INFO]: (state, action) => {
            return produce(state, draft => {
                const statusCategoryListInfo = action.payload;
                draft.statusCategoryListInfo = statusCategoryListInfo;
                const { rows } = statusCategoryListInfo;

                if (rows && rows.length && state.statusListInfo.rows.length) {
                    const statusList = state.statusListInfo.rows;
                    draft.statusCategoryListInfo.rows = rows.map(v => {
                        return {
                            ...v,
                            mappedStatus: statusList.filter(status => status.statusCategoryNum === v.statusCategoryNum),
                        };
                    });
                }
            });
        },
        [SET_STATUS_FLOW_CATEGORY_MAP_INFO]: (state, action) => {
            return produce(state, draft => {
                const flowCategoryMappedStatusInfo = action.payload;
                draft.flowCategoryMappedStatusInfo = flowCategoryMappedStatusInfo;
                const { rows } = flowCategoryMappedStatusInfo;
                let itemCntByFlow = {};
                if (rows && rows.length) {
                    itemCntByFlow = rows.reduce((acc, curr) => {
                        if (acc[curr.flowNum]) {
                            acc[curr.flowNum] += curr.itemCount;
                        } else {
                            acc[curr.flowNum] = curr.itemCount;
                        }
                        return acc;
                    }, {});

                    if (state.statusListInfo.rows.length) {
                        const statusList = state.statusListInfo.rows;
                        draft.statusCategoryListInfo.rows = rows.map(v => {
                            return {
                                ...v,
                                mappedStatus: statusList.filter(
                                    status => status.statusCategoryNum === v.statusCategoryNum,
                                ),
                            };
                        });
                    }

                    const flowSchemeList = state.flowSchemeListInfo.rows;
                    if (flowSchemeList.length) {
                        draft.flowSchemeListInfo.rows = flowSchemeList.map(v => {
                            const itemCount = itemCntByFlow[v.flowNum] || 0;
                            return { ...v, itemCount };
                        });
                    }
                }

                draft.itemCntByFlow = itemCntByFlow;
            });
        },
        [SET_TRANSITION_LIST]: (state, action) => {
            return produce(state, draft => {
                const transitionListInfo = action.payload;
                if (transitionListInfo.rows) {
                    draft.transitionList = transitionListInfo.rows;
                } else {
                    draft.transitionList = [];
                }
            });
        },
    },
    defaultState,
);

export default StatusInfo;
