import React, { useState } from 'react';
import useTranslation from '../../../util/hooks/useTranslation';
import { useDispatch } from 'react-redux';
import { editWidgetSettings } from '../../../reducers/Dashboards/DashboardEdit';
import Label from '../../Common/Label';
import ReactSelect from '../../Common/Select';
import useAsync from '../../../util/hooks/useAsync';
import { getFlowSchemeListInfoApi } from '../../../api/status';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import { useSettings } from '../util/useSettings';

const RealtimeUtilizationSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;
    const t = useTranslation();
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    useAsync({
        promise: getFlowSchemeListInfoApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            setOptions(
                res.rows.map(list => {
                    return { label: list.flowName, value: list.flowNum };
                }),
            );

            let foundOne;
            if (res.rows && res.rows.length) {
                if (settings.flowNum) {
                    foundOne = res.rows.find(option => option.flowNum === settings.flowNum) || res.rows[0];
                } else {
                    foundOne = res.rows[0];
                }
            } else {
                foundOne = {};
            }
            setSelectedOption({ value: foundOne.flowNum, label: foundOne.flowName });
        },
    });

    const handleChange = e => {
        setSelectedOption(e);
    };

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: {
                        ...customSettings,
                        ...data,
                        selectedOption: selectedOption,
                        intervalTime: data.intervalTime,
                        flowNum: selectedOption.value,
                        ...updatedWidgetInfo,
                    },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: {
                        ...data,
                        selectedOption: selectedOption,
                        intervalTime: data.intervalTime,
                        flowNum: selectedOption.value,
                        ...updatedWidgetInfo,
                    },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('WidgetSetting;Realtime Utilization')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <Label
                name={t('WidgetSetting;Option')}
                labelGroupClassName={'w-100'}
                className={'w-100'}
                value={
                    <div className="pnt-select--group w-100">
                        <ReactSelect
                            name={'statusByOptions'}
                            options={options}
                            value={selectedOption}
                            onChange={handleChange}
                            className={'w-100'}
                            customOptionStyles={{ width: '100%' }}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                        />
                    </div>
                }
            />
        </WidgetSettingModal>
    );
};

export default RealtimeUtilizationSetting;
