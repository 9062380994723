import React, { useState, useEffect } from 'react';
import Select, { StylesConfig } from 'react-select';
import { OptionType, SelectProps } from '../type';
import useTranslation from '../../../util/hooks/useTranslation';

const ReactSelect = ({
    name,
    value,
    options,
    defaultValue,
    closeMenuOnSelect = true,
    isSearchable = false,
    isClearable = false,
    isMulti = false,
    onChange,
    onInputChange,
    customControlStyles,
    customMenuStyles,
    customOptionStyles,
    noOptionsMessage,
    disabled,
    placeholder,
    valueKey = 'value',
    labelKey = 'label',
    className,
    ...restProps
}: SelectProps): JSX.Element => {
    const t = useTranslation('Select');
    const [selectedValue, setSelectedValue] = useState<OptionType | undefined | null>(null);

    const selectStyle: StylesConfig<OptionType, boolean> = {
        control: (provided, state) => {
            return {
                ...provided,
                width: '150',
                ...customControlStyles,
            };
        },
        menu: provided => ({
            ...provided,
            width: '150',
            marginTop: '1px',
            ...customMenuStyles,
        }),
        option: (provided, state) => {
            return {
                ...provided,
                ...customOptionStyles,
            };
        },
        menuPortal: base => ({ ...base, zIndex: 9999 }),
    };

    // 특정 option을 선택한 후 언어설정을 바꾸면, 언어설정을 바꾸기 전 언어로 남아있어서, 선택한 언어로 변경하기 위한 로직
    useEffect(() => {
        let selected = null;
        if (value) {
            selected = options.find(option => option[valueKey] === value[valueKey]);
        }
        setSelectedValue(selected);
    }, [t, value]);

    return (
        <Select
            menuPortalTarget={document.getElementById('selectContainer')}
            name={name}
            value={selectedValue}
            options={options}
            defaultValue={defaultValue ? defaultValue : options[0]}
            onChange={onChange}
            onInputChange={onInputChange}
            closeMenuOnSelect={closeMenuOnSelect}
            isSearchable={isSearchable}
            isClearable={isClearable}
            styles={selectStyle}
            isMulti={isMulti}
            className={`react-select ${className}`}
            classNamePrefix="react-select"
            placeholder={placeholder || t('Select')}
            noOptionsMessage={() => noOptionsMessage || t('No options')}
            isDisabled={disabled}
            // 초기 객체값은 항상 빈값으로 존재 -> {}
            // 따라서 string 타입을 객체의 키값으로 사용할 수가 없음 -> 그 키가 객체에 존재하는지 알 수 없기 때문에
            // 하지만 valueKey와 labelKey가 무조건 존재한다고 확신할 수 있는 상황이기 때문에
            // Typescript에 valueKey와 labelKey는 항상 존재하는 키값이라고 선언해줄 수 있음 (as keyof)
            getOptionValue={option => option[valueKey as keyof OptionType]}
            getOptionLabel={option => option[labelKey as keyof OptionType]}
            {...restProps}
        />
    );
};

export default ReactSelect;
