import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledStatus = styled.span`
    max-width: 100%;
    height: 25px;
    color: ${props => props.color} !important;
    font-weight: bold;
`;

export const StyledStatusLabel = ({ statusName, displayColor, className }) => {
    return (
        <StyledStatus
            className={`pnt-txt txt-border text-ellipsis ${className}`}
            color={displayColor}
            title={statusName}
        >
            {statusName}
        </StyledStatus>
    );
};

export const departmentName = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Name'),
        width: 100,
        accessor: 'groupName',
        ...props,
    };
};

export const departmentID = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;ID'),
        width: 100,
        accessor: 'groupId',
        ...props,
    };
};

export const departmentPath = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Department path'),
        accessor: 'groupNamePath',
        ...props,
    };
};

export const userName = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;User name'),
        width: 100,
        accessor: 'userName',
        ...props,
    };
};

export const userID = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;ID'),
        accessor: 'userID',
        className: 'text-ellipsis',
        ...props,
    };
};

export const userTeam = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Team'),
        accessor: 'groupName',
        ...props,
    };
};

export const checkBox = props => {
    return {
        Header: ({}) => {
            return <input type="checkbox" />;
        },
        width: 50,
        accessor: 'checkBox',
        Cell: ({ row: { original } }) => {
            return (
                <input
                    type="checkbox"
                    name={'categoryCode'}
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
            );
        },
    };
};
export const category = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Category'),
        accessor: 'categoryNamePath',
        ...props,
    };
};

export const name = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Name'),
        accessor: 'targetName',
        ...props,
    };
};

export const filterName = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Filter Name'),
        accessor: 'filterName',
        width: 100,
        ...props,
    };
};

export const num = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Number'),
        accessor: 'targetNum',
        ...props,
    };
};

export const location = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Location'),
        accessor: 'floorNamePath',
        ...props,
    };
};

export const status = props => {
    const { t } = useTranslation();

    return {
        Header: t('Table;Status'),
        accessor: 'statusId',
        Cell: row => {
            const selectedStatus = props && props.rows.find(status => status.statusId === row.value);

            return selectedStatus ? (
                <StyledStatusLabel {...selectedStatus} />
            ) : (
                // <StyledStatus className="pnt-txt txt-border" color={selectedStatus.displayColor}>
                //     {selectedStatus.statusName}
                // </StyledStatus>
                <span></span>
            );
        },
        ...props,
    };
};

export const owner = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Owner'),
        accessor: 'properties',
        Cell: ({ value }) => {
            if (value.pic_id) {
                return <span>{value.pic_id}</span>;
            } else {
                return <span></span>;
            }
        },
    };
};

export const ownerForFilter = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Owner'),
        accessor: 'groupNamesForOwner',
        Cell: ({ value }) => {
            if (value === '-1') {
                return <span>{t('Filter;All users')}</span>;
            } else {
                return <span>{value}</span>;
            }
        },
        ...props,
    };
};

export const accessRights = props => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Access rights'),
        accessor: 'groupNamesForAccessRight',
        Cell: ({ value }) => {
            if (value === '-1') {
                return <span>{t('Filter;All users')}</span>;
            } else {
                return <span>{value}</span>;
            }
        },
        ...props,
    };
};

export const favorite = ({ handleOpenModal, ...props }) => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Favorite'),
        accessor: 'favorite',
        width: 50,
        ...props,
        Cell: ({ value }) => {
            return value ? (
                <button
                    onClick={e => {
                        e.stopPropagation();
                        handleOpenModal();
                    }}
                >
                    <span className="icon-star icon-filter-red" />
                </button>
            ) : (
                <button
                    onClick={e => {
                        e.stopPropagation();
                        handleOpenModal();
                    }}
                >
                    <span className="icon-star icon-filter-gray" />
                </button>
            );
        },
    };
};
