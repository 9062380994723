import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import Button from '../../../../../Common/Button';
import DetailLabel from '../../../Components/DetailLabel';
import { useDispatch, useSelector } from 'react-redux';
import useConfirmModal from '../../../../../Common/ConfirmModal/useConfirmModal';
import useAsync from '../../../../../../util/hooks/useAsync';
import {
    createMappedStatusListInfoApi,
    deleteMappedStatusListInfoApi,
    getMappedStatusListInfoApi,
    getStatusSchemeListInfoApi,
} from '../../../../../../api/status';
import { setMappedStatusListInfo, setStatusSchemeListInfo } from '../../../../../../reducers/Common/StatusInfo';
import ComeGoBasket from '../../../Components/ComeGoBasket';
import { FlowSchemeStateContext } from '../index';

const INPUT_WIDTH = 317;

const initStatusSchemeMappInfo = [];

const AddStatusModal = ({ isOpen, toggle }) => {
    const dispatch = useDispatch();
    const t = useTranslation('Status');
    const buttonT = useTranslation('Button');
    // const { serviceCode } = useSelector(state => state.AppConfig);
    const { flowSchemeInfo, flowStatusList } = useContext(FlowSchemeStateContext);
    const [currFlowStatusList, setCurrFlowStatusList] = useState(
        flowStatusList.filter(v => v.flowNum === flowSchemeInfo.flowNum),
    );

    useEffect(() => {
        setCurrFlowStatusList(flowStatusList.filter(v => v.flowNum === flowSchemeInfo.flowNum));
    }, [flowSchemeInfo, flowStatusList, isOpen]);

    const {
        statusListInfo: { rows: statusList },
    } = useSelector(state => state.StatusInfo);
    const [statusSchemeMappInfo, setStatusSchemeMappInfo] = useState(initStatusSchemeMappInfo);

    useEffect(() => {
        if (currFlowStatusList && currFlowStatusList.length) {
            setStatusSchemeMappInfo(currFlowStatusList.map(v => v.statusNum));
        } else {
            setStatusSchemeMappInfo(initStatusSchemeMappInfo);
        }
    }, [currFlowStatusList]);

    const { promise: getMappedStatusListInfo } = useAsync({
        promise: getMappedStatusListInfoApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            dispatch(setMappedStatusListInfo(res));
        },
    });

    const { promise: createMappedStatusListInfo } = useAsync({
        promise: createMappedStatusListInfoApi,
        resolve: res => {
            toggleSaveCompleteModal();
        },
        reject: err => {
            console.log({ err });
            if (err.data && err.data.code === '6011') {
                toggleRequestBatchTransition();
            }
        },
    });
    const {
        promise: deleteMappedStatusListInfo,
        state: { request },
    } = useAsync({
        promise: deleteMappedStatusListInfoApi,
        resolve: res => {
            // toggleSaveCompleteModal();
            // setStatusSchemeMappInfo(comeItems.map(v => v.statusNum));
            setCurrFlowStatusList(
                statusList.filter(v =>
                    statusSchemeMappInfo.filter(statusNum => statusNum !== request.statusNum).includes(v.statusNum),
                ),
            );
        },
        reject: err => {
            console.log({ err });
            if (err.data && err.data.code === '6011') {
                toggleRequestBatchTransition();
            }
        },
    });

    const { toggleModal: toggleSaveConfirmModal, Modal: SaveConfirmModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Do you want to save?'),
        okCallback: () => {
            // if (statusSchemeMappInfo.length) {
            createMappedStatusListInfo({
                flowNum: flowSchemeInfo.flowNum,
                schemeNum: flowSchemeInfo.schemeNum,
                statusNums: statusSchemeMappInfo.join(','),
            });
            // } else {
            //     deleteMappedStatusListInfo({ schemeNum: flowSchemeInfo.schemeNum });
            // }
        },
    });
    const { toggleModal: toggleSaveCompleteModal, Modal: SaveCompleteModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Save is complete.'),
        okCallback: () => {
            toggle();
            getMappedStatusListInfo();
        },
    });
    const { toggleModal: toggleRequestBatchTransition, Modal: RequestBatchTransition } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: (
            <div>
                <div>{t('There are assets mapped to the state you want to delete.')}</div>
                <div>{t('Please try again after changing the status of the asset.')}</div>
                <div className={'font-weight-bold'}>{`${t('Status batch transition')} : ${t(
                    'Status > Status Scheme > Status Scheme Tab',
                )}`}</div>
            </div>
        ),
    });

    return (
        <>
            <Modal
                container={document.getElementsByClassName('app-container')[0]}
                isOpen={isOpen}
                toggle={toggle}
                className={'pnt-modal'}
            >
                <ModalHeader>{t('Add Status')}</ModalHeader>
                <ModalBody style={{ display: 'grid', rowGap: '5px' }}>
                    <DetailLabel
                        name={t('Status setting')}
                        value={
                            <div style={{ width: INPUT_WIDTH }}>
                                <ComeGoBasket
                                    key={JSON.stringify(currFlowStatusList)}
                                    valueKey={'statusNum'}
                                    labelKey={'statusName'}
                                    initComeItems={currFlowStatusList}
                                    initGoItems={statusList.filter(
                                        v => !currFlowStatusList.find(mapped => mapped.statusNum === v.statusNum),
                                    )}
                                    onDelete={selected => {
                                        deleteMappedStatusListInfo({
                                            schemeNum: flowSchemeInfo.schemeNum,
                                            flowNum: flowSchemeInfo.flowNum,
                                            statusNum: selected.statusNum,
                                        });
                                    }}
                                    onChange={({ comeItems }) => {
                                        setStatusSchemeMappInfo(comeItems.map(v => v.statusNum));
                                    }}
                                />
                            </div>
                        }
                    />
                </ModalBody>
                <ModalFooter>
                    <Button className="color-brand" onClick={toggle}>
                        {buttonT('Cancel')}
                    </Button>
                    <Button
                        className={'btn-brand'}
                        iconClassName={'icon-check'}
                        onClick={() => {
                            toggleSaveConfirmModal();
                        }}
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '80px',
                        }}
                    >
                        {buttonT('Ok')}
                    </Button>
                </ModalFooter>
            </Modal>
            <SaveConfirmModal />
            <SaveCompleteModal />
            <RequestBatchTransition />
        </>
    );
};

export default AddStatusModal;
