import React, { useEffect, useState } from 'react';
import { fetchCategory } from '../../../../api/asset';
import useAsync from '../../../../util/hooks/useAsync';
import TreeSelect from '../../Components/Select/TreeSelectWithOption';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CategorySearchableSelect = ({ selected, changeable, handleChange, hideOption, className }) => {
    const { t } = useTranslation();

    const [categoryList, setCategoryList] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const { serviceCategory } = useSelector(state => state.CategoryInfo);

    useAsync({
        promise: fetchCategory,
        fixedParam: {
            isAll: 'Y',
            parentCode: serviceCategory,
        },
        immediate: true,
        resolve: res => {
            setCategoryList(
                res.rows.map(v => {
                    return { ...v, categoryCodes: v.categoryCode };
                }),
            );
        },
    });

    useEffect(() => {
        if (!selected || !selected.length) {
            setSelectedOption([]);
        } else if (categoryList.length) {
            setSelectedOption(selected);
        }
    }, [selected, categoryList]);

    const handleSelectedOptionChange = (selected, changeable) => {
        handleChange(selected, changeable);
        setSelectedOption(selected);
    };

    return (
        <TreeSelect
            data={categoryList}
            title={t('Filter;Asset Categorization')}
            valueKey={'categoryCode'}
            parentKey={'parentCode'}
            labelKey={'categoryName'}
            selected={selectedOption}
            className={className ? className : 'mr-2 mb-2 mt-2'}
            onChange={handleSelectedOptionChange}
            changeable={changeable}
            hideOption={hideOption}
        />
    );
};

export default CategorySearchableSelect;
