import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import defaultConfig, { monitoringConfig } from './config';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { setTagInfos } from '../../../../reducers/TagInfo';
import useSocket from '../../../../util/socket/hooks/useSocket';
import FilterWidget from '../../Filter/FilterWidget';
import { updateFilterInfo } from '../../../../reducers/FilterInfo';

const ResponsiveGridLayout = WidthProvider(Responsive);
const RENDERING_TIME = 0;

const DashboardFrame = ({ children, layouts, ...rest }) => {
    const dispatch = useDispatch();
    const { socket, setSocketEvent } = useSocket();
    const { enableClosedSidebar } = useSelector(state => state.ThemeOptions);
    const { filterInfo } = useSelector(state => state.FilterInfo);
    const { serviceCategoryCodes } = useSelector(state => state.CategoryInfo);
    const { mode } = useSelector(state => state.DashboardFrame);
    const [modeConfig, setModeConfig] = useState({});

    const socketBuffer = useRef({
        interval: null,
        buffer: [],
    });
    const flushData = () => {
        // current buffer 어레이가 있는 경우에만 실행
        if (socketBuffer.current.buffer.length > 0) {
            dispatch(setTagInfos(socketBuffer.current.buffer.splice(0)));
        }
    };
    const flushDataInterval = () => {
        clearInterval(socketBuffer.current.interval);
        socketBuffer.current.interval = setInterval(() => {
            flushData();
        }, RENDERING_TIME);
    };
    const stopFlushDataInterval = () => {
        flushData();
        clearInterval(socketBuffer.current.interval);
        socketBuffer.current.interval = null;
    };

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [enableClosedSidebar]);

    // useEffect(() => {
    //     console.log({ getFloorListInfo });
    // }, [getFloorListInfo]);

    useEffect(() => {
        if (mode !== 'edit') {
            setModeConfig(monitoringConfig);

            flushDataInterval();
            if (socket) {
                setSocketEvent('updateMarker', function (data) {
                    // dispatch(setTagInfo(data));
                    socketBuffer.current.buffer.push(data);
                });
            }
            // updateMarker
            // notification
            // changeSensorValue
        } else {
            setModeConfig({});
        }
        return () => {
            stopFlushDataInterval();
            if (socket) {
                setSocketEvent('updateMarker');
            }
        };
    }, [mode, socket]);

    return (
        <>
            {serviceCategoryCodes && <FilterWidget
                filterInfo={filterInfo}
                handleFilterConditionChange={selected => {
                    dispatch(
                        updateFilterInfo({
                            filterInfoCondition: filterInfo.filterInfoCondition.map(conditionInfo => {
                                if (conditionInfo.conditionId === selected.conditionId) {
                                    return { ...conditionInfo, conditionValues: selected.conditionValues };
                                }
                                return conditionInfo;
                            }),
                        }),
                    );
                }}
            />}
            <ResponsiveGridLayout key={mode} {...defaultConfig} layouts={layouts} {...rest} {...modeConfig}>
                {children}
            </ResponsiveGridLayout>
        </>
    );
};

export default DashboardFrame;
