import React from 'react';
import Ionicon from 'react-ionicons';
import { useHistory } from 'react-router-dom';

const SearchDots = () => {
    const history = useHistory();

    return (
        <div className="header-dots mr-2" onClick={() => history.push('/assets/quickSearch')}>
            <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                <div className="icon-wrapper-bg" style={{ backgroundColor: '#b1e8f0' }} />
                <Ionicon beat={false} color="#4c5fdb" fontSize="23px" className="icon-search" />
            </div>
        </div>
    );
};

export default SearchDots;
