import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import DetailModalHeader from '../../Components/DetailModalHeader';
import useTranslation from '../../../../../util/hooks/useTranslation';
import DetailLabel from '../../Components/DetailLabel';
import Select from '../../../../Common/Select/SingleSelect';
import { useSelector } from 'react-redux';
import Button from '../../../../Common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Table from '../../../../Common/Table';
import useAsync from '../../../../../util/hooks/useAsync';
import { getAssetListApi } from '../../../../../api/asset';
import useColumns from '../../../../../util/hooks/useColumns';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import { updateStatusBatchApi } from '../../../../../api/status';

const PAGE_SIZE = 50;

const initSearchParam = { flowScheme: null, status: null };
const initTransitionParam = { flowNum: null, statusNum: null, targetNums: [] };

const TransitionBatchModal = ({ isOpen, toggle }) => {
    const t = useTranslation('Status');
    const buttonT = useTranslation('Button');
    const tableT = useTranslation('Table');
    const { flowSchemeListInfo, mappedStatusInfo } = useSelector(state => state.StatusInfo);
    const flowSchemeList = flowSchemeListInfo.rows.map(v => ({
        value: v.flowNum,
        label: v.flowName,
        schemeNum: v.schemeNum,
    }));
    const [searchParam, setSearchParam] = useState(initSearchParam);
    const { filteredList, noneStatusList } = useMemo(() => {
        let filteredList = [];
        let noneStatusList = [];
        if (searchParam.flowScheme) {
            const statusList = mappedStatusInfo[searchParam.flowScheme.schemeNum];
            if (statusList) {
                filteredList = statusList.map(v => ({ value: v.statusNum, label: v.statusName }));
            }
            noneStatusList = [{ value: null, label: t('No status') }].concat(filteredList);
        }
        return { filteredList, noneStatusList };
    }, [t, searchParam.flowScheme, mappedStatusInfo]);

    const [transitionParam, setTransitionParam] = useState(initTransitionParam);

    const [assetList, setAssetList] = useState({ rows: [] });
    const { promise: getAssetList } = useAsync({
        promise: getAssetListApi,
        fixedParam: { pageSize: PAGE_SIZE },
        resolve: res => {
            setAssetList({ ...res, pageSize: PAGE_SIZE });
        },
    });
    const { promise: updateStatusBatch } = useAsync({
        promise: updateStatusBatchApi,
        resolve: res => {
            toggleSaveCompleteModal();
        },
        reject: res => {
            toggleSaveFailureModal();
        },
    });
    const assetListColumns = useColumns(
        [
            {
                Header: (
                    <input
                        style={{ margin: 0 }}
                        type={'checkbox'}
                        name={'checkAll'}
                        onChange={e => {
                            const { checked } = e.currentTarget;
                            if (checked) {
                                setTransitionParam({
                                    ...transitionParam,
                                    targetNums: assetList.rows.map(v => v.targetNum),
                                });
                            } else {
                                setTransitionParam({ ...transitionParam, targetNums: [] });
                            }
                        }}
                    />
                ),
                headerClassName: 'flex-center',
                width: 50,
                accessor: 'selector',
                Cell: ({ row: { original } }) => {
                    const value = original.targetNum;
                    const exist = transitionParam.targetNums.find(v => value === v);
                    return (
                        <input
                            style={{ marginLeft: '0.5rem' }}
                            type="checkbox"
                            name={'asset'}
                            checked={exist}
                            onChange={e => {
                                e.stopPropagation();
                                if (exist) {
                                    setTransitionParam({
                                        ...transitionParam,
                                        targetNums: transitionParam.targetNums.filter(v => v !== value),
                                    });
                                } else {
                                    setTransitionParam({
                                        ...transitionParam,
                                        targetNums: transitionParam.targetNums.concat(value),
                                    });
                                }
                                // setChecked(prevState => {
                                //     const checkBoxIdx = prevState.indexOf(original);
                                //     if (checkBoxIdx === -1) {
                                //         return [...prevState, original];
                                //     } else {
                                //         prevState.splice(checkBoxIdx, 1);
                                //         return prevState;
                                //     }
                                // });
                            }}
                        />
                    );
                },
            },
            {
                Header: 'Number',
                accessor: 'targetNum',
                width: 100,
            },
            {
                Header: 'ID',
                accessor: 'targetId',
            },
            {
                Header: 'Name',
                accessor: 'targetName',
                Cell: ({ value }) => {
                    return (
                        <div className={'text-ellipsis'} title={value}>
                            {value}
                        </div>
                    );
                },
            },
            {
                Header: 'Category',
                accessor: 'categoryNamePath',
            },
        ],
        tableT,
        [assetList, transitionParam],
    );

    const callList = searchParam => {
        const searchParams = {};
        const { flowScheme, status, page } = searchParam;
        if (flowScheme) {
            searchParams.flowNum = flowScheme.value;
        }
        if (status) {
            if (status.value) {
                searchParams.statusNums = status.value + '';
            } else {
                searchParams.includeStatus = 'N';
            }
        } else {
            searchParams.includeStatus = 'N';
        }
        getAssetList({ ...searchParams, page });
    };
    const onPageChange = useCallback(
        pageIndex => {
            if (searchParam.flowScheme) {
                callList({ ...searchParam, page: pageIndex });
            }
        },
        [searchParam],
    );

    const { toggleModal: toggleSaveConfirmModal, Modal: SaveConfirmModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Do you want to apply bulk changes?'),
        okCallback: () => {
            updateStatusBatch(transitionParam);
        },
    });
    const { toggleModal: toggleSaveCompleteModal, Modal: SaveCompleteModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Save is complete.'),
        removeCancel: true,
        callbackParam: searchParam,
        okCallback: searchParam => {
            callList(searchParam);
        },
        cancelCallback: searchParam => {
            callList(searchParam);
        },
    });
    const { toggleModal: toggleSaveFailureModal, Modal: SaveFailureModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Save is failure.'),
    });

    const { toggleModal: toggleRequestRequiredModal, Modal: RequestRequiredModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Please select one or more assets.'),
    });

    useEffect(() => {
        if (searchParam.flowScheme) {
            callList(searchParam);
        }
    }, [searchParam]);

    useEffect(() => {
        return () => {
            setSearchParam(initSearchParam);
            setTransitionParam(initTransitionParam);
            setAssetList({ rows: [] });
        };
    }, [isOpen]);

    return (
        <>
            <Modal
                container={document.getElementsByClassName('app-container')[0]}
                isOpen={isOpen}
                toggle={toggle}
                className={'pnt-modal'}
                style={{ width: 'max-content', maxWidth: '100%' }}
            >
                <DetailModalHeader
                    headerTitleStyle={{ maxWidth: '100%' }}
                    title={t('Transition batch')}
                    subTitle={t(
                        'This is a function to collectively change the status of selected assets to other statuses.',
                    )}
                />
                <ModalBody style={{ display: 'grid', rowGap: '10px' }}>
                    <DetailLabel
                        required
                        name={t('Status flow scheme')}
                        value={
                            <Select
                                value={searchParam.flowScheme && searchParam.flowScheme.value}
                                name={'flowNum'}
                                options={flowSchemeList}
                                className={'form-must w-340'}
                                onChange={selected => {
                                    const selectedScheme = flowSchemeList.find(
                                        flowScheme => flowScheme.value === selected,
                                    );
                                    setSearchParam({
                                        ...searchParam,
                                        flowScheme: selectedScheme,
                                        status: null,
                                    });
                                    setTransitionParam({
                                        ...transitionParam,
                                        flowNum: selected,
                                        statusNum: null,
                                        targetNums: [],
                                    });
                                }}
                            />
                        }
                    />
                    <DetailLabel
                        required
                        name={t('Transition')}
                        value={
                            <div style={{ width: 340, display: 'grid', gridTemplateColumns: '1fr 30px 1fr' }}>
                                <Select
                                    value={searchParam.status && searchParam.status.value}
                                    name={'statusNum'}
                                    options={noneStatusList}
                                    className={'form-must w-auto'}
                                    // customControlStyles={{ width: 340 }}
                                    // customMenuStyles={{ width: 340 }}
                                    onChange={selected => {
                                        setSearchParam({
                                            ...searchParam,
                                            status: noneStatusList.find(status => status.value === selected),
                                            targetNums: [],
                                        });
                                    }}
                                />
                                <div className={'flex-center'} style={{ fontSize: '1rem' }}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                                <Select
                                    value={transitionParam.statusNum || ''}
                                    name={'targetStatusNum'}
                                    options={filteredList}
                                    className={'form-must w-auto'}
                                    // customControlStyles={{ width: 340 }}
                                    // customMenuStyles={{ width: 340 }}
                                    onChange={selected => {
                                        setTransitionParam({ ...transitionParam, statusNum: selected });
                                    }}
                                />
                            </div>
                        }
                    />
                    <div className={'mt-1'} style={{ height: 500 }}>
                        <Table
                            data={assetList}
                            columns={assetListColumns}
                            textAlign={'center'}
                            // onTrClick={({ targetNum }) => {}}
                            onPageChange={onPageChange}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button style={{ color: '#FF7800' }} onClick={toggle}>
                        {buttonT('Close')}
                    </Button>
                    <Button
                        className={'btn-brand'}
                        iconClassName={'icon-check'}
                        onClick={() => {
                            if (transitionParam.targetNums.length) {
                                toggleSaveConfirmModal();
                            } else {
                                toggleRequestRequiredModal();
                            }
                        }}
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {t('Execution')}
                    </Button>
                </ModalFooter>
            </Modal>
            <SaveConfirmModal />
            <SaveCompleteModal />
            <SaveFailureModal />
            <RequestRequiredModal />
        </>
    );
};

export default TransitionBatchModal;
