import React, { useEffect, useMemo, useState } from 'react';
import ReactSelect from '../../../Common/Select';
import TextInput from '../../../Common/Input/TextInput';
import { useTranslation } from 'react-i18next';
import Card from '../../../Common/Card';
import useAsync from '../../../../util/hooks/useAsync';
import * as col from '../../Components/Grid/column';
import Table from '../../../Common/Table';
import { getAssetListApi, getStatusInfoApi } from '../../../../api/asset';
import { Col, Row } from 'reactstrap';
import { getSelectedFilterApi } from '../../../../api/filter';
import { initialFilterInfo, makeQueryParam } from '../../../../reducers/FilterInfo';
import FilterWidget from '../../../Filter';
import { useSelector } from 'react-redux';

const tableHeight = { height: 'calc(100vh - 60px - 60px - 150px - 100px)' };
const PAGE_SIZE = 20;

const CustomFilter = ({ match: { params }, history }) => {
    const { t } = useTranslation();
    const { serviceCategoryCodes } = useSelector(state => state.CategoryInfo);
    const { filterNum } = params;
    const [keyword, setKeyword] = useState('');
    const [assetStatusList, setAssetStatusList] = useState();
    const [filterCondition, setFilterCondition] = useState({});
    const [tableUpdateKey, setTableUpdateKey] = useState(Date.now());

    const [searchParam, setSearchParam] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState();

    const assetListColumns = [
        col.category(),
        col.name(),
        col.num(),
        col.owner(),
        col.location(),
        col.status(assetStatusList),
    ];
    const searchOptions = useMemo(() => {
        return [
            { value: 'targetName', label: t('Filter;Name') },
            { value: 'targetId', label: t('Filter;ID') },
        ];
    }, [t]);
    const [assetList, setAssetList] = useState({ rows: [], pageSize: PAGE_SIZE });

    useAsync({
        promise: getSelectedFilterApi,
        param: { filterNum },
        immediate: true,
        deps: [filterNum],
        resolve: res => {
            const filterInfo = res;
            filterInfo.filterInfoCondition = filterInfo.filterInfoCondition.map(filterInfo => {
                const filter = initialFilterInfo.filterInfoCondition.find(
                    defaultFilter => filterInfo.conditionId === defaultFilter.conditionId,
                );
                return { ...filter, ...filterInfo };
            });
            setSelectedFilter(filterInfo);
        },
    });
    const { promise: getAssetList } = useAsync({
        promise: getAssetListApi,
        fixedParam: { pageSize: PAGE_SIZE, categoryCodes: serviceCategoryCodes },
        resolve: res => {
            setAssetList({ ...res, pageSize: PAGE_SIZE });
        },
    });
    const { promise: getStatusInfo } = useAsync({
        promise: getStatusInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setAssetStatusList(res);
        },
    });

    const [selectedOption, setSelectedOption] = useState(null);
    const handleSelect = selected => {
        setSelectedOption(selected);
    };
    const handleInputChange = e => {
        setKeyword(e.target.value);
    };

    const mergeParam = () => {
        if (!filterCondition.categoryCodes || !filterCondition.categoryCodes.length) {
            filterCondition.categoryCodes = serviceCategoryCodes.split(',');
        }
        const searchParam = makeQueryParam(filterCondition);
        // for (let conditionId in filterCondition) {
        //     const selectedCondition = filterCondition[conditionId];
        //     if (selectedCondition && selectedCondition.length) {
        //         searchParam[conditionId] = filterCondition[conditionId].join(',');
        //     }
        // }
        if (selectedOption && keyword) {
            searchParam.opt = selectedOption.value;
            searchParam.keyword = keyword;
        }
        return searchParam;
    };

    const handleBtnClick = e => {
        setSearchParam(mergeParam());
    };

    useEffect(() => {
        setSelectedOption(null);
        setKeyword('');
    }, [filterNum]);

    useEffect(() => {
        if (selectedFilter) {
            getStatusInfo();
            const filterCondition = (selectedFilter.filterInfoCondition || []).reduce((acc, curr) => {
                acc[curr.conditionId] = curr.conditionValues;
                return acc;
            }, {});
            if (!filterCondition.categoryCodes || !filterCondition.categoryCodes.length) {
                filterCondition.categoryCodes = serviceCategoryCodes.split(',');
            }
            setSearchParam(makeQueryParam(filterCondition));
        }
    }, [selectedFilter]);

    useEffect(() => {
        setTableUpdateKey(Date.now());
        if (searchParam !== null) {
            getAssetList(searchParam);
        }
    }, [searchParam]);

    return (
        <div>
            <div className="card filter_ver3">
                <FilterWidget
                    filterInfo={selectedFilter}
                    handleChange={filterInfo => {
                        setFilterCondition(filterInfo);
                    }}
                />
                <div className="filter__this-page">
                    <div className="filter__info">
                        <div className="pnt-label--group">
                            <span className="label-main">{t('Search;Search')}</span>
                        </div>
                    </div>
                    <div className="filter__options">
                        <Row className={'w-100'}>
                            <Col md={'3'} className={'pb-1 pl-3 pr-0'}>
                                <ReactSelect
                                    name={'search for list'}
                                    onChange={handleSelect}
                                    value={selectedOption}
                                    options={searchOptions}
                                    customControlStyles={{ width: '100%' }}
                                    customOptionStyles={{ width: '100%' }}
                                    customMenuStyles={{ width: '100%' }}
                                />
                            </Col>
                            <Col md={'5'} className={'d-flex w-100 pl-3 pr-0'}>
                                <TextInput
                                    type={'text'}
                                    name={'textInput'}
                                    value={keyword}
                                    placeholder={t('Filter;Input keyword')}
                                    handleChange={handleInputChange}
                                    size={100}
                                />
                                <button
                                    className="pnt-btn btn-icon-only btn-orange none-under-770 ml-1"
                                    onClick={handleBtnClick}
                                >
                                    <span className="icon-search" />
                                </button>
                            </Col>
                        </Row>
                    </div>
                    <div className="filter__function">
                        <button className="pnt-btn btn-icon-only btn-orange none-over-770">
                            <span className="icon-search" />
                        </button>
                    </div>
                </div>
            </div>
            <Card
                className={'mb-0'}
                header={{
                    // 높이 유지를 위해 공백문자열
                    title: selectedFilter ? selectedFilter.filterName : ' ',
                }}
            >
                <div style={tableHeight}>
                    <Table
                        key={tableUpdateKey}
                        data={assetList}
                        columns={assetListColumns}
                        textAlign={'center'}
                        onTrClick={({ targetNum }) => {
                            history.push(`/assets/detail/${targetNum}`);
                        }}
                        onPageChange={pageIndex => {
                            if (searchParam !== null) {
                                getAssetList({ ...searchParam, page: pageIndex });
                            }
                            // if (filterParam.categoryCodes) {
                            //     const requestParam = {
                            //         pageSize: PAGE_SIZE,
                            //         opt: inputOption.value,
                            //         keyword: keyword,
                            //         page: pageIndex,
                            //         ...filterParam,
                            //     };
                            //     getAssetList(requestParam);
                            // }
                        }}
                    />
                </div>
            </Card>
        </div>
    );
};

export default CustomFilter;
