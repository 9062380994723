import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const SET_FLOW_SCHEME = 'flowScheme/SET_FLOW_SCHEME';
const SET_FLOW_STATUS_LIST = 'flowScheme/SET_FLOW_STATUS_LIST';
const SET_FLOW_TRANSITION_LIST = 'flowScheme/SET_FLOW_TRANSITION_LIST';
const SET_FLOW_DRAW_JSON = 'flowScheme/SET_FLOW_DRAW_JSON';

export const { setFlowScheme, setFlowStatusList, setFlowTransitionList, setFlowDrawJson } = createActions(
    {
        SET_FLOW_SCHEME: flowScheme => flowScheme,
        SET_FLOW_STATUS_LIST: statusList => statusList,
        SET_FLOW_TRANSITION_LIST: transitionList => transitionList,
        SET_FLOW_DRAW_JSON: flowDrawJson => flowDrawJson,
    },
    {
        prefix: 'flowScheme',
    },
);

export const initialState = {
    flowSchemeInfo: {},
    flowDrawJson: null,
    flowStatusList: [],
    flowTransitionList: [],
};

const flowSchemeReducer = handleActions(
    {
        [SET_FLOW_SCHEME]: (state, action) => {
            return produce(state, draft => {
                const flowSchemeInfo = action.payload;
                draft.flowSchemeInfo = flowSchemeInfo;
                try {
                    draft.flowDrawJson = JSON.parse(flowSchemeInfo.flowDrawJson);
                } catch (e) {
                    console.log(e);
                    draft.flowDrawJson = null;
                }
            });
        },
        [SET_FLOW_STATUS_LIST]: (state, action) => {
            return produce(state, draft => {
                draft.flowStatusList = action.payload;
            });
        },
        [SET_FLOW_TRANSITION_LIST]: (state, action) => {
            return produce(state, draft => {
                draft.flowTransitionList = action.payload;
            });
        },
        [SET_FLOW_DRAW_JSON]: (state, action) => {
            return produce(state, draft => {
                try {
                    draft.flowDrawJson = JSON.parse(action.payload);
                } catch (e) {
                    console.log(e);
                    draft.flowDrawJson = null;
                }
            });
        },
    },
    initialState,
);

export default flowSchemeReducer;
