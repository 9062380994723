import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Button from '../../../../Common/Button';
import DetailModalHeader from '../../Components/DetailModalHeader';
import TextInput from '../../../../Common/Input/TextInput';
import DetailLabel from '../../Components/DetailLabel';
import { useDispatch, useSelector } from 'react-redux';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import useAsync from '../../../../../util/hooks/useAsync';
import {
    createStatusSchemeInfoApi,
    getStatusSchemeListInfoApi,
    updateStatusSchemeInfoApi,
} from '../../../../../api/status';
import { setStatusSchemeListInfo } from '../../../../../reducers/Common/StatusInfo';
import { trim } from '../../../../../util/common/util';

const INPUT_WIDTH = 317;

const initStatusSchemeDetailInfo = {
    schemeName: '',
    flowNum: null,
    description: '',
    mappedStatus: [],
};

const StatusSchemeDetail = ({ isOpen, toggle, statusInfo }) => {
    const dispatch = useDispatch();
    const t = useTranslation('Status');
    const buttonT = useTranslation('Button');
    const { serviceCode } = useSelector(state => state.AppConfig);
    const {
        flowSchemeListInfo: { rows: flowSchemeList },
        statusListInfo: { rows: statusList },
    } = useSelector(state => state.StatusInfo);
    const [statusSchemeDetailInfo, setStatusSchemeDetailInfo] = useState(initStatusSchemeDetailInfo);

    useEffect(() => {
        if (statusInfo) {
            setStatusSchemeDetailInfo(statusInfo);
        } else {
            setStatusSchemeDetailInfo(initStatusSchemeDetailInfo);
        }
    }, [statusInfo]);

    const { promise: getStatusSchemeListInfo } = useAsync({
        promise: getStatusSchemeListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setStatusSchemeListInfo(res));
        },
    });

    const { promise: createStatusSchemeInfo } = useAsync({
        promise: createStatusSchemeInfoApi,
        resolve: res => {
            toggleSaveCompleteModal();
        },
        reject: err => {
            console.log({ err });
        },
    });
    const { promise: updateStatusSchemeInfo } = useAsync({
        promise: updateStatusSchemeInfoApi,
        resolve: res => {
            toggleSaveCompleteModal();
        },
        reject: err => {
            console.log({ err });
        },
    });

    const { toggleModal: toggleSaveConfirmModal, Modal: SaveConfirmModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Do you want to save?'),
        okCallback: () => {
            const {
                statusCategoryName,
                schemeNum,
                schemeName,
                comNum,
                modDate,
                modUserNum,
                regDate,
                regUserNum,
                mappedStatus,
                flowNum,
                flowName,
                ...detailInfo
            } = statusSchemeDetailInfo;
            if (schemeNum) {
                updateStatusSchemeInfo({
                    ...detailInfo,
                    schemeName: trim(schemeName),
                    schemeNum,
                    serviceCode,
                });
            } else {
                createStatusSchemeInfo({
                    ...detailInfo,
                    schemeName: trim(schemeName),
                    // flowNum,
                    serviceCode,
                });
            }
        },
    });
    const { toggleModal: toggleSaveCompleteModal, Modal: SaveCompleteModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Save is complete.'),
        okCallback: () => {
            toggle();
            getStatusSchemeListInfo();
        },
    });
    const { toggleModal: toggleRequestRequiredModal, Modal: RequestRequiredModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Please fill in the required fields. ( Display Name )'),
    });

    const handleChange = e => {
        const { value, name } = e.currentTarget;
        setStatusSchemeDetailInfo({ ...statusSchemeDetailInfo, [name]: value });
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                container={document.getElementsByClassName('app-container')[0]}
                className={'pnt-modal'}
            >
                <DetailModalHeader
                    title={`${t('Status Scheme')} - ${statusInfo ? t('Edit') : t('Add')}`}
                    subTitle={
                        statusInfo
                            ? t('This is a function to change the status scheme of an asset.')
                            : t('This is a function to add the status scheme of an asset.')
                    }
                />
                <ModalBody style={{ display: 'grid', rowGap: '5px' }}>
                    <DetailLabel
                        required
                        name={t('Display name')}
                        value={
                            // col-sm-10 p-0
                            <TextInput
                                style={{ width: INPUT_WIDTH }}
                                inputGroupClassName={'w-auto pnt-input--group form-must'}
                                type={'text'}
                                maxlength={50}
                                name={'schemeName'}
                                handleChange={handleChange}
                                value={statusSchemeDetailInfo.schemeName || ''}
                            />
                        }
                    />
                    {/*<DetailLabel*/}
                    {/*    name={t('Status flow scheme')}*/}
                    {/*    value={*/}
                    {/*        <Select*/}
                    {/*            value={*/}
                    {/*                statusSchemeDetailInfo.flowNum*/}
                    {/*                    ? {*/}
                    {/*                          value: statusSchemeDetailInfo.flowNum,*/}
                    {/*                          label: statusSchemeDetailInfo.flowName,*/}
                    {/*                      }*/}
                    {/*                    : null*/}
                    {/*            }*/}
                    {/*            name={'flowNum'}*/}
                    {/*            options={flowSchemeList.map(v => ({ value: v.flowNum, label: v.flowName }))}*/}
                    {/*            customControlStyles={{ width: INPUT_WIDTH }}*/}
                    {/*            customMenuStyles={{ width: INPUT_WIDTH }}*/}
                    {/*            onChange={selected => {*/}
                    {/*                setStatusSchemeDetailInfo({*/}
                    {/*                    ...statusSchemeDetailInfo,*/}
                    {/*                    flowNum: selected.value,*/}
                    {/*                    flowName: selected.label,*/}
                    {/*                });*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*/>*/}
                    <DetailLabel
                        name={t('Description')}
                        value={
                            <TextInput
                                style={{ width: INPUT_WIDTH }}
                                type={'text'}
                                inputGroupClassName={'w-auto'}
                                maxlength={200}
                                name={'description'}
                                handleChange={handleChange}
                                value={statusSchemeDetailInfo.description || ''}
                            />
                        }
                    />
                    {/*<DetailLabel*/}
                    {/*    name={t('Status scheme')}*/}
                    {/*    value={*/}
                    {/*        <div style={{ width: INPUT_WIDTH }}>*/}
                    {/*            <ComeGoBasket*/}
                    {/*                valueKey={'statusNum'}*/}
                    {/*                labelKey={'statusName'}*/}
                    {/*                initComeItems={statusSchemeDetailInfo.mappedStatus}*/}
                    {/*                initGoItems={statusList.filter(*/}
                    {/*                    v =>*/}
                    {/*                        !(statusSchemeDetailInfo.mappedStatus || []).find(*/}
                    {/*                            mapped => mapped.statusNum === v.statusNum,*/}
                    {/*                        ),*/}
                    {/*                )}*/}
                    {/*                onChange={({ comeItems }) => {*/}
                    {/*                    setStatusSchemeDetailInfo({*/}
                    {/*                        ...statusSchemeDetailInfo,*/}
                    {/*                        statusScheme: comeItems.map(v => v.statusNum),*/}
                    {/*                    });*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*/>*/}
                </ModalBody>
                <ModalFooter>
                    <Button style={{ color: '#FF7800' }} onClick={toggle}>
                        {buttonT('Cancel')}
                    </Button>
                    <Button
                        className={'btn-brand'}
                        iconClassName={'icon-check'}
                        onClick={() => {
                            if (trim(statusSchemeDetailInfo.schemeName)) {
                                toggleSaveConfirmModal();
                            } else {
                                toggleRequestRequiredModal();
                            }
                        }}
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {buttonT('Ok')}
                    </Button>
                </ModalFooter>
            </Modal>
            <SaveConfirmModal />
            <SaveCompleteModal />
            <RequestRequiredModal />
        </>
    );
};

export default StatusSchemeDetail;
