import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../Components/PageTitle';
import { Button } from 'reactstrap';
import DashboardFrame from '../DashboardFrame';
import useWidgetGenerator from '../util/useWidgetGenerator';
import { getMaxY } from '../util';
import moment from 'moment';
import cx from 'classnames';
import {
    setEditDashboard,
    editWidgets,
    editLayout,
    setTempWidgetLayout,
} from '../../../../reducers/Dashboards/DashboardEdit';
import { setMode } from '../../../../reducers/Dashboards/DashboardFrame';
import PalettePopup from '../../../Widgets/PalettePopup';
import { temp } from '../../../Widgets';
import useAsync from '../../../../util/hooks/useAsync';
import { getDashboardApi, updateDashboardApi } from '../../../../api/dashboard';
import ConfirmModal from '../../../Common/ConfirmModal';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';

const DashboardEdit = ({ history, match }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { promise: getDashboardInfo } = useAsync({
        promise: getDashboardApi,
        param: { menuNum: Number(match.params.menuNum) },
        resolve: response => {
            dispatch(setEditDashboard(response));
        },
    });
    const { promise: saveWidgetInfo } = useAsync({
        promise: updateDashboardApi,
        resolve: () => {
            const location = history.location;
            if (location.state && location.state.redirect) {
                const currMenuNum = Number(match.params.menuNum);
                history.push(`/dashboards/${currMenuNum}`);
            } else {
                history.goBack();
            }
        },
    });

    const [preView, setPreView] = useState(false);
    const handlePreviewToggle = () => {
        let mode = 'preview';
        if (preView) {
            mode = 'edit';
        }
        dispatch(setMode(mode));
        setPreView(!preView);
    };

    const [addWidgetPopup, setAddWidgetPopup] = useState(false);
    const handleAddWidgetPopup = () => {
        setAddWidgetPopup(!addWidgetPopup);
        dispatch(setTempWidgetLayout(null));
    };

    const [saveModal, setSaveModal] = useState(false);
    const toggleSaveModal = () => {
        setSaveModal(!saveModal);
    };

    const [cancelModal, setCancelModal] = useState(false);
    const toggleCancelModal = () => {
        setCancelModal(!cancelModal);
    };

    const [resetModal, setResetModal] = useState(false);
    const toggleResetModal = () => {
        setResetModal(!resetModal);
    };

    const handleSave = dashboardInfo => {
        const idPrefix = `d${dashboardInfo.menuNum}_`;
        const updateTime = moment().valueOf();
        saveWidgetInfo({
            ...dashboardInfo,
            widgetInfo: JSON.stringify(
                dashboardInfo.widgetInfo.reduce((acc, curr, i) => {
                    const newId = idPrefix + (updateTime + i).toString(36);
                    if (curr.type !== 'temp') {
                        acc.push({ ...curr, id: newId, layout: { ...curr.layout, i: newId } });
                    }
                    return acc;
                }, []),
            ),
        });
    };

    const editTimeout = useRef();
    const { origin, editable, tempWidgetLayout } = useSelector(state => state.DashboardEdit);
    const { layouts, Widgets } = useWidgetGenerator(editable.widgetInfo);

    const handleEditLayout = layout => {
        if (editTimeout.current) {
            clearTimeout(editTimeout.current);
            editTimeout.current = null;
        }
        editTimeout.current = setTimeout(() => {
            dispatch(editLayout(layout));
        }, 200);
    };

    const addPanel = e => {
        const id = moment().valueOf().toString(36);
        // console.log({ editable, widgetInfo: editable.widgetInfo, layouts });
        dispatch(
            editWidgets([
                ...editable.widgetInfo,
                {
                    id,
                    type: temp.type,
                    config: temp.config,
                    layout: {
                        ...temp.layout,
                        i: id,
                        x: 0,
                        y: getMaxY(layouts.lg),
                    },
                },
            ]),
        );
    };

    const backToOrigin = useCallback(() => {
        dispatch(editWidgets(origin.widgetInfo));
    }, [origin]);

    useEffect(() => {
        dispatch(setMode('edit'));

        if (!editable.menuNum || editable.menuNum !== Number(match.params.menuNum)) {
            dispatch(editWidgets([]));
            getDashboardInfo();
        }

        return () => {
            dispatch(setEditDashboard());
        };
    }, [match.url]);

    useEffect(() => {
        if (tempWidgetLayout) {
            setAddWidgetPopup(true);
        } else {
            setAddWidgetPopup(false);
        }
        return () => {
            setAddWidgetPopup(false);
        };
    }, [tempWidgetLayout]);

    return (
        <>
            <PageTitle
                className={cx(!preView && 'd-none', 'dashboard-edit-title')}
                customIcon={
                    <div className={'edit-icon-container'}>
                        <FiEdit color={'white'} />
                    </div>
                }
                heading={t('Dashboard;Preview')}
                enablePageTitleSubheading={true}
                subheading={t('Dashboard;It is a Dashboard Preview Page. Sample data is shown on this page.')}
                titleAction={
                    <Button className={'mr-2 btn-preview'} onClick={handlePreviewToggle}>
                        {t('Dashboard;End preview')}
                    </Button>
                }
            />

            <PageTitle
                className={cx(preView && 'd-none', 'dashboard-edit-title')}
                customIcon={
                    <div className={'edit-icon-container'}>
                        <FiEdit color={'white'} />
                    </div>
                }
                heading={t('Dashboard;Edit dashboard')}
                enablePageTitleSubheading={true}
                subheading={t('Dashboard;It is a Dashboard Edit Page. Sample data is shown on this page.')}
                titleAction={
                    <>
                        <Button className={'mr-2 btn-preview'} onClick={handlePreviewToggle}>
                            {t('Dashboard;Preview')}
                        </Button>
                        {/*<div className="pnt-border border-h border-bold mr-2" />*/}
                        <Button className={'mr-2 btn-edit'} onClick={() => toggleResetModal()}>
                            {t('Dashboard;Revert to the first')}
                        </Button>
                        <Button className={'mr-2 btn-edit'} onClick={() => toggleCancelModal()}>
                            {t('Dashboard;Cancel')}
                        </Button>
                        {/*<div className="pnt-border border-h border-bold mr-2" />*/}
                        <Button className={'mr-2 btn-edit'} onClick={addPanel}>
                            {t('Dashboard;Add panel')}
                        </Button>
                        <Button
                            className={'pnt-btn btn-icon btn-danger btn-long'}
                            onClick={e => {
                                toggleSaveModal();
                            }}
                        >
                            <span className="icon-save" />
                            {t('Dashboard;Save')}
                        </Button>
                    </>
                }
            />
            <DashboardFrame layouts={layouts} onDragStop={handleEditLayout} onResizeStop={handleEditLayout}>
                {Widgets}
            </DashboardFrame>
            <PalettePopup initOpen={addWidgetPopup} toggleModal={handleAddWidgetPopup} layout={tempWidgetLayout} />
            <ConfirmModal
                initModal={saveModal}
                toggleModal={toggleSaveModal}
                confirmText={t('Dashboard;Do you want to save the layout?')}
                okCallback={handleSave}
                callbackParam={editable}
            />
            <ConfirmModal
                initModal={cancelModal}
                toggleModal={toggleCancelModal}
                confirmText={t('Dashboard;Do you want to exit editing mode?')}
                okCallback={() => {
                    backToOrigin();
                    history.goBack();
                }}
            />
            <ConfirmModal
                initModal={resetModal}
                toggleModal={toggleResetModal}
                confirmText={t('Dashboard;Do you want to reset edits?')}
                okCallback={() => {
                    backToOrigin();
                }}
            />
        </>
    );
};

export default DashboardEdit;
