import React, { useEffect, useRef, useState } from 'react';
import Table from '../../../../Common/Table';
import useColumns from '../../../../../util/hooks/useColumns';
import * as column from '../../../util/grid/column';
import useAsync from '../../../../../util/hooks/useAsync';
import { createAssetInspectionApi, getAssetInspectionListApi } from '../../../../../api/asset';
import useTranslation from '../../../../../util/hooks/useTranslation';
import cx from 'classnames';
import moment from 'moment';
import { fetchUserInfo, getUserGroupInfoApi } from '../../../../../api/login';
import DatePicker from '../../../../Common/DatePicker';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import Button from '../../../../Common/Button';
import Label from '../../../../Common/Label';
import TextInput from '../../../../Common/Input/TextInput';
import Card from '../../../../Common/Card';

const InspectionLogTab = ({
    assetInfo,
    assetNum,
    handleOpenAddAssetInspectionPage,
    getNewAssetInfo,
    goToAddAssetInspectionPage,
}) => {
    const t = useTranslation('Inspection Tab');
    const [addAssetInspectionPage, setAddAssetInspectionPage] = useState(false);
    const [userGroupInfo, setUserGroupInfo] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [nextInspectionDate, setNextInspectionDate] = useState('');
    const [inspectionStatus, setInspectionStatus] = useState('');
    const [inspectionDesc, setInspectionDesc] = useState('');
    const [assetInspectionList, setAssetInspectionList] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });
    const firstRadioRef = useRef();
    const secondRadioRef = useRef();
    const thirdRadioRef = useRef();
    const fourthRadioRef = useRef();

    useEffect(() => {
        if (assetNum) {
            getAssetInspectionList({ opt: 'targetSearch', keyword: assetNum });
        }
        getUserGroupInfo();
        getUserInfo();
        return () => {
            setAddAssetInspectionPage(false);
        };
    }, []);

    useEffect(() => {
        if (assetInfo && assetInfo.properties && assetInfo.properties.inspect_interval) {
            setNextInspectionDate(moment().add(parseInt(assetInfo.properties.inspect_interval), 'days').unix());
        }
    }, [assetInfo]);

    useEffect(() => {
        goToAddAssetInspectionPage ? setAddAssetInspectionPage(true) : setAddAssetInspectionPage(false);
    }, [goToAddAssetInspectionPage]);

    const { promise: getAssetInspectionList, state: assetInspectionListInfo } = useAsync({
        promise: getAssetInspectionListApi,
        fixedParam: { pageSize: 10, opt: 'targetSearch', keyword: assetNum },
        keepState: true,
        resolve: res => {
            setAssetInspectionList(res);
        },
    });

    const { promise: getUserGroupInfo } = useAsync({
        promise: getUserGroupInfoApi,
        resolve: res => {
            if (res.rows) {
                setUserGroupInfo(res.rows[0]);
            }
        },
    });

    const { promise: getUserInfo } = useAsync({
        promise: fetchUserInfo,
        resolve: res => {
            setUserInfo(res);
        },
    });

    const { promise: createInspectionInfo } = useAsync({
        promise: createAssetInspectionApi,
        resolve: res => {
            toggleSuccessModal();
            setInspectionStatus('');
            //refreshUserInput();
        },
    });

    const columns = useColumns([
        column.inspectionDate(),
        column.inspectionStatus({
            Cell: ({ value }) => {
                return <div>{t(value)}</div>;
            },
        }),
        column.regUserName(),
        column.assetDescription(),
    ]);

    const handleAddAssetBtnClick = e => {
        setAddAssetInspectionPage(true);
    };

    const handleRadioBtnClick = selected => {
        setInspectionStatus(selected.target.value);
    };

    const handleDescChange = typed => {
        setInspectionDesc(typed.target.value);
    };

    const handleAddBtnClick = e => {
        if (inspectionStatus) {
            toggleCreateAssetInspectionModal();
        } else {
            toggleValidationModal();
        }
    };

    const refreshUserInput = e => {
        setNextInspectionDate('');
        setInspectionDesc('');
        firstRadioRef.current.checked = false;
        secondRadioRef.current.checked = false;
        thirdRadioRef.current.checked = false;
        fourthRadioRef.current.checked = false;
        handleOpenAddAssetInspectionPage(false);
    };

    const getInspectorInfo = e => {
        let inspectorInfo = '';
        if (userGroupInfo && userInfo) {
            inspectorInfo = `${userGroupInfo.groupName} ${t('department')} > ${userInfo.userName} [${userInfo.userID}]`;
        }
        return inspectorInfo;
    };

    const { toggleModal: toggleCreateAssetInspectionModal, Modal: AssetInspectionModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Confirm'),
        },
        confirmText: t('Do you want to create a new asset inspection?'),
        okCallback: createInspectionInfo,
        callbackParam: {
            description: inspectionDesc,
            inspectStatus: inspectionStatus,
            targetNum: assetNum,
            nextInspectDate: nextInspectionDate,
            regGroupNum: userGroupInfo && userGroupInfo.groupNum,
        },
    });

    const { toggleModal: toggleValidationModal, Modal: ValidationModal } = useConfirmModal({
        header: {
            title: t('Notification'),
        },
        confirmText: t('Inspection status must be selected'),
        removeCancel: true,
    });

    const { toggleModal: toggleSuccessModal, Modal: SuccessModal } = useConfirmModal({
        header: {
            title: t('Notification'),
        },
        confirmText: t('Asset inspection was created successfully'),
        removeCancel: true,
        okCallback: () => {
            setAddAssetInspectionPage(false);
            getAssetInspectionList();
            refreshUserInput();
            getNewAssetInfo();
        },
    });

    return (
        <div>
            <div className="d-flex flx-col" style={{ height: '700px' }}>
                <div className={cx(addAssetInspectionPage ? 'd-none' : 'd-block')}>
                    <div className="card-header mb-4 pl-0 pr-0">
                        <div
                            style={{ maxWidth: '98%', width: '98%' }}
                            className="card-header__title justify-content-end"
                        >
                            <Button className={'btn-brand'} onClick={handleAddAssetBtnClick}>
                                {t('Add asset inspection')}
                            </Button>
                        </div>
                    </div>
                    <div className="card-body tab-body" style={{ height: '600px' }}>
                        <Table
                            data={{ ...assetInspectionList, pageSize: 10 }}
                            columns={columns}
                            onPageChange={pageIndex => getAssetInspectionList({ page: pageIndex })}
                            loading={assetInspectionListInfo.isLoading}
                        />
                    </div>
                </div>
                <div className={cx(addAssetInspectionPage ? 'd-block' : 'd-none')}>
                    <div className="card-header pl-0 pr-0">
                        <div className="card-header__title">
                            <div className={'title-wrap'}>
                                <h3 className="title__main">{t('Register an asset inspection')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="card-body tab-body" style={{ height: '650px' }}>
                        <div className={'pnt-label-7 flx-col gap-3'}>
                            <Label
                                labelValueClassName={'label-dot'}
                                name={t('Scheduled Inspection Date')}
                                value={
                                    assetInfo && assetInfo.properties && assetInfo.properties.scheduled_inspection_date
                                        ? moment
                                              .unix(assetInfo.properties.scheduled_inspection_date)
                                              .format('YYYY-MM-DD')
                                        : t('None')
                                }
                            />
                            <Label
                                labelValueClassName={'label-dot'}
                                name={t('Last Inspection Date')}
                                value={
                                    assetInfo && assetInfo.properties && assetInfo.properties.last_inspect_date
                                        ? moment.unix(assetInfo.properties.last_inspect_date).format('YYYY-MM-DD')
                                        : t('None')
                                }
                            />
                            <Label
                                labelValueClassName={'label-dot'}
                                name={t('Inspection Date')}
                                value={moment().format('YYYY-MM-DD')}
                            />
                            <Label
                                labelValueClassName={'label-dot'}
                                name={t('Inspector Information')}
                                value={getInspectorInfo()}
                            />
                            <div className="pnt-label--group">
                                <div className="label-main label-dot color-brand">{t('Inspection Status')}</div>
                                <div className="label-options">
                                    <ul className="pnt-radio-short d-flex pl-0" style={{ marginBottom: '-5px' }}>
                                        <li className="mr-4">
                                            <input
                                                ref={firstRadioRef}
                                                type="radio"
                                                id="normal"
                                                value="normal"
                                                name="radio"
                                                onChange={handleRadioBtnClick}
                                            />
                                            <label style={{ cursor: 'auto' }}>{t('Normal')}</label>
                                        </li>
                                        <li className="mr-4">
                                            <input
                                                ref={secondRadioRef}
                                                type="radio"
                                                id="replaced"
                                                value="replaced"
                                                name="radio"
                                                onChange={handleRadioBtnClick}
                                            />
                                            <label style={{ cursor: 'auto' }}>{t('Replace')}</label>
                                        </li>
                                        <li className="mr-4">
                                            <input
                                                ref={thirdRadioRef}
                                                type="radio"
                                                id="repair"
                                                value="repair"
                                                name="radio"
                                                onChange={handleRadioBtnClick}
                                            />
                                            <label style={{ cursor: 'auto' }}>{t('Repair')}</label>
                                        </li>
                                        <li className="mr-4">
                                            <input
                                                ref={fourthRadioRef}
                                                type="radio"
                                                id="disposal"
                                                value="disposal"
                                                name="radio"
                                                onChange={handleRadioBtnClick}
                                            />
                                            <label style={{ cursor: 'auto' }}>{t('Disposal')}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="pnt-label--group">
                                <div className="label-main label-dot">{t('Inspection Description')}</div>
                                <div className="label-options">
                                    <TextInput
                                        type={'text'}
                                        inputGroupClassName={'w-100'}
                                        placeholder={t('Please enter description')}
                                        handleChange={handleDescChange}
                                        value={inspectionDesc}
                                        name={'desc'}
                                    />
                                </div>
                            </div>
                            <div className="pnt-label--group">
                                <div className="label-main label-dot">{t('Next Inspection Date')}</div>
                                <div className="label-options">
                                    <DatePicker
                                        minDate={new Date()}
                                        withoutTime={true}
                                        value={nextInspectionDate}
                                        handleChange={selected => setNextInspectionDate(selected)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={'d-flex justify-content-end w-100 mt-3'}>
                            <Button
                                className={'btn-gray mr-2'}
                                style={{ width: '100px' }}
                                onClick={() => {
                                    refreshUserInput();
                                    setAddAssetInspectionPage(false);
                                    setInspectionStatus('');
                                }}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button className={'btn-brand'} style={{ width: '100px' }} onClick={handleAddBtnClick}>
                                {t('Add')}
                            </Button>
                        </div>
                        <div className={'mt-4'} style={{ height: '200px' }}>
                            <Table
                                data={{ ...assetInspectionList, pageSize: 10 }}
                                columns={columns}
                                onPageChange={pageIndex => getAssetInspectionList({ page: pageIndex })}
                                loading={assetInspectionListInfo.isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <AssetInspectionModal />
            <ValidationModal />
            <SuccessModal />
        </div>
    );
};

export default InspectionLogTab;
