import React, { useEffect, useState } from 'react';
import { getOwnershipListApi } from '../../../../api/asset';
import useAsync from '../../../../util/hooks/useAsync';
import TreeSelect from '../../Components/Select/TreeSelectWithOption/index';
import { useTranslation } from 'react-i18next';

const OwnershipSearchableSelect = ({ selected, handleChange, className }) => {
    const { t } = useTranslation();

    const [ownershipList, setOwnershipList] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);

    useAsync({
        promise: getOwnershipListApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            setOwnershipList(
                res.rows.map(v => {
                    return { ...v, own_dep_id: v.groupId };
                }),
            );
        },
    });
    // selectedOption = groupNum;
    // selected = groupId;
    useEffect(() => {
        if (!selected) {
            setSelectedOption([]);
        }
    }, [selected]);

    const handleSelectedOptionChange = selected => {
        let groupNums = [];
        for (let i = 0; i < selected.length; i++) {
            let found = ownershipList.find(option => option.groupId === selected[i]);
            if (found) {
                groupNums.push(found.groupNum);
            }
        }
        handleChange(groupNums);
        setSelectedOption(selected);
    };

    return (
        <TreeSelect
            data={ownershipList}
            title={t('Filter;Ownership Organization')}
            valueKey={'groupId'}
            parentKey={'parentGroupId'}
            labelKey={'groupName'}
            selected={selectedOption}
            onChange={handleSelectedOptionChange}
            hideOption
        />
        // <SearchableSelect
        //     data={ownershipList}
        //     onChange={handleChange}
        //     title={'Ownership Organization'}
        //     selected={selected}
        //     labelKey={'groupName'}
        //     valueKey={valueKey ? valueKey : 'own_dep_id'}
        //     className={className ? className : 'mr-2 mb-2 mt-2'}
        //     changeable={changeable}
        //     hideOption={hideOption}
        // />
    );
};

export default OwnershipSearchableSelect;
