import React, { useEffect, useState } from 'react';
import { getOwnershipListApi } from '../../../../api/asset';
import useAsync from '../../../../util/hooks/useAsync';
import TreeSelect from '../../../Common/Select/TreeSelect';

const DepartmentSelect = ({ title, selectedOption, onChange }) => {
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);

    useAsync({
        promise: getOwnershipListApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        // deps: [logInUserDepartment],
        resolve: res => {
            // setOptions(res.rows.filter(department => department.groupId !== logInUserDepartment));
            setOptions(res.rows);
        },
    });

    //selectedOption = groupNum
    //selected = groupId
    useEffect(() => {
        if (!selectedOption.length) {
            setSelected([]);
        }
    }, [selectedOption]);

    const handleSelectedOptionChange = selected => {
        let groupNums = [];
        for (let i = 0; i < selected.length; i++) {
            let found = options.find(option => option.groupId === selected[i]);
            if (found) {
                groupNums.push(found.groupNum);
            }
        }
        onChange(groupNums);
        setSelected(selected);
    };

    return (
        <TreeSelect
            data={options}
            title={title}
            valueKey={'groupId'}
            parentKey={'parentGroupId'}
            labelKey={'groupName'}
            selected={selected}
            onChange={handleSelectedOptionChange}
        />
    );
};

export default DepartmentSelect;
