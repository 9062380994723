import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ReactSelect from '../../../../../../Common/Select';
import useTranslation from '../../../../../../../util/hooks/useTranslation';
import { AND, OR } from './triggerReducer';

const TriggerWrapper = styled.div`
    min-height: 35px;
    border-radius: 5px;
    width: 4rem;
    display: flex;
    align-items: center;
`;

export const TriggerLabel = ({ label }) => {
    return (
        <TriggerWrapper className={'filter-label bg-l-2 bg-secondary'}>
            <span style={{ minWidth: 0, width: '100%', textAlign: 'center', color: 'white' }} className="label-main">
                {label}
            </span>
        </TriggerWrapper>
    );
};

export const OperatorSelect = ({ value, disabled, onChange }) => {
    const t = useTranslation('Status');
    const operatorOptions = useMemo(() => {
        return [
            { value: AND, label: t('AND') },
            { value: OR, label: t('OR') },
        ];
    }, [t]);
    const [selectedOperator, setSelectedOperator] = useState(operatorOptions.find(v => v.value === value));
    useEffect(() => {
        setSelectedOperator(operatorOptions.find(v => v.value === value));
    }, [value, operatorOptions]);

    return (
        <ReactSelect
            name={'operator'}
            value={selectedOperator}
            options={operatorOptions}
            onChange={selected => {
                onChange(selected.value);
            }}
            disabled={disabled}
            customControlStyles={{ width: '100%' }}
            customMenuStyles={{ width: '100%' }}
        />
    );
};
