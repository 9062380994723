import React from 'react';
import { TextInputProps } from '../type';

export const TextInput = ({
    type = 'text',
    size,
    name,
    id,
    value,
    handleChange,
    style,
    disabled = false,
    placeholder,
    buttonIcon,
    className,
    buttonClassName,
    minlength,
    maxlength,
    required,
    buttonHandleClick,
    handleKeyUp,
    handleKeyDown,
    inputGroupClassName = '',
    errorMsg,
    autoFocus = false,
    readOnly,
}: TextInputProps): JSX.Element => {
    return (
        <div className={`pnt-input--group ${inputGroupClassName ? inputGroupClassName : ''}`}>
            <input
                type={type}
                size={size}
                name={name}
                id={id}
                value={value}
                onChange={handleChange}
                className={`pnt-input ${className} ${disabled ? 'non-active' : ''}`}
                placeholder={placeholder}
                disabled={inputGroupClassName.includes('form-disable') ? true : disabled}
                minLength={minlength}
                maxLength={maxlength}
                required={required}
                style={style}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                autoFocus={autoFocus}
                readOnly={readOnly}
            />
            {buttonIcon && (
                <button
                    className={`pnt-btn btn-icon-only ${buttonClassName}`}
                    tabIndex={-1}
                    onClick={buttonHandleClick}
                >
                    <span className={`icon-toggle-type ${buttonIcon}`} />
                </button>
            )}
            <p className="input-error-txt">{errorMsg}</p>
        </div>
    );
};

export default TextInput;
