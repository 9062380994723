import CryptoJS from 'crypto-js';
import moment from 'moment';
import envKeys from '../../environment';

const { userInfoKey, cryptoKey: key, cryptoIv: iv } = envKeys;

// 2시간으로 설정된 accss_token이 유효한지 확인
export const checkExpire = () => {
    let auth = false;
    if (localStorage.getItem(userInfoKey)) {
        const { oAuthInfo } = getLsUserInfo();
        // 유저 정보와 유저토큰의 기간 만료시간이 있을시에 실행
        // expire_at이 unix timestamp의 seconds로 저장 되어있기 때문에 1000을 곱하여 milliseconds로 전환
        if (oAuthInfo && oAuthInfo.expire_at) {
            const expire = Number(oAuthInfo.expire_at);
            if (expire && moment().isBefore(moment(expire * 1000))) {
                auth = true;
            }
        }
    }
    return auth;
};

// Refresh token은 14일이 default로 설정돼서 오는데 리프레쉬 토큰을 이용해서 유저가 새로우 토큰을 받기전에 리프레쉬 토큰 자체가 유효한지 확인
export const checkRefreshExpire = () => {
    let auth = false;
    const { oAuthInfo } = getLsUserInfo();
    if (oAuthInfo && oAuthInfo.refresh_expire_at) {
        const expire = Number(oAuthInfo.expire_at);
        if (expire && moment().isBefore(moment(expire * 1000))) {
            auth = true;
        }
    }

    return auth;
};

// LocalStorage내의 유저 인포 불러오기
export const getLsUserInfo = () => {
    const userInfo = localStorage.getItem(userInfoKey);
    return userInfo ? decodeInfo(userInfo) : {};
};
// export const getLsUserInfo = () => {
//     const userInfo = JSON.parse(localStorage.getItem(userInfoKey));
//     return userInfo || {};
// };

// 유저 인포 LocalStorage 내에 저장하기
export const setLsUserInfo = info => {
    localStorage.setItem(userInfoKey, encodeInfo(info));
};

export const checkResponseErr = ({ data }) => {
    return !!(data === null || data === undefined || data.result === 'error' || data.errorCode);
};

// LocalStorage내의 유저가 설정해둔 대쉬보드 홈 불러오기
export const getHome = () => {
    const { userInfo } = getLsUserInfo();
    return userInfo && userInfo.home ? userInfo.home : null;
};

// LocalStorage내의 유저가 속한 회사 정보 불러오기
export const getUuid = () => {
    const { userInfo } = getLsUserInfo();
    // return userInfo && userInfo.uuid ? userInfo.uuid : null;
    if (userInfo && userInfo.companyInfo) {
        return userInfo.companyInfo.uuid;
    }
    return null;
};

export const idxGenerator = function* () {
    let i = 0;
    while (true) {
        yield ++i;
    }
};

export const isSelectableFloor = (floor, floorList) => {
    return floor.imgURL && !floorList.find(compFloor => compFloor.upperFloorId === floor.floorId);
};

export const isleafFloor = (floor, floorList) => {
    return floor.imgURL && !floorList.find(compFloor => compFloor.upperFloorId === floor.floorId);
};

// API가 없는 상황에 직접 데이터를 만들어서 하드코딩으로 만들게 된다면 연동할때 처리해야할 상황들이 너무 많아진다
// 그런상황일때 사용 가능하다
export const generateFakeData = data => () => {
    return new Promise(function (resolve, reject) {
        resolve({ data });
    });
};

// 암호화
export const encodeInfo = info => {
    const cryptoKey = CryptoJS.enc.Utf8.parse(key);
    const ivUtf = CryptoJS.enc.Utf8.parse(iv);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(info), cryptoKey, {
        iv: ivUtf,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return encrypted;
};

// 복호화
// @param encodedInfo 암호화된 정보
// @param toString string타입으로 리턴 여부
export const decodeInfo = (encodedInfo, toString) => {
    const cryptoKey = CryptoJS.enc.Utf8.parse(key);
    const ciphertext = CryptoJS.enc.Base64.parse(encodedInfo);
    const ivUtf = CryptoJS.enc.Utf8.parse(iv);

    const decrypt = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, cryptoKey, {
        iv: ivUtf,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    let decInfo = {};
    try {
        const decInfo = decrypt.toString(CryptoJS.enc.Utf8);
        if (toString || !decInfo) {
            return decInfo;
        }
        return JSON.parse(decInfo);
    } catch (error) {
        console.error(error);
    }
    return decInfo;
};

const dateFormat = {
    START_YEAR: 'YYYY-MM-DD HH:mm:ss',
    START_YEAR_2: 'YYYY-MM-DD h:mm:ss a',
    START_MONTH: 'MMMM Do, h:mm:ss a',
    START_MONTH_2: 'MM-DD HH:mm:ss',
    MD: 'MMMM Do',
    ONLY_TIME: 'h:mm:ss a',
};

export const dateToFormat = (date, format = 'START_YEAR') => {
    return moment(date).format(dateFormat[format] || format);
};

export const defaultActionCreator = (type, param) => ({ type, payload: param });

export const secToHms = (sec, returnString = false, t, keepOriginal = false) => {
    const tempMin = sec / 60;

    let h = Math.floor(tempMin / 60);
    if (h !== 0 && !h) {
        h = null;
    }
    let m = Math.floor(tempMin % 60);
    if (m !== 0 && !m) {
        m = null;
    }
    let tempStr = sec ? `${h}h ${m}m ${sec % 60}s` : '-';

    if (t && sec) {
        tempStr = `${h}${t('h')} ${m}${t('m')} ${sec % 60}${t('s')}`;
    }

    if (!keepOriginal) {
        return returnString
            ? tempStr
            : {
                  h: h,
                  m: m,
                  s: sec % 60,
              };
    } else {
        return returnString && sec ? tempStr : sec;
    }
};

// 초를 hh:mm:ss 형태로 표시
export const secDisplayFormat = sec => {
    if (sec) {
        let timeString = sec.replace(/[hm]/gi, ':').replace(/[s\s+]/gi, '');

        const timeStringArr = timeString.split(':').map(time => {
            return time >= 10 ? time : '0' + time;
        });

        return timeStringArr.join(':');
    } else if (sec === 0) {
        return '00:00:00';
    } else {
        return '-';
    }
};
// 카테고리 속성 => 리스트 표시여부 'Y'

export const returnCategoryProperty = (assetCategoryProps, categoryProps) => {
    let list = [];
    if (assetCategoryProps) {
        assetCategoryProps.map(assetProp => {
            categoryProps.map(categoryProp => {
                if (assetProp.id === categoryProp.propertyId) {
                    let value;
                    if (assetProp.id === 'last_inspect_date' || assetProp.id === 'scheduled_inspection_date') {
                        if (assetProp.value) {
                            value = moment.unix(assetProp.value).format('YYYY-MM-DD');
                        }
                    } else {
                        value = assetProp.value;
                    }
                    list.push({
                        name: categoryProp.displayName,
                        value: value,
                        id: assetProp.id,
                    });
                }
            });
        });
    }
    return list.length > 0 ? list : [];
};

export const trim = str => {
    let tempStr = str || '';
    return tempStr.trim();
};
