import React, { useState } from 'react';
import useTranslation from '../../../util/hooks/useTranslation';
import Button from '../../Common/Button';
import TextInput from '../../Common/Input/TextInput';
import useConfirmModal from '../../Common/ConfirmModal/useConfirmModal';
import {
    SelectGroup,
    SearchWrap,
    InputGroup,
    FilterList,
    InputWrap,
} from '../../Common/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../Common/FilterSearchGroup';
import FilterGroup from '../../Common/FilterSearchGroup/Components/FilterGroup';
import SearchGroup from '../../Common/FilterSearchGroup/Components/SearchGroup';
import FilterButtonArea from '../../Common/FilterSearchGroup/Components/FilterButtonArea';

const Search = ({ searchInputPlaceholder, handleSearchClick, handleRefreshClick, children }) => {
    const t = useTranslation('Search');

    const [searchInput, setSearchInput] = useState('');

    const handleSearchInputChange = e => {
        setSearchInput(e.target.value);
    };

    const handleRefreshBtnClick = () => {
        setSearchInput('');
        handleRefreshClick();
    };

    const { toggleModal: toggleRefreshModal, Modal: RefreshModal } = useConfirmModal({
        confirmText: t('Do you want to refresh?'),
        okCallback: () => {
            handleRefreshBtnClick();
        },
    });

    return (
        <>
            <FilterSearchGroup>
                <FilterGroup className={'bg-brand'} label={t('Search', 'Search')}>
                    <FilterList>
                        {children.map((child, idx) => {
                            if (idx === 2) {
                                return (
                                    <>
                                        <SelectGroup key={child.$$typeof.toString() + idx}>{child}</SelectGroup>
                                        <div className={'text-center'}> ~ </div>
                                    </>
                                );
                            }
                            return <SelectGroup key={child.$$typeof.toString() + idx}>{child}</SelectGroup>;
                        })}
                    </FilterList>
                    <FilterButtonArea>
                        <Button
                            className="btn-danger btn-icon-only"
                            iconClassName={'icon-refresh'}
                            onClick={toggleRefreshModal}
                        />
                    </FilterButtonArea>
                </FilterGroup>
                <div className={'filter__options'}>
                    <SearchGroup>
                        <SearchWrap>
                            <InputWrap>
                                <InputGroup>
                                    <TextInput
                                        type={'text'}
                                        placeholder={searchInputPlaceholder}
                                        name={'name'}
                                        value={searchInput}
                                        handleChange={handleSearchInputChange}
                                    />
                                </InputGroup>
                                <Button
                                    className={'btn-brand btn-icon'}
                                    iconClassName={'icon-search'}
                                    onClick={() => handleSearchClick(searchInput)}
                                >
                                    {t('Search', 'Search')}
                                </Button>
                            </InputWrap>
                        </SearchWrap>
                    </SearchGroup>
                </div>
            </FilterSearchGroup>
            <RefreshModal />
        </>
    );
};

export default Search;
