import React, { useContext, useState } from 'react';
import TextInput from '../../../../../../Common/Input/TextInput';
import cx from 'classnames';
import { OperatorSelect, TriggerLabel } from './Parts';
import useTranslation from '../../../../../../../util/hooks/useTranslation';
import { TriggerDispatchContext, TriggerStateContext } from './index';
import { checkRestful, RESTFUL_GROUP_KEY } from './triggerReducer';

const RestfulApiInputGroup = ({ apiURL }) => {
    const t = useTranslation('Status');
    const dispatch = useContext(TriggerDispatchContext);
    const { restFulOn, triggerRelationJson } = useContext(TriggerStateContext);
    const { triggers } = triggerRelationJson.data[RESTFUL_GROUP_KEY]
        ? triggerRelationJson.data[RESTFUL_GROUP_KEY]
        : { triggers: [] };

    const handleInputChange = e => {
        dispatch(checkRestful({ restFulOn: !restFulOn, apiURL }));
    };

    return (
        <div className={'input-container-group'}>
            <div className={'input-container'}>
                <OperatorSelect value={'||'} disabled />
            </div>
            <div className={cx('input-container', 'api-input')}>
                <div className="d-flex">
                    <input
                        id={'restFul'}
                        checked={restFulOn}
                        type="checkbox"
                        name={'restFul'}
                        onChange={handleInputChange}
                    />
                    <label htmlFor={'restFul'} className={'input-label'}>
                        <TriggerLabel label={t('Restful API')} />
                    </label>
                </div>
                <TextInput
                    inputGroupClassName="w-100"
                    name={'restfulApiInput'}
                    value={apiURL}
                    readOnly
                    disabled={!restFulOn}
                />
            </div>
        </div>
    );
};

export default RestfulApiInputGroup;
