import FloorSelect from './FloorSelect';
import AssetStatusSelect from './AssetStatusSelect';
import CategorySelect from './CategorySelect';
import OwnershipSelect from './OwnershipSelect';

export const staticFilterInfo = [
    {
        name: 'Floor',
        conditionId: 'floorIds',
        conditionType: 'static',
        conditionValues: [],
        isEditable: 'Y',
        FilterComponent: FloorSelect,
    },
    // {
    //     name: 'Asset Status',
    //     conditionId: 'statusCategoryNums',
    //     conditionType: 'static',
    //     conditionValues: [],
    //     isEditable: 'Y',
    //     FilterComponent: AssetStatusSelect,
    // },
    {
        name: 'Asset Categorization',
        conditionId: 'categoryCodes',
        conditionType: 'static',
        conditionValues: [],
        isEditable: 'Y',
        FilterComponent: CategorySelect,
    },
];

// 22.06.23 사용하지 않음. 카테고리 속성 정보를 통해서 dynamic 필터 컴포넌트 생성
export const dynamicFilterInfo = [
    {
        name: 'Ownership Organization',
        conditionId: 'own_dep_id',
        conditionType: 'dynamic',
        conditionValues: [],
        isEditable: 'Y',
        FilterComponent: OwnershipSelect,
    },
];

export const filterInfo = [...staticFilterInfo, ...dynamicFilterInfo];

export const defaultFilterInfo = filterInfo.map(({ name, FilterComponent, ...rest }) => rest);
export const defaultStaticFilterInfo = staticFilterInfo.map(({ name, FilterComponent, ...rest }) => rest);

export const defaultDynamicSelectFilterInfo = {
    conditionType: 'dynamic',
    conditionValues: [],
    isEditable: 'Y',
};
