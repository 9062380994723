import React, { useState, useMemo, createContext, useReducer, useContext, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import { defaultStyle, groupTitleStyle, allItemStyle } from '../../../../Common/Select/selectStyle';
import {
    ValueContainer,
    DropdownIndicator,
    Control,
    DropdownOnLeft,
    StyledOption,
    StyledOptionLabel,
} from '../../../../Common/Select/Components/Parts';
import { useTranslation } from 'react-i18next';
import { changeOption, selectReducer } from '../selectReducer';

const selectStyles = {
    ...defaultStyle,
    container: (provided, state) => ({
        ...provided,
        minWidth: '11.5rem',
        maxWidth: '14rem',
    }),
    control: provided => ({
        ...provided,
        minWidth: '11.5rem',
        maxWidth: '14rem',
        margin: 8,
    }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
    menuList: provided => ({
        ...provided,
        maxWidth: '17rem',
    }),
};

export const SearchableSelectContext = createContext();
export const SearchableSelectDispatchContext = createContext();

export const MenuList = ({ children, getValue, setValue, options, ...restProps }) => {
    const selected = getValue();
    const { t } = useTranslation();
    const dispatch = useContext(SearchableSelectDispatchContext);
    const { valueKey, labelKey, changeable, hideOption } = useContext(SearchableSelectContext);
    const handleChange = e => {
        const { checked } = e.currentTarget;
        setValue(checked ? options : []);
    };

    return (
        <components.MenuList {...restProps}>
            <div className={'mb-2'}>
                <div style={groupTitleStyle}>{t('Select;Selected')}</div>
                {selected.length ? (
                    selected.map(v => (
                        <div key={v[valueKey]} className={changeable === 'Y' ? '' : 'disabled-select-option'}>
                            <StyledOption
                                onClick={e => {
                                    setValue(
                                        selected.filter(selectedOption => selectedOption[valueKey] !== v[valueKey]),
                                    );
                                }}
                            >
                                <label className={'text-ellipsis'} style={{ width: '100%' }} title={v[valueKey]}>
                                    <input type="checkbox" value={v[valueKey]} checked onChange={e => null} />
                                    {v[labelKey]}
                                </label>
                            </StyledOption>
                        </div>
                    ))
                ) : (
                    <StyledOptionLabel>{t('Select;Not Selected')}</StyledOptionLabel>
                )}
            </div>
            <div>
                <div style={allItemStyle}>
                    <label className={'text-ellipsis mb-0'} title="All Items">
                        <input type="checkbox" onChange={handleChange} checked={selected.length === options.length} />
                        {t('Select;All Items')}
                    </label>
                </div>
                {children}
            </div>
            {!hideOption && (
                <div>
                    <div style={groupTitleStyle}>{t('Select;Option')}</div>
                    <StyledOption>
                        <label className={'text-ellipsis'} title={'선택 항목 변경가능'}>
                            <input
                                type="checkbox"
                                checked={changeable === 'Y'}
                                onChange={e => {
                                    dispatch(changeOption(e.target.checked ? 'Y' : 'N'));
                                }}
                            />
                            {t('Filter;Permission to change')}
                        </label>
                    </StyledOption>
                </div>
            )}
        </components.MenuList>
    );
};

export const Option = function ({ label, isSelected, ...restProps }) {
    const { changeable } = useContext(SearchableSelectContext);
    return (
        <div className={changeable === 'Y' ? restProps.className : `${restProps.className} disabled-select-option`}>
            <components.Option {...restProps}>
                <label className={'text-ellipsis mb-0'} style={{ width: '100%' }} title={label}>
                    <input type="checkbox" checked={isSelected} onChange={e => null} />
                    {label}
                </label>
                {/*<label style={{ marginBottom: '4px' }}>{label}</label>*/}
            </components.Option>
        </div>
    );
};

const SearchableSelectWithOption = ({
    className,
    data = [],
    selected = [],
    title,
    labelKey = 'label',
    valueKey = 'value',
    onChange,
    changeable = 'Y',
    hideOption,
}) => {
    const isMounted = useRef(false);
    const [state, dispatch] = useReducer(selectReducer, { title, labelKey, valueKey, changeable, hideOption });
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const getOptionValue = option => option[valueKey];
    const getOptionLabel = option => option[labelKey];
    //const filterOption = ({ label }, string) => ~label.toLowerCase().indexOf(string.toLowerCase());
    const selectedOptions = useMemo(() => {
        return data.reduce((acc, curr) => {
            if ((selected || []).includes(curr[valueKey])) {
                acc.push(curr);
            }
            return acc;
        }, []);
    }, [data, selected, valueKey]);

    useEffect(() => {
        if (isMounted.current) {
            if (typeof onChange === 'function') {
                onChange(
                    data.filter(v => selected.includes(v[valueKey])),
                    state.changeable,
                );
            }
        } else {
            isMounted.current = true;
        }
    }, [state.changeable]);

    return (
        <SearchableSelectDispatchContext.Provider value={dispatch}>
            <SearchableSelectContext.Provider value={state}>
                <DropdownOnLeft
                    className={className}
                    menuClassName={'mr-5'}
                    isOpen={isOpen}
                    onClose={toggleOpen}
                    target={
                        <ValueContainer
                            title={title}
                            labelKey={labelKey}
                            isOpen={isOpen}
                            selected={selectedOptions}
                            onClick={toggleOpen}
                        />
                    }
                >
                    <Select
                        isMulti
                        // autoFocus
                        backspaceRemovesValue={false}
                        components={{
                            DropdownIndicator,
                            IndicatorSeparator: null,
                            MenuList,
                            Option,
                            Control,
                        }}
                        controlShouldRenderValue={false}
                        // hideSelectedOptions={false}
                        isClearable={false}
                        menuIsOpen
                        onChange={selected => {
                            // setSelectedItem(selected);
                            onChange(selected, state.changeable);
                        }}
                        options={data}
                        placeholder={t('Select;Search')}
                        noOptionsMessage={() => t('Select;No options')}
                        styles={selectStyles}
                        tabSelectsValue={false}
                        getOptionValue={getOptionValue}
                        getOptionLabel={getOptionLabel}
                        value={selectedOptions}
                        //filterOption={filterOption}
                    />
                </DropdownOnLeft>
            </SearchableSelectContext.Provider>
        </SearchableSelectDispatchContext.Provider>
    );
};

export default SearchableSelectWithOption;
