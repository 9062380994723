import React from 'react';
import produce from 'immer';

const defaultActionCreator = (type, param) => ({ type, payload: param });
const CHANGE_OPTION = 'CHANGE_OPTION';

export const changeOption = param => defaultActionCreator(CHANGE_OPTION, param);


export const selectReducer = (state, action) => {
    switch (action.type) {
        case CHANGE_OPTION:
            return produce(state, draft => {
                 draft.changeable=action.payload;
            });
    }
};