import { reqDelete, reqGet, reqPatch, reqPost, reqPut } from './index';

// 카테고리별 가능한 액션 조회
export const getTransitionInfoByCategoryApi = param =>
    reqGet(`/v3/api/common/target/status/transition/info/action`, param);

// 자산 상태 카테고리 정보
export const getStatusCategoryInfoApi = param => reqGet('/v3/api/common/target/status/category/info', param);
export const createStatusCategoryInfoApi = param => reqPost('/v3/api/common/target/status/category/info', param);
export const updateStatusCategoryInfoApi = ({ statusCategoryNum, ...param }) =>
    reqPut(`/v3/api/common/target/status/category/info/${statusCategoryNum}`, param);

// 자산 상태 정보
export const getStatusListInfoApi = param => reqGet('/v3/api/common/target/status/info', param);
export const createStatusInfoApi = param => reqPost('/v3/api/common/target/status/info', param);
export const updateStatusInfoApi = ({ statusId, ...param }) =>
    reqPut(`/v3/api/common/target/status/info/${statusId}`, param);

// 상태 스킴 정보
export const getStatusSchemeListInfoApi = param => reqGet('/v3/api/common/target/status/scheme/info', param);
export const createStatusSchemeInfoApi = param => reqPost('/v3/api/common/target/status/scheme/info', param);
export const updateStatusSchemeInfoApi = ({ schemeNum, ...param }) =>
    reqPut(`/v3/api/common/target/status/scheme/info/${schemeNum}`, param);
export const getStatusSchemeInfoApi = param => reqGet(`/v3/api/common/target/status/scheme/info/${param.schemeNum}`);

// 상태 스킴에 매핑된 상태 정보
export const getMappedStatusListInfoApi = param => reqGet('/v3/api/common/target/status/scheme/map/info', param);
export const createMappedStatusListInfoApi = param => reqPost('/v3/api/common/target/status/scheme/map/info', param);
export const deleteMappedStatusListInfoApi = ({ schemeNum, ...param }) =>
    reqDelete(`/v3/api/common/target/status/scheme/map/info/${schemeNum}`, param);

// 상태 흐름 스킴 정보
export const getFlowSchemeListInfoApi = param => reqGet('/v3/api/common/target/status/flow/info', param);
export const getFlowSchemeInfoApi = param => reqGet(`/v3/api/common/target/status/flow/info/${param.flowNum}`, param);
export const createFlowSchemeInfoApi = param => reqPost('/v3/api/common/target/status/flow/info', param);
export const deleteFlowSchemeInfosApi = param => reqDelete('/v3/api/common/target/status/flow/info', param);
export const updateFlowSchemeInfosApi = param =>
    reqPut(`/v3/api/common/target/status/flow/info/${param.flowNum}`, param);

// 대상 상태 흐름 카테고리 매핑 정보 API
export const getStatusFlowCategoryMapInfoApi = param =>
    reqGet('/v3/api/common/target/status/flow/category/map/info', param);
export const createStatusFlowCategoryMapInfoApi = param =>
    reqPost('/v3/api/common/target/status/flow/category/map/info', param);
export const deleteStatusFlowCategoryMapInfoApi = param =>
    reqDelete('/v3/api/common/target/status/flow/category/map/info', param);

// 대상 상태 일괄 변경
export const updateStatusBatchApi = param => reqPatch('/v3/api/common/target/status/transition/info/batch', param);

// 트랜지션 정보
export const getTransitionListInfoApi = param => reqGet('/v3/api/common/target/status/transition/info', param);
export const createTransitionInfoApi = param => reqPost('/v3/api/common/target/status/transition/info', param);
export const updateTransitionInfoApi = ({ actionNum, ...param }) =>
    reqPut(`/v3/api/common/target/status/transition/info/${actionNum}`, param);

// 트리거 정보
export const createTriggerInfoApi = ({ actionNum, ...param }) =>
    reqPost(`/v3/api/common/target/status/trigger/info/${actionNum}`, param);
export const updateTriggerInfoApi = ({ actionNum, ...param }) =>
    reqPut(`/v3/api/common/target/status/trigger/info/${actionNum}`, param);
