import React, { useMemo } from 'react';
import { CartesianGrid, Legend, LineChart, XAxis, YAxis, Line, Text, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';

const CustomizedYaxis = ({ viewBox }) => {
    const { t } = useTranslation();
    return (
        <Text x={30} y={viewBox.height / 2 + 45} angle={-89} fill={'#ababab'} style={{ fontSize: '0.8rem' }}>
            {t('Overview;count of assets')}
        </Text>
    );
};
const CustomizedXaxis = () => {
    const { t } = useTranslation();
    return (
        <Text x={230} y={248} style={{ fontSize: '0.8rem' }}>
            {t('Overview;count of assets')}
        </Text>
    );
};

const CustomLineChart = ({ data, statusInfo }) => {
    const statusInfoArr = useMemo(() => {
        let arr = [];
        for (let statusNum in statusInfo) {
            if (statusInfo.hasOwnProperty(statusNum)) {
                arr.push({ statusNum, ...statusInfo[statusNum] });
            }
        }
        return arr;
    }, [statusInfo]);

    const renderLegend = (value, entry) => {
        const { color } = entry;
        return (
            <span style={{ color: color, fontSize: '0.7rem' }}>
                {' '}
                {statusInfo && statusInfo[value] ? statusInfo[value].statusName : ''}
            </span>
        );
    };

    return (
        <ResponsiveContainer width={'100%'}>
            <LineChart data={data} width={570} height={250}>
                <Legend
                    wrapperStyle={{
                        paddingLeft: '20px',
                        height: '100%',
                        overflow: 'auto',
                    }}
                    layout="vertical"
                    iconType="square"
                    align="right"
                    verticalAlign="top"
                    formatter={renderLegend}
                />
                <CartesianGrid strokeDasharray="2" />
                <XAxis
                    dataKey="time"
                    // label={<CustomizedXaxis />}
                    tickLine={false}
                    style={{ fontSize: '0.7rem', fill: '#ababab' }}
                    stroke="#b5b5b5"
                />
                <YAxis
                    stroke="#b5b5b5"
                    label={<CustomizedYaxis />}
                    tickLine={false}
                    style={{ fontSize: '0.7rem', fill: '#ababab' }}
                />
                {statusInfoArr.map(({ statusNum, displayColor }) => {
                    return <Line dataKey={statusNum} stroke={displayColor} dot={false} />;
                })}
                {/*<Line dataKey="inUse" stroke="#3E66FB" dot={false} />*/}
                {/*<Line dataKey="available" stroke="#3acc5c" dot={false} />*/}
                {/*<Line dataKey="par" stroke="#ff3333" dot={false} />*/}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default CustomLineChart;
