import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import CustomPieChart from '../../Components/Chart/CustomePieChart';
import AvgSection from '../../Components/Chart/AvgSection';

const UtilizationBigChartVer = (
    { totalNum, usedNum, mainColor, avgCount, avgTime, avgLeadTime, ...restProps },
    ref,
) => {
    const UsedNumSpan = styled.span`
        color: ${props => props.color};
        font-size: 2rem;
        font-weight: bold;
    `;
    return (
        <div className={'w-100'}>
            <Row>
                <Col xl={'4'}>
                    <CustomPieChart
                        fontColor={mainColor}
                        graphColor={mainColor}
                        totalNum={totalNum}
                        usedNum={usedNum}
                        fontSize={'1.5rem'}
                        innerRadius={38}
                        outerRadius={46}
                    />
                </Col>
                <Col xl={'8'}>
                    <div className="ml-3">
                        <div className={'borderBottomInUtilization'}>
                            <UsedNumSpan color={mainColor} className={'exclude-font-theme'}>
                                {usedNum}
                            </UsedNumSpan>
                            <span className={'totalNum exclude-font-theme'}>/{totalNum}</span>
                        </div>
                        <AvgSection avgCount={avgCount} avgTime={avgTime} avgLeadTime={avgLeadTime} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default forwardRef(UtilizationBigChartVer);
