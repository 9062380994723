import React from 'react';
import { Modal as RSModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import cx from 'classnames';
import Button from '../Button';
import useTranslation from '../../../util/hooks/useTranslation';

const Modal = ({
    className,
    headerClassName,
    bodyClassName,
    footerClassName,
    headerTitle,
    bodyText,
    modalHeader,
    modalBody,
    modalFooter,
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    okCallback,
    callbackParam,
    cancelCallback,
    removeCancel,
    children,
    ...restProps
}) => {
    const t = useTranslation('ConfirmModal');
    return (
        <RSModal
            container={document.getElementsByClassName('app-container')[0]}
            className={cx('pnt-modal', className)}
            isOpen={initModal}
            toggle={toggleModal}
            okCallback
            callbackParam
            cancelCallback
            {...restProps}
        >
            {modalHeader ? (
                modalHeader
            ) : (
                <ModalHeader
                    className={headerClassName}
                    toggle={() => {
                        if (typeof cancelCallback === 'function') {
                            cancelCallback();
                        }
                        toggleModal();
                    }}
                >
                    {headerTitle}
                </ModalHeader>
            )}
            {children ? children : <ModalBody className={bodyClassName}>{bodyText}</ModalBody>}
            {modalFooter ? (
                modalFooter
            ) : (
                <ModalFooter className={footerClassName}>
                    {!removeCancel && (
                        <Button
                            className={'color-secondary'}
                            onClick={e => {
                                if (typeof cancelCallback === 'function') {
                                    cancelCallback(callbackParam);
                                }
                                toggleModal();
                            }}
                        >
                            {t('Cancel')}
                        </Button>
                    )}

                    <Button
                        className={'btn-secondary'}
                        onClick={e => {
                            if (typeof okCallback === 'function') {
                                okCallback(callbackParam);
                            }
                            toggleModal();
                        }}
                    >
                        {t('OK')}
                    </Button>
                </ModalFooter>
            )}
        </RSModal>
    );
};

export default Modal;
