import React, { useEffect, useState } from 'react';
import { fetchCategory } from '../../../../api/asset';
import useAsync from '../../../../util/hooks/useAsync';
import SearchableSelect from '../../../Common/Select/SearchableSelect';
import { useSelector } from 'react-redux';
import useTranslation from '../../../../util/hooks/useTranslation';

const InspectionCategorySearchableSelect = ({ selected, changeable, handleChange, hideOption, className }) => {
    const t = useTranslation('AssetInspectionList');
    const [categoryList, setCategoryList] = useState([]);
    const { serviceCategory } = useSelector(state => state.CategoryInfo);
    const { promise: getCategoryList } = useAsync({
        promise: fetchCategory,
        fixedParam: { isAll: 'Y', parentCode: serviceCategory },
        resolve: res => {
            setCategoryList(
                res.rows.map(v => {
                    return { ...v, categoryCodes: v.categoryCode };
                }),
            );
        },
    });
    useEffect(() => {
        getCategoryList();
    }, []);
    return (
        <SearchableSelect
            data={categoryList}
            onChange={handleChange}
            title={t('Asset Categorization')}
            selected={selected}
            labelKey={'categoryName'}
            valueKey={'categoryCodes'}
            changeable={changeable}
            hideOption={hideOption}
        />
    );
};

export default InspectionCategorySearchableSelect;
