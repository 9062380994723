import React, { useEffect, useState } from 'react';
import Card from '../Components/WidgetCard';
import useMonitor from '../util/useMonitor';
import { getStatisticsLogApi } from '../../../api/dashboard';
import useAsync from '../../../util/hooks/useAsync';
import { getStatusInfoApi } from '../../../api/asset';
import { useSelector } from 'react-redux';
import CustomTimeLine from './Components/CustomTimeLine';

const defaultData = {
    code: 0,
    count: 0,
    result: 'success',
    rows: [
        {
            categoryCode: 'ASSET',
            comNum: 100003,
            floorName: '1F',
            floorNamePath: '1F > 2F',
            lastDate: 1600000000,
            leadTime: 1600000000,
            logNum: 100003,
            previousStatusName: 'Repair',
            previousStatusNum: 1,
            properties: {},
            regDate: 1600000000,
            statusCount: 1004,
            statusName: 'In use',
            statusNum: 2,
            targetName: 'John',
            targetNum: 14,
        },
        {
            categoryCode: 'ASSET',
            comNum: 100003,
            floorName: '1F',
            floorNamePath: '1F > 2F',
            lastDate: 1600000000,
            leadTime: 1600000000,
            logNum: 100003,
            previousStatusName: 'Repair',
            previousStatusNum: 1,
            properties: {},
            regDate: 1600000000,
            statusCount: 1004,
            statusName: 'In use',
            statusNum: 2,
            targetName: 'John',
            targetNum: 14,
        },
        {
            categoryCode: 'ASSET',
            comNum: 100003,
            floorName: '1F',
            floorNamePath: '1F > 2F',
            lastDate: 1600000000,
            leadTime: 1600000000,
            logNum: 100003,
            previousStatusName: 'Repair',
            previousStatusNum: 1,
            properties: {},
            regDate: 1600000000,
            statusCount: 1004,
            statusName: 'In use',
            statusNum: 2,
            targetName: 'John',
            targetNum: 14,
        },
    ],
};

const StatusTimeLine = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const [statusColorInfo, setStatusColorInfo] = useState();
    const { filterParam } = useSelector(state => state.FilterInfo);

    const { promise: getStatusInfo } = useAsync({
        promise: getStatusInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const statusToColor = {};
            if (res.rows) {
                res.rows.forEach(v => {
                    statusToColor[v.statusNum] = v.displayColor;
                });
                setStatusColorInfo(statusToColor);
            }
        },
    });

    const { data } = useMonitor({
        config,
        defaultData: defaultData,
        dynamicParam: filterParam,
        fetchData: getStatisticsLogApi,
        makeData: ({ newData }) => {
            if (newData.rows) {
                return newData.rows.length > 0 ? newData.rows : [];
            }
        },
    });

    useEffect(() => {
        getStatusInfo();
    }, []);

    return (
        <Card bodyClassName={'custom-card-layout'} widgetInfo={widgetInfo} {...restProps}>
            <CustomTimeLine events={data} statusColorInfo={statusColorInfo} />
            {children}
        </Card>
    );
};

export default StatusTimeLine;
