import React, { useState, useMemo, createContext, useReducer, useContext } from 'react';
import Select, { components } from 'react-select';
import { defaultStyle, groupTitleStyle } from '../../../../Common/Select/selectStyle';
import {
    Dropdown,
    DropdownOnLeft,
    ValueContainer,
    DropdownIndicator,
    Control,
    Option,
    StyledOption,
    StyledOptionLabel,
} from '../../../../Common/Select/Components/Parts';
import { useTranslation } from 'react-i18next';
import Button from '../../../../Common/Button';
import cx from 'classnames';

const selectStyles = {
    ...defaultStyle,
    container: (provided, state) => ({
        ...provided,
        minWidth: '11.5rem',
        maxWidth: '14rem',
    }),
    control: provided => ({
        ...provided,
        minWidth: '11.5rem',
        maxWidth: '14rem',
        margin: 8,
    }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
    menuList: provided => ({
        ...provided,
        maxWidth: '17rem',
    }),
};

export const SearchableSelectContext = createContext();

const MenuList = ({ children, getValue, setValue, ...restProps }) => {
    const selected = getValue();
    const { t } = useTranslation();
    const { valueKey, labelKey } = useContext(SearchableSelectContext);
    return (
        <components.MenuList {...restProps}>
            <div className={'mb-2'}>
                <div style={groupTitleStyle}>{t('Select;Selected')}</div>
                {selected.length ? (
                    selected.map(v => (
                        <StyledOption
                            key={v[valueKey]}
                            onClick={e => {
                                setValue(selected.filter(selectedOption => selectedOption[valueKey] !== v[valueKey]));
                            }}
                        >
                            <label className={'text-ellipsis'} title={v[valueKey]}>
                                <input type="checkbox" value={v[valueKey]} checked onChange={e => null} />
                                {v[labelKey]}
                            </label>
                        </StyledOption>
                    ))
                ) : (
                    <StyledOptionLabel>{t('Select;Not Selected')}</StyledOptionLabel>
                )}
            </div>
            <div>
                <div style={groupTitleStyle}>{t('Select;All Items')}</div>
                {children}
            </div>
        </components.MenuList>
    );
};

const SearchableSelectWithButton = ({
    buttonLabelOn = true,
    className,
    data = [],
    selected = [],
    title,
    labelKey = 'label',
    valueKey = 'value',
    onChange,
}) => {
    const [state] = useReducer(null, { title, labelKey, valueKey });
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    const getOptionValue = option => option[valueKey];
    const getOptionLabel = option => option[labelKey];
    //const filterOption = ({ label }, string) => ~label.toLowerCase().indexOf(string.toLowerCase());
    const selectedOptions = useMemo(() => {
        return data.reduce((acc, curr) => {
            if ((selected || []).includes(curr[valueKey])) {
                acc.push(curr);
            }
            return acc;
        }, []);
    }, [data, selected, valueKey]);

    return (
        <SearchableSelectContext.Provider value={state}>
            <DropdownOnLeft
                className={className}
                isOpen={isOpen}
                onClose={toggleOpen}
                target={
                    <Button
                        className={cx(
                            'btn-secondary',
                            buttonLabelOn && 'w-px-150',
                            buttonLabelOn ? 'btn-icon' : 'btn-icon-only',
                        )}
                        onClick={toggleOpen}
                    >
                        <span className="icon-plus">{t('Filter;Add filter')}</span>
                        {buttonLabelOn && t('Filter;Add filter')}
                    </Button>
                }
            >
                <Select
                    isMulti
                    // autoFocus
                    backspaceRemovesValue={false}
                    components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                        MenuList,
                        Option,
                        Control,
                    }}
                    controlShouldRenderValue={false}
                    // hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen
                    onChange={onChange}
                    options={data}
                    placeholder={t('Select;Search')}
                    noOptionsMessage={() => t('Select;No options')}
                    styles={selectStyles}
                    tabSelectsValue={false}
                    getOptionValue={getOptionValue}
                    getOptionLabel={getOptionLabel}
                    value={selectedOptions}
                    //filterOption={filterOption}
                />
            </DropdownOnLeft>
        </SearchableSelectContext.Provider>
    );
};

export default SearchableSelectWithButton;
