import React, { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import cx from 'classnames';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import useTranslation from '../../../../../../../util/hooks/useTranslation';
import Button from '../../../../../../Common/Button';
import Label from '../../../../../../Common/Label';
import ReactSelect from '../../../../../../Common/Select';
import ManualInputGroup from './ManualInputGroup';
import RestfulApiInputGroup from './RestfulApiInputGroup';
import { getApiURL } from '../../../../../../../api';
import LocationInputGroup from './LocationInputGroup';
import { FlowSchemeStateContext } from '../../index';
import DetailModalHeader from '../../../../Components/DetailModalHeader';
import triggerReducer, {
    addTriggerGroup,
    deleteTriggerGroup,
    initialState,
    makeSaveData,
    MANUAL_GROUP_KEY,
    RESTFUL_GROUP_KEY,
    setTransitionInfo,
    updateTriggerGroupOperator,
} from './triggerReducer';
import { OperatorSelect } from './Parts';
import moment from 'moment';
import useAsync from '../../../../../../../util/hooks/useAsync';
import { fetchFloorList } from '../../../../../../../api/common';
import { setFloorList } from '../../../../../../../reducers/Common/FloorInfo';
import { useDispatch } from 'react-redux';
import { createTriggerInfoApi, getTransitionListInfoApi, updateTriggerInfoApi } from '../../../../../../../api/status';
import useConfirmModal from '../../../../../../Common/ConfirmModal/useConfirmModal';
import { setTransitionList } from '../../../../../../../reducers/Common/StatusInfo';
import ConfirmModal from '../../../../../../Common/ConfirmModal';

export const TriggerStateContext = createContext();
export const TriggerDispatchContext = createContext();

const TransitionTriggerModal = ({ isOpen, toggle, selectedTransition }) => {
    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(triggerReducer, initialState);
    const {
        startStatusNum,
        endStatusNum,
        manualOn,
        restFulOn,
        targetStatusTriggers,
        triggerRelationJson,
        triggerGroup,
    } = state;
    const triggerRelationJsonData = triggerRelationJson.data;
    const triggerRelationJsonOrder = triggerRelationJson.order.filter(
        v => ![MANUAL_GROUP_KEY, RESTFUL_GROUP_KEY].includes(v),
    );
    const { flowSchemeInfo, flowStatusList } = useContext(FlowSchemeStateContext);
    const transitionInfo = useMemo(() => {
        const transitionInfo = { ...selectedTransition };
        //triggerRelationJson
        if (transitionInfo.triggerRelationJson) {
            try {
                transitionInfo.triggerRelationJson = JSON.parse(transitionInfo.triggerRelationJson);
            } catch (e) {
                console.log(e);
                transitionInfo.triggerRelationJson = { data: {}, order: [] };
            }
        } else {
            transitionInfo.triggerRelationJson = { data: {}, order: [] };
        }
        if (transitionInfo.startStatusNum) {
            transitionInfo.startStatusName = (
                flowStatusList.find(v => v.statusNum === transitionInfo.startStatusNum) || {}
            ).statusName;
        }
        if (transitionInfo.endStatusNum) {
            transitionInfo.endStatusName = (
                flowStatusList.find(v => v.statusNum === transitionInfo.endStatusNum) || {}
            ).statusName;
        }
        return transitionInfo;
    }, [isOpen, selectedTransition, flowStatusList]);
    const { actionNum, actionName, actionId, description, startStatusName, endStatusName } = transitionInfo || {};

    const t = useTranslation('Status');
    const buttonT = useTranslation('Button');

    const [selectedTriggerList, setSelectedTriggerList] = useState([]);

    const [restfulApiInput, setRestfulApiInput] = useState('');

    const [saveCompleteModal, setSaveCompleteModal] = useState(false);

    const toggleSaveCompleteModal = () => {
        setSaveCompleteModal(!saveCompleteModal);
    };

    const triggerOptions = useMemo(() => {
        return [
            // { value: 'api', label: t('Restful API') },
            { value: 'location', label: t('Location') },
        ];
    }, [t]);

    // 트리거 추가 후 옵션 변경할 경우
    const handleTriggerOptionChange = (selected, index) => {
        let updatedSelectedTrigger = [];

        for (let i = 0; i < selectedTriggerList.length; i++) {
            if (selected && i === index) {
                updatedSelectedTrigger[index] = selected;
            } else {
                updatedSelectedTrigger.push(selectedTriggerList[i]);
            }
        }

        setSelectedTriggerList(updatedSelectedTrigger);
    };

    const { toggleModal: toggleSaveConfirmModal, Modal: SaveConfirmModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Do you want to save?'),
        okCallback: () => {
            const triggerInfo = makeSaveData(triggerRelationJson);

            updateTriggerInfo({
                ...triggerInfo,
                actionNum,
            });
        },
    });

    const { promise: createTriggerInfo } = useAsync({
        promise: createTriggerInfoApi,
        resolve: res => {
            toggleSaveCompleteModal();
        },
        reject: err => {
            console.log({ err });
        },
    });
    const { promise: updateTriggerInfo } = useAsync({
        promise: updateTriggerInfoApi,
        resolve: res => {
            toggleSaveCompleteModal();
            getTransitionListInfo();
            toggle();
        },
        reject: err => {
            console.log({ err });
        },
    });

    const { promise: getTransitionListInfo } = useAsync({
        promise: getTransitionListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            storeDispatch(setTransitionList(res));
        },
    });

    useAsync({
        promise: fetchFloorList,
        resolve: res => {
            storeDispatch(setFloorList(res));
        },
        fixedParam: { isAll: 'Y' },
        immediate: true,
    });

    useEffect(() => {
        if (transitionInfo) {
            dispatch(setTransitionInfo(transitionInfo));
        }
    }, [transitionInfo]);

    // restful api 경로 호출
    useEffect(() => {
        getApiURL().then(({ wmsApiUrl }) => {
            setRestfulApiInput(`${wmsApiUrl}/asset/{AssetNumber}/transition/${actionId}/execute/{UUID}`);
        });
    }, [actionId]);

    return (
        <TriggerDispatchContext.Provider value={dispatch}>
            <TriggerStateContext.Provider value={state}>
                <Modal
                    container={document.getElementsByClassName('app-container')[0]}
                    className={'pnt-modal transition-trigger-container'}
                    isOpen={isOpen}
                    toggle={toggle}
                >
                    <DetailModalHeader
                        title={t('Transition Trigger')}
                        subTitle={t('Function to set the execution condition for the transition.')}
                    />
                    <ModalBody>
                        <div className={'trigger-description'}>
                            <Label
                                labelGroupClassName={'col-xl-6 col-lg-12'}
                                labelValueClassName={'label-dot color-brand'}
                                name={t('Transition')}
                                value={
                                    <div className={'description-value'}>
                                        {actionName} <span className={'description-text'}>{description}</span>
                                    </div>
                                }
                            />
                            <Label
                                labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                labelValueClassName={'label-dot color-brand'}
                                name={t('From → to')}
                                value={
                                    <div className={'description-value'}>
                                        <span>{startStatusName}</span>
                                        <span style={{ margin: '0 5px' }}> → </span>
                                        <span>{endStatusName}</span>
                                    </div>
                                }
                            />
                        </div>
                        <div className={'pnt-border border-w mb-3'} />
                        <div className={'trigger-btn-container'}>
                            <Button
                                className={cx('btn-icon btn-secondary btn-long')}
                                iconClassName={'icon-plus'}
                                onClick={() => {
                                    const groupKey = `$${moment().valueOf().toString(36)}`;
                                    dispatch(addTriggerGroup({ groupKey }));
                                    // const newDefaultList = triggerOptions.filter(
                                    //     option => !selectedTriggerList.find(list => list.value === option.value),
                                    // );
                                    // setSelectedTriggerList(prevState => [...prevState, newDefaultList[0]]);
                                }}
                            >
                                {t('Add trigger')}
                            </Button>
                        </div>
                        <div className={'trigger-body-container'}>
                            <ManualInputGroup />
                            <RestfulApiInputGroup apiURL={restfulApiInput} />
                            {/* 트리거 추가 버튼 클릭시 트리거 카운트 숫자가 올라가면서 갯수에 따른 컴포넌트 추가 */}
                            {triggerRelationJsonOrder.map(groupKey => {
                                const triggerGroupInfo = triggerRelationJsonData[groupKey];
                                return (
                                    <div key={groupKey} className={'input-container-group'}>
                                        <div className={'input-container'}>
                                            <OperatorSelect
                                                value={triggerGroupInfo.op}
                                                disabled={triggerGroupInfo.disabled}
                                                onChange={selected => {
                                                    dispatch(updateTriggerGroupOperator({ groupKey, op: selected }));
                                                }}
                                            />
                                            <Button
                                                iconClassName={'icon-close'}
                                                className={'btn-danger btn-icon-only'}
                                                onClick={e => {
                                                    dispatch(deleteTriggerGroup(groupKey));
                                                    // handleListRemoveClick(condition, index);
                                                }}
                                            />
                                        </div>
                                        <div className={'input-container trigger-group'}>
                                            <div>
                                                <ReactSelect
                                                    name={'triggerSelect'}
                                                    value={
                                                        triggerGroupInfo.triggers[0]
                                                            ? triggerOptions.find(
                                                                  v =>
                                                                      v.value ===
                                                                      triggerGroupInfo.triggers[0].triggerType,
                                                              )
                                                            : triggerOptions[0]
                                                    }
                                                    options={triggerOptions}
                                                    onChange={selected => {
                                                        handleTriggerOptionChange(selected);
                                                    }}
                                                    disabled={!!triggerGroupInfo.triggers.length}
                                                    customControlStyles={{ width: '100%' }}
                                                    customMenuStyles={{ width: '100%' }}
                                                />
                                            </div>

                                            {/* 트리거 타입에 따라 다른 인풋 그룹 랜더링 필요 */}
                                            <LocationInputGroup groupKey={groupKey} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button className={'color-brand'} onClick={toggle}>
                            {buttonT('Cancel')}
                        </Button>
                        <Button
                            className={'btn-brand'}
                            iconClassName={'icon-check'}
                            onClick={() => {
                                toggleSaveConfirmModal();
                            }}
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '80px',
                            }}
                        >
                            {t('Save')}
                        </Button>
                    </ModalFooter>
                </Modal>
                <SaveConfirmModal />
                {/*<SaveCompleteModal />*/}
                <ConfirmModal
                    initModal={saveCompleteModal}
                    toggleModal={toggleSaveCompleteModal}
                    removeCancel={true}
                    confirmText={t('Save is complete.')}
                />
            </TriggerStateContext.Provider>
        </TriggerDispatchContext.Provider>
    );
};

export default TransitionTriggerModal;
