import React from 'react';
import produce from 'immer';

const defaultActionCreator = (type, param) => ({ type, payload: param });
const CHANGE_OPTION = 'CHANGE_OPTION';
const UPDATE_FLATTEN_DATA = 'UPDATE_FLATTEN_DATA';
const UPDATE_CHECKED_NODE = 'UPDATE_CHECKED_NODE';
const UPDATE_TITLE = 'UPDATE_TITLE';

export const changeOption = param => defaultActionCreator(CHANGE_OPTION, param);
export const updateFlattenData = param => defaultActionCreator(UPDATE_FLATTEN_DATA, param);
export const updateCheckedNode = param => defaultActionCreator(UPDATE_CHECKED_NODE, param);
export const updateTitle = param => defaultActionCreator(UPDATE_TITLE, param);

function addLeafNode(treeData, dataToAdd, valueKey, parentKey) {
    const parent = dataToAdd[parentKey];
    let find = false;

    // 자식 탐색
    treeData.every(v => {
        if (v[valueKey] === parent) {
            if (v.children) {
                v.children.push(dataToAdd);
            } else {
                v.children = [dataToAdd];
            }
            find = true;
            return false;
        }
        return true;
    });

    // 자식 탐색 실패시 자식의 자식 탐색
    if (!find) {
        treeData.every(v => {
            if (v.children) {
                find = addLeafNode(v.children, dataToAdd, valueKey, parentKey);
                if (find) {
                    return false;
                }
            }
            return true;
        });
    }
    return find;
}
function makeTreeData(treeData = [], flattenData, valueKey, parentKey) {
    const dataToAdd = [...flattenData];
    const restData = [];

    if (treeData.length) {
        while (dataToAdd.length > 0) {
            const curr = dataToAdd.shift();
            const found = addLeafNode(treeData, curr, valueKey, parentKey);
            if (!found) {
                restData.push(curr);
            }
        }
    }

    if (restData.length > 0) {
        makeTreeData(treeData, restData, valueKey, parentKey);
    }
    return treeData;
}

export const treeSelectReducer = (state, action) => {
    switch (action.type) {
        case CHANGE_OPTION:
            return produce(state, draft => {
                draft.changeable = action.payload;
            });
        case UPDATE_FLATTEN_DATA:
            const { valueKey, labelKey, parentKey } = state;
            const flattenData = action.payload.map(v => ({
                ...v,
                value: v[valueKey],
                label: v[labelKey],
            }));

            const initialData = flattenData.reduce(
                (acc, curr) => {
                    if (flattenData.find(v => v[valueKey] === curr[parentKey])) {
                        acc.rest.push(curr);
                    } else {
                        acc.root.push(curr);
                    }
                    return acc;
                },
                { root: [], rest: [] },
            );
            return {
                ...state,
                flattenData: flattenData,
                treeData: makeTreeData(initialData.root, initialData.rest, valueKey, parentKey),
            };
        case UPDATE_CHECKED_NODE:
            return { ...state, checked: action.payload };
        case UPDATE_TITLE:
            return { ...state, title: action.payload };
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
};
