import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import TimeLineInJournal from '../../../../Common/TimeLine/TimeLineInJournal';
import { secToHms } from '../../../../../util/common/util';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Label from '../../../../Common/Label';

const StatusJournalTab = ({ statusLogInfo, statusColorInfo }) => {
    const t = useTranslation('Status Journal Tab');
    const { lang } = useSelector(state => state.UserInfo);
    const [selectedLogInfo, setSelectedLogInfo] = useState({});
    const handleLogClick = e => {
        let foundOne = statusLogInfo.find(log => log.logNum === e.logNum);
        setSelectedLogInfo(foundOne);
    };

    return (
        <div style={{ height: '700px' }} className={'d-flex w-100'}>
            <div className={'timeline-height w-50'}>
                <div className="card-header mb-4 pl-0 pr-0">
                    <div className="card-header__title">
                        <div className={'title-wrap'}>
                            <h3 className="title__main">{t('Timeline')}</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body tab-body h-100">
                    <TimeLineInJournal
                        onClick={handleLogClick}
                        withDate={false}
                        events={statusLogInfo}
                        statusColorInfo={statusColorInfo}
                    />
                </div>
            </div>
            <div className={'w-50'}>
                <div className="card-header mb-4 pl-0 pr-0">
                    <div className="card-header__title">
                        <div className={'title-wrap'}>
                            <h3 className="title__main">{t('Detail')}</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body tab-body">
                    <div className={'pnt-label-7 flx-col gap-4'}>
                        <Label
                            labelValueClassName={'label-dot color-brand'}
                            name={t('Start time')}
                            value={
                                selectedLogInfo.regDate
                                    ? moment(selectedLogInfo.regDate * 1000)
                                          .locale(lang)
                                          .format('YYYY-MM-DD A hh:mm')
                                    : '-'
                            }
                        />
                        <Label
                            labelValueClassName={'label-dot color-brand'}
                            name={t('End time')}
                            value={
                                selectedLogInfo.endTime
                                    ? moment(selectedLogInfo.endTime * 1000)
                                          .locale(lang)
                                          .format('YYYY-MM-DD A hh:mm')
                                    : '-'
                            }
                        />
                        <Label
                            labelValueClassName={'label-dot color-brand'}
                            name={t('Stay time')}
                            value={secToHms(selectedLogInfo.stayTime, true, t)}
                        />
                        <Label
                            labelValueClassName={'label-dot color-brand'}
                            name={t('Previous status')}
                            value={selectedLogInfo.previousStatusName ? selectedLogInfo.previousStatusName : '-'}
                        />
                        <Label
                            labelValueClassName={'label-dot color-brand'}
                            name={t('Current status')}
                            value={selectedLogInfo.statusName ? selectedLogInfo.statusName : '-'}
                        />
                        <Label
                            labelValueClassName={'label-dot color-brand'}
                            name={t('Last location')}
                            value={selectedLogInfo.floorNamePath ? selectedLogInfo.floorNamePath : '-'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatusJournalTab;
