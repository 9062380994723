import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Card as RSCard, CardBody } from 'reactstrap';

const Tab = ({ tabs = [{}], styles = {}, handleTabChange = () => {}, activeTabId }) => {
    const [tabOn, setTabOn] = useState(activeTabId ? activeTabId : tabs[0].tabId);

    useEffect(() => {
        handleTabChange(tabOn);
    }, [tabOn]);

    useEffect(() => {
        setTabOn(activeTabId);
    }, [activeTabId]);

    return (
        <RSCard className={'h-100'}>
            <div className={'card-tab tab-list1'}>
                <ul style={{ ...{ padding: '.5rem .5rem 0', ...styles.header } }}>
                    {tabs.map((data, index) => {
                        return (
                            <li
                                id={data.tabId}
                                className={cx(tabOn === data.tabId && 'on', 'cursor-pointer')}
                                style={{ ...styles.headerItem }}
                                key={data.tabId + '_' + index}
                                onClick={e => {
                                    e.preventDefault();
                                    setTabOn(e.target.id);
                                }}
                            >
                                <a
                                    href={'#!'}
                                    id={data.tabId}
                                    onClick={e => {
                                        e.preventDefault();
                                        setTabOn(e.target.id);
                                    }}
                                >
                                    {data.title}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <CardBody>
                {tabs.map((data, index) => {
                    return (
                        <div key={data.tabId + '_' + index} className={cx(tabOn !== data.tabId && 'd-none')}>
                            {data.content}
                        </div>
                    );
                })}
            </CardBody>
        </RSCard>
    );
};

export default Tab;
