import React, { useState, useEffect, useMemo } from 'react';
import useTranslation from '../../util/hooks/useTranslation';
import SearchableSelectWithButton from '../MainPages/Components/Select/SearchableSelectWithButton';
import useAsync from '../../util/hooks/useAsync';
import { editFilterApi, getFilterListApi } from '../../api/filter';
import { filterInfo as fixedFilterInfo } from '../MainPages/Filters/Filter/filterReducer';
import useConfirmModal from '../Common/ConfirmModal/useConfirmModal';
import { setSelectedFilter } from '../../reducers/FilterInfo';
import FilterListSelect from './FilterListSelect';
import Button from '../Common/Button';
import { useDispatch } from 'react-redux';
import { getLsUserInfo } from '../../util/common/util';

const FilterWidget = ({ filterInfo, handleChange, editable = true, onDashboard = false }) => {
    const dispatch = useDispatch();
    const t = useTranslation('Filter');
    const [currFilterInfo, setCurrFilterInfo] = useState({});
    const { userInfo } = getLsUserInfo();
    const editableFilterAuth = useMemo(() => {
        let auth = false;
        if (filterInfo && filterInfo.filterInfoAccessRight) {
            auth = filterInfo.filterInfoAccessRight.find(({ userNum, groupNum, accessScope }) => {
                // 읽기 권한 &&
                // (모든 사용자 || 해당 유저 || 해당 그룹)
                return (
                    accessScope === 'W' &&
                    (userNum === -1 ||
                        groupNum === -1 ||
                        userNum === Number(userInfo.userNum) ||
                        groupNum === Number(userInfo.groupNum))
                );
            });
        }
        return auth;
    }, [filterInfo, userInfo]);

    const { toggleModal: toggleSaveModal, Modal: SaveModal } = useConfirmModal({
        confirmText: t('Do you want to save?'),
        okCallback: () => {
            const { filterInfoCondition } = currFilterInfo;
            editFilter({
                ...currFilterInfo,
                filterInfoCondition: filterInfoCondition.filter(v => v.conditionValues.length),
                serviceCode: 'AM01',
            });
        },
    });
    const { toggleModal: toggleSaveCompleteModal, Modal: SaveCompleteModal } = useConfirmModal({
        confirmText: t('Save is complete'),
        removeCancel: true,
    });

    const { toggleModal: toggleRefreshModal, Modal: RefreshModal } = useConfirmModal({
        confirmText: t('Do you want to refresh?'),
        okCallback: () => {
            setCurrFilterInfo(filterInfo || {});
        },
    });

    const {
        promise: getFilterList,
        state: { response: filterListInfo },
    } = useAsync({
        promise: getFilterListApi,
        param: { isAll: 'Y' },
        resolve: () => {},
    });

    const { promise: editFilter } = useAsync({
        promise: editFilterApi,
        resolve: res => {
            toggleSaveCompleteModal();
        },
    });

    useEffect(() => {
        setCurrFilterInfo(filterInfo || {});
    }, [filterInfo]);

    useEffect(() => {
        if (typeof handleChange === 'function') {
            // handleChange(convertConditionInfoToMap(currFilterInfo.filterInfoCondition || []));
        }
    }, [currFilterInfo]);

    useEffect(() => {
        if (onDashboard) {
            getFilterList();
        }
    }, [onDashboard]);

    return (
        <>
            <div className="filter__now align-items-center">
                <div className="filter__info">
                    <div className="pnt-label--group">
                        <span className="label-main">{t('Filter')}</span>
                    </div>
                </div>
                <div className="filter__options margin-left-under-770">
                    {currFilterInfo.filterInfoCondition ? (
                        currFilterInfo.filterInfoCondition.length > 0 ? (
                            currFilterInfo.filterInfoCondition.map(conditionInfo => {
                                const conditionId = conditionInfo.conditionId;
                                const thisFilter = fixedFilterInfo.find(fixedFilter => fixedFilter.id === conditionId);
                                if (!thisFilter) {
                                    return null;
                                }
                                const FilterComponent = thisFilter.FilterComponent;
                                const isTree = conditionInfo.isTree;
                                return (
                                    !!FilterComponent && (
                                        <FilterComponent
                                            key={conditionId}
                                            hideOption
                                            selected={conditionInfo.conditionValues}
                                            changeable={conditionInfo.isEditable}
                                            handleChange={(selected, changeable) => {
                                                const condition = currFilterInfo.filterInfoCondition.map(
                                                    currCondition => {
                                                        if (conditionId === currCondition.conditionId && isTree) {
                                                            return {
                                                                ...currCondition,
                                                                isEditable: changeable,
                                                                conditionValues: selected,
                                                            };
                                                        } else if (conditionId === currCondition.conditionId) {
                                                            return {
                                                                ...currCondition,
                                                                isEditable: changeable,
                                                                conditionValues: selected.map(v => v[conditionId]),
                                                            };
                                                        }
                                                        return currCondition;
                                                    },
                                                );
                                                setCurrFilterInfo({
                                                    ...currFilterInfo,
                                                    filterInfoCondition: condition,
                                                });
                                            }}
                                        />
                                    )
                                );
                            })
                        ) : (
                            <span style={{ color: '#8c8c8c' }}>{t('Filter is not selected')}</span>
                        )
                    ) : (
                        // 높이 유지를 위한 더미 엘리먼트
                        <div className={'mb-2 mt-2'} style={{ height: '38px', width: '100%' }}></div>
                    )}
                </div>
                <div className="filter__function d-flex">
                    <Button
                        className={'btn-danger btn-icon-only mr-2'}
                        iconClassName={'icon-refresh'}
                        onClick={toggleRefreshModal}
                    />
                    {onDashboard ? (
                        <FilterListSelect
                            data={filterListInfo ? filterListInfo.rows : []}
                            selected={currFilterInfo}
                            handleChange={selected => {
                                dispatch(setSelectedFilter(selected));
                            }}
                        />
                    ) : (
                        <>
                            {/*{editableFilterAuth && editable && (*/}
                            {/*    <Button*/}
                            {/*        className={'btn-blue btn-icon-only mr-2'}*/}
                            {/*        iconClassName={'icon-save'}*/}
                            {/*        onClick={e => {*/}
                            {/*            toggleSaveModal();*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*)}*/}
                            {/*<SearchableSelectWithButton*/}
                            {/*    buttonLabelOn={false}*/}
                            {/*    data={fixedFilterInfo.map(v => ({ ...v, name: t(v.name) }))}*/}
                            {/*    selected={*/}
                            {/*        currFilterInfo.filterInfoCondition*/}
                            {/*            ? currFilterInfo.filterInfoCondition.map(v => v.conditionId)*/}
                            {/*            : []*/}
                            {/*    }*/}
                            {/*    valueKey={'id'}*/}
                            {/*    labelKey={'name'}*/}
                            {/*    onChange={selected => {*/}
                            {/*        const currFilterConditions = currFilterInfo.filterInfoCondition || [];*/}
                            {/*        setCurrFilterInfo({*/}
                            {/*            ...currFilterInfo,*/}
                            {/*            filterInfoCondition: selected.map(v => {*/}
                            {/*                const thisCondition = currFilterConditions.find(*/}
                            {/*                    condition => condition.conditionId === v.id,*/}
                            {/*                );*/}
                            {/*                return {*/}
                            {/*                    conditionId: v.id,*/}
                            {/*                    conditionType: v.type,*/}
                            {/*                    conditionValues: thisCondition ? thisCondition.conditionValues : [],*/}
                            {/*                    isEditable: thisCondition ? thisCondition.isEditable : 'Y',*/}
                            {/*                };*/}
                            {/*            }),*/}
                            {/*        });*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </>
                    )}
                </div>
            </div>
            <SaveModal />
            <SaveCompleteModal />
            <RefreshModal />
        </>
    );
};

export default FilterWidget;
