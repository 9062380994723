import React, { useEffect, useState } from 'react';
import useTranslation from '../../../../util/hooks/useTranslation';
import Page from '../../../Common/Page';
import Search from '../../Components/Search';
import Card from '../../../Common/Card';
import Button from '../../../Common/Button';
import Table from '../../../Common/Table';
import useColumns from '../../../../util/hooks/useColumns';
import * as column from '../../util/grid/column';
import { useHistory } from 'react-router-dom';
import useAsync from '../../../../util/hooks/useAsync';
import moment from 'moment';
import DatePicker from '../../../Common/DatePicker';
import ApplicationStatusSelect from '../Components/ApplicationStatusSelect';
import DepartmentSelect from '../Components/DepartmentSelect';
import { useSelector } from 'react-redux';
import { getAssetRentalRequestListApi } from '../../../../api/asset';

const AssetRentalRequest = () => {
    const t = useTranslation('AssetRentalRequest');

    const { groupId, groupNum } = useSelector(state => state.UserInfo.userInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);

    const history = useHistory();

    const [tableKey, setTableKey] = useState(0);
    const [selectedSearchOption, setSelectedSearchOption] = useState({
        lendingDepartment: [],
        applicationStatus: [],
    });
    const [assetRentalRequestList, setAssetRentalRequestList] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });
    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(moment().endOf('day').valueOf());

    const columns = useColumns(
        [
            column.assetRentalRequestNum({
                width: 90,
            }),
            column.assetRentalRequestDepart({
                width: 100,
            }),
            column.assetRentalApplicant({
                width: 100,
            }),
            column.assetRentalDepartment({
                width: 100,
            }),
            column.applicationDate(),
            column.rentalPeriod({
                width: 200,
                Cell: ({ row }) => {
                    const { original } = row;

                    return (
                        <div>
                            {moment.unix(original.startDate).format('YYYY-MM-DD')} ~
                            {moment.unix(original.endDate).format('YYYY-MM-DD')}
                        </div>
                    );
                },
            }),
            column.applicationStatus({
                width: 90,
                Cell: ({ value }) => {
                    return <div>{t(value)}</div>;
                },
            }),
            column.dateChanged(),
            column.category({
                Cell: ({ value }) => {
                    const category = categoryList.find(({ categoryCode }) => categoryCode === value);
                    if (category) {
                        return <div>{category.categoryNamePath}</div>;
                    } else {
                        return <div>-</div>;
                    }
                },
            }),
            column.quantity({
                width: 90,
            }),
        ],
        null,
        [categoryList],
    );

    useEffect(() => {
        if (startDate > endDate) {
            setEndDate(startDate);
        }
    }, [startDate]);

    const { promise: getAssetRentalRequestList, state: assetRentalRequestInfo } = useAsync({
        promise: getAssetRentalRequestListApi,
        fixedParam: { pageSize: 20, usingDepNums: groupNum },
        param: { startDate: moment(startDate).unix(), endDate: moment(endDate).unix() },
        keepState: true,
        immediate: true,
        deps: [groupNum],
        resolve: res => {
            setAssetRentalRequestList(res);
        },
    });

    const handleSearchClick = searchInput => {
        setTableKey(prevState => prevState + 1);
        getAssetRentalRequestList({
            keyword: searchInput,
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
            statusList: selectedSearchOption.applicationStatus.length
                ? selectedSearchOption.applicationStatus.join(',')
                : null,
            ownDepNums: selectedSearchOption.lendingDepartment.length
                ? selectedSearchOption.lendingDepartment.join(',')
                : null,
        });
    };

    const handleRefreshClick = () => {
        setTableKey(prevState => prevState + 1);
        setSelectedSearchOption({
            lendingDepartment: [],
            applicationStatus: [],
        });
        setStartDate(moment().startOf('day').valueOf());
        setEndDate(moment().endOf('day').valueOf());
        getAssetRentalRequestList({
            startDate: moment().startOf('day').unix(),
            endDate: moment().endOf('day').unix(),
        });
    };

    const handlePageChange = pageIndex => {
        if (assetRentalRequestInfo.request) {
            getAssetRentalRequestList({ ...assetRentalRequestInfo.request, page: pageIndex });
        } else {
            getAssetRentalRequestList({
                startDate: moment(startDate).unix(),
                endDate: moment(endDate).unix(),
                page: pageIndex,
            });
        }
    };

    const handleAssetRentalRequestClick = () => {
        history.push(`/asset-rental/request/application`);
    };

    const handleRentalRequestRowClick = selected => {
        history.push(`/asset-rental/request/${selected.reqNum}`);
    };

    return (
        <Page menuPath={[t('Asset Rental Management', 'Menu'), t('Asset Rental Request', 'Menu')]}>
            <div className="grid-layout-container">
                <Search
                    searchInputPlaceholder={t('Please enter the applicant or id.')}
                    handleSearchClick={handleSearchClick}
                    handleRefreshClick={handleRefreshClick}
                >
                    <DepartmentSelect
                        title={t('Lending Department', 'AssetRentalManagement')}
                        logInUserDepartment={groupId}
                        selectedOption={selectedSearchOption.lendingDepartment}
                        onChange={selected => {
                            setSelectedSearchOption(prevState => {
                                return { ...prevState, lendingDepartment: selected };
                            });
                        }}
                    />
                    <ApplicationStatusSelect
                        selectedOption={selectedSearchOption.applicationStatus}
                        onChange={selected => {
                            setSelectedSearchOption(prevState => {
                                return { ...prevState, applicationStatus: selected };
                            });
                        }}
                    />
                    <DatePicker
                        value={startDate}
                        valueType={'ms'}
                        handleChange={selected => setStartDate(selected)}
                        withoutTime
                    />
                    <DatePicker
                        value={endDate}
                        valueType={'ms'}
                        minDate={startDate}
                        handleChange={selected => setEndDate(selected)}
                        withoutTime
                    />
                </Search>

                <Card
                    header={{
                        title: t('Asset Rental Request List'),
                        action: (
                            <Button className={'btn-brand'} onClick={handleAssetRentalRequestClick}>
                                {t('Rental Request')}
                            </Button>
                        ),
                    }}
                >
                    <div className={'table-container'}>
                        <Table
                            key={tableKey}
                            data={{ ...assetRentalRequestList, pageSize: 20 }}
                            columns={columns}
                            onPageChange={handlePageChange}
                            onTrClick={handleRentalRequestRowClick}
                            loading={assetRentalRequestInfo.isLoading}
                        />
                    </div>
                </Card>
            </div>
        </Page>
    );
};

export default AssetRentalRequest;
