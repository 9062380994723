import React, { useEffect, useState } from 'react';
import Card from '../Components/WidgetCard';
import { useSettings } from '../util/useSettings';
import useMonitor from '../util/useMonitor';
import { getCategoryStatisticApi, getDepartmentStatisticApi } from '../../../api/dashboard';
import { useSelector } from 'react-redux';
import StatusByBarChart from './Components/StatusByBarChart';

const defaultData = {
    result: 'success',
    count: null,
    code: '0',
    rows: [
        {
            comNum: 1,
            statusNum: 3,
            statusName: 'Broken',
            statusCount: 1,
            categoryCode: 'PEOPLE',
            categoryName: 'People',
            groupNum: '1',
            groupName: 'PNT',
        },
        {
            comNum: 1,
            statusNum: 2,
            statusName: 'repairing',
            statusCount: 1,
            categoryCode: 'ASSET',
            categoryName: 'Asset',
            groupNum: '1',
            groupName: 'PNT',
        },
        {
            comNum: 1,
            statusNum: 1,
            statusName: 'use',
            statusCount: 1,
            categoryCode: 'ASSET',
            categoryName: 'Asset',
            groupNum: '2',
            groupName: 'AM',
        },
        {
            comNum: 1,
            statusNum: 3,
            statusName: 'Broken',
            statusCount: 1,
            categoryCode: 'ASSET',
            categoryName: 'Asset',
            groupNum: '2',
            groupName: 'AM',
        },
    ],
};

const tempData = {
    rows: [
        {
            statusNum: 0,
            statusName: null,
            statusCount: 0,
            categoryCode: 'PEOPLE',
            categoryName: null,
            groupNum: 0,
            groupName: null,
        },
        {
            statusNum: 0,
            statusName: null,
            statusCount: 0,
            categoryCode: 'PEOPLE',
            categoryName: null,
            groupNum: 0,
            groupName: null,
        },
        {
            statusNum: 0,
            statusName: null,
            statusCount: 0,
            categoryCode: 'PEOPLE',
            categoryName: null,
            groupNum: 0,
            groupName: null,
        },
        {
            statusNum: 0,
            statusName: null,
            statusCount: 0,
            categoryCode: 'PEOPLE',
            categoryName: null,
            groupNum: 0,
            groupName: null,
        },
    ],
};

const StatusBy = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { name } = useSettings(config);
    const [option, setOption] = useState('category');
    const { mode } = useSelector(state => state.DashboardFrame);
    const { filterParam } = useSelector(state => state.FilterInfo);

    const { data: categoryData } = useMonitor({
        config: config,
        dynamicParam: filterParam,
        defaultData: mode === 'monitoring' ? tempData : defaultData,
        fetchData: getCategoryStatisticApi,
        makeData: ({ newData: { rows: statusInfo = [] } }) => {
            return statusInfo && statusInfo.length > 0 ? statusInfo : tempData.rows;
        },
    });

    const { data: departmentData } = useMonitor({
        config: config,
        dynamicParam: filterParam,
        defaultData: mode === 'monitoring' ? tempData : defaultData,
        fetchData: getDepartmentStatisticApi,
        makeData: ({ newData: { rows: statusInfo = [] } }) => {
            return statusInfo && statusInfo.length > 0 ? statusInfo : tempData.rows;
        },
    });

    useEffect(() => {
        if (config.settings && config.settings.selectedOption) {
            setOption(config.settings.selectedOption.value);
        }
    }, [config]);

    return (
        <Card title={name} widgetInfo={widgetInfo} {...restProps}>
            <StatusByBarChart
                data={option === 'category' ? categoryData : departmentData}
                groupBy={
                    option === 'category'
                        ? { key: 'categoryCode', label: 'categoryName' }
                        : { key: 'groupNum', label: 'groupName' }
                }
            />
            {children}
        </Card>
    );
};

export default StatusBy;
