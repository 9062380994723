import React from 'react';
import cx from 'classnames';
import Label from '../../../Common/Label';

const DetailLabel = ({ required, name, value }) => {
    return (
        <Label
            labelValueClassName={cx('label-dot', required && 'color-brand')}
            name={name}
            value={<div className={cx(`w-100 d-flex justify-content-end`)}>{value}</div>}
        />
    );
};

export default DetailLabel;
