import React from 'react';
import produce from 'immer';
import FloorSearchableSelect from '../FilterOption/FloorSearchableSelect';
import OwnershipSearchableSelect from '../FilterOption/OwnershipSearchableSelect';
import AssetStatusSearchableSelect from '../FilterOption/AssetStatusSearchableSelect';
import CategorySearchableSelect from '../FilterOption/CategorySearchableSelect';

const defaultActionCreator = (type, param) => ({ type, payload: param });

const SET_FILTER = 'SET_FILTER';
const ADD_LIST = 'ADD_LIST';
const HANDLE_SELECTED_FILTER = 'HANDLE_SELECTED_FILTER';
const SET_FILTER_LIST = 'SET_FILTER_LIST';

export const setFilterState = param => defaultActionCreator(SET_FILTER, param);
export const addList = param => defaultActionCreator(ADD_LIST, param);
export const handleSelectedFilter = param => defaultActionCreator(HANDLE_SELECTED_FILTER, param);
export const setFilterListState = param => defaultActionCreator(SET_FILTER_LIST, param);

export const filterInfo = [
    {
        name: 'Floor',
        id: 'floorIds',
        type: 'static',
        isEditable: true,
        selectedList: [],
        isTree: true,
        FilterComponent: FloorSearchableSelect,
    },
    {
        name: 'Ownership Organization',
        id: 'own_dep_id',
        type: 'dynamic',
        isEditable: true,
        selectedList: [],
        isTree: true,
        FilterComponent: OwnershipSearchableSelect,
    },
    {
        name: 'Asset Status',
        id: 'statusCategoryNums',
        type: 'static',
        isEditable: true,
        selectedList: [],
        isTree: false,
        FilterComponent: AssetStatusSearchableSelect,
    },
    {
        name: 'Asset Categorization',
        id: 'categoryCodes',
        type: 'static',
        isEditable: true,
        selectedList: [],
        isTree: true,
        FilterComponent: CategorySearchableSelect,
    },
];

export const initialState = {
    filterInfo: {
        name: '',
        desc: '',
        isApplyMenu: false,
        accessRight: [],
        ownership: [],
        checkAllBox: false,
        checkAllUsersForR: false,
        checkAllUsersForW: false,
        filterName: '',
        description: '',
    },
    selectedFilterList: [
        {
            name: 'Floor',
            id: 'floorIds',
            type: 'static',
            isEditable: 'Y',
            selectedList: [],
            isTree: true,
            FilterComponent: FloorSearchableSelect,
        },
        {
            name: 'Ownership Organization',
            id: 'own_dep_id',
            type: 'dynamic',
            isEditable: 'Y',
            selectedList: [],
            isTree: true,
            FilterComponent: OwnershipSearchableSelect,
        },
        {
            name: 'Asset Status',
            id: 'statusCategoryNums',
            type: 'static',
            isEditable: 'Y',
            selectedList: [],
            isTree: false,
            FilterComponent: AssetStatusSearchableSelect,
        },
        {
            name: 'Asset Categorization',
            id: 'categoryCodes',
            type: 'static',
            isEditable: 'Y',
            selectedList: [],
            isTree: true,
            FilterComponent: CategorySearchableSelect,
        },
    ],
};

export const filterReducer = (state, action) => {
    switch (action.type) {
        case SET_FILTER:
            return produce(state, draft => {
                draft.filterInfo = action.payload;
            });
        case HANDLE_SELECTED_FILTER:
            return produce(state, draft => {
                let tempArr = draft.selectedFilterList.map(filter => filter.id);
                // 저장되어있는 배열과 선택된 배열 비교
                //저장되어 있는 배열의 길이가 긴 경우 = 선택된 아이디를 제외하고 제거
                if (draft.selectedFilterList.length > action.payload.length) {
                    let difference = tempArr.filter(x => !action.payload.includes(x));
                    draft.selectedFilterList = draft.selectedFilterList.filter(filter => filter.id !== difference[0]);
                } else {
                    // 저장되어 있는 배열의 길이가 짧은 경우 = 없는 아이디를 새로 추가
                    let difference = action.payload.filter(x => !tempArr.includes(x));
                    let foundFilter = filterInfo.find(filter => filter.id === difference[0]);
                    draft.selectedFilterList = draft.selectedFilterList.concat(foundFilter);
                }
            });
        case ADD_LIST:
            return produce(state, draft => {
                const { id, list, isEditable } = action.payload;
                const newFilterList = state.selectedFilterList.map(filter => {
                    const tempFilter = { ...filter };
                    if (tempFilter.id === id) {
                        tempFilter.selectedList = list;
                        tempFilter.isEditable = isEditable;
                    }
                    return tempFilter;
                });

                draft.selectedFilterList = newFilterList;
            });
        case SET_FILTER_LIST:
            return produce(state, draft => {
                draft.selectedFilterList = action.payload;
            });
    }
};
