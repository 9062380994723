import React from 'react';
import moment from 'moment';
import CustomMap from '../../../../Common/Map/Components/CustomMap';
import RotatedImageOverlay from '../../../../Common/Map/Components/RotatedImageOverlay';
import styled from 'styled-components';
import { Popup } from 'react-leaflet';
import useTranslation from '../../../../../util/hooks/useTranslation';
import { useSelector } from 'react-redux';
import Label from '../../../../Common/Label';

const StyledPop = styled(Popup)`
    .leaflet-popup-content-wrapper {
        border-radius: 0;
        background-color: #525252;
        color: white;
        width: 200px;
        height: 150px;
    }

    .leaflet-popup-tip {
        background-color: #525252;
    }
`;
const LocationTab = ({ assetInfo, locationInfo, geofenceInfo, floorInfo }) => {
    const t = useTranslation('Location Tab');
    const { lang } = useSelector(state => state.UserInfo);

    return (
        <div>
            <div className="card-header pl-0 pr-0">
                <div className="card-header__title">
                    <div className={'title-wrap'}>
                        <h3 className="title__main">{t('Location Information')}</h3>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className={'d-grid'} style={{ gridTemplateRows: '1fr 3fr' }}>
                    <div className={'location-information-card'}>
                        <Label
                            labelValueClassName={'label-dot color-brand'}
                            name={t('Location Tag')}
                            value={locationInfo.tagName ? locationInfo.tagName : '-'}
                        />
                        <Label
                            labelValueClassName={'label-dot color-brand'}
                            name={t('Floor')}
                            value={assetInfo.floorNamePath ? assetInfo.floorNamePath : '-'}
                        />
                        <Label
                            labelValueClassName={'label-dot color-brand'}
                            name={t('Geofence')}
                            value={locationInfo.tagGeofence ? locationInfo.tagGeofence : '-'}
                        />
                        <Label
                            labelValueClassName={'label-dot color-brand'}
                            name={t('Detecting Status')}
                            value={assetInfo.lostSignalStatus ? assetInfo.lostSignalStatus : '-'}
                        />
                        <Label
                            labelGroupClassName={'mb-3'}
                            labelValueClassName={'label-dot color-brand'}
                            name={t('Last Detected')}
                            value={
                                assetInfo.lastDate > 0
                                    ? moment(assetInfo.lastDate * 1000)
                                          .locale(lang)
                                          .format('YYYY-MM-DD A hh:mm:ss')
                                    : '-'
                            }
                        />
                    </div>
                    <div style={{ width: '100%', height: '440px' }}>
                        <CustomMap
                            markerLngLat={[assetInfo.lat, assetInfo.lng]}
                            geofenceList={
                                geofenceInfo &&
                                geofenceInfo.rows &&
                                geofenceInfo.rows.map(v => ({
                                    ...v,
                                    bounds: v.latLngList.map(({ lat, lng }) => [lat, lng]),
                                }))
                            }
                            markerPopup={
                                <StyledPop>
                                    <div className="pnt-label--group">
                                        <div className="label-main label-dot">{assetInfo.targetNum}</div>
                                    </div>
                                    <div className="pnt-label--group">
                                        <div className="label-main label-dot">{assetInfo.targetName}</div>
                                    </div>
                                    <div className="pnt-label--group">
                                        <div className="label-main label-dot">{assetInfo.targetId}</div>
                                    </div>
                                </StyledPop>
                            }
                        >
                            {floorInfo && floorInfo.neLat && (
                                <RotatedImageOverlay
                                    key={floorInfo.neLat}
                                    url={floorInfo.imgURL}
                                    deg={floorInfo.deg}
                                    bounds={[
                                        [floorInfo.neLat, floorInfo.neLng],
                                        [floorInfo.swLat, floorInfo.swLng],
                                    ]}
                                />
                            )}
                        </CustomMap>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationTab;
