import React, { useState } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownOnLeft, DropdownIndicator } from '../Common/Select/Components/Parts';
import Button from '../Common/Button';
import useTranslation from '../../util/hooks/useTranslation';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const selectStyles = {
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
    menuList: provided => ({
        ...provided,
        minWidth: 200,
    }),
};

const Control = () => {
    return null;
};

const FilterListSelect = ({ data = [], selected, handleChange }) => {
    const t = useTranslation('Common');
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    return (
        <DropdownOnLeft
            isOpen={isOpen}
            onClose={toggleOpen}
            target={
                <Button className={'btn-blue'} onClick={toggleOpen}>
                    {selected.filterName ? selected.filterName : t('List')}
                    <FontAwesomeIcon className={'ml-2'} icon={faAngleDown} />
                </Button>
            }
        >
            <Select
                autoFocus
                backspaceRemovesValue={false}
                components={{ DropdownIndicator, IndicatorSeparator: null, Control: Control }}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                isSearchable={false}
                menuIsOpen
                onChange={handleChange}
                options={data}
                getOptionValue={option => option.filterNum}
                getOptionLabel={option => option.filterName}
                placeholder="Search..."
                styles={selectStyles}
                tabSelectsValue={false}
                value={selected}
            />
        </DropdownOnLeft>
    );
};

export default FilterListSelect;
