import React, { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import useTranslation from '../../../../util/hooks/useTranslation';
import Tree from './Tree';
import { TreeSelectContext } from './index';
import cx from 'classnames';

export const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div className={'select-dropdown'}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);

export const Menu = props => {
    return <div className={'select-menu'} {...props} />;
};

export const Blanket = props => <div className={'select-blanket'} {...props} />;

export const StyledContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    min-width: 11.5rem;
    //max-width: 14rem;
    width: 100%;
    & > div:hover {
        border-color: hsl(0, 0%, 70%);
    }
    & > div {
        align-items: center;
        background-color: hsl(0, 0%, 100%);
        border: ${props => (props.isOpen ? '1px solid #2684ff' : '1px solid hsl(0, 0%, 80%)')};
        box-shadow: ${props => props.isOpen && '0 0 0 1px #2684ff'};
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 38px;
        outline: 0 !important;
        position: relative;
        transition: all 100ms;
        box-sizing: border-box;

        div:first-child {
            align-items: center;
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            padding: 2px 8px;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
        }
        div:last-child {
            align-items: center;
            align-self: stretch;
            display: flex;
            flex-shrink: 0;
            span {
                align-self: stretch;
                background-color: hsl(0, 0%, 80%);
                margin-bottom: 8px;
                margin-top: 8px;
                width: 1px;
                box-sizing: border-box;
            }
            div {
                color: hsl(0, 0%, 80%);
                display: flex;
                padding: 8px;
                transition: color 150ms;
                box-sizing: border-box;
                svg {
                    display: inline-block;
                    fill: currentColor;
                    line-height: 1;
                    stroke: currentColor;
                    stroke-width: 0;
                }
            }
            div:hover {
                color: hsl(0, 0%, 60%);
            }
        }
    }
`;

export const ValueContainer = ({ selected, onClick, isOpen, totalLength }) => {
    return (
        <StyledContainer onClick={onClick} isOpen={isOpen}>
            <div className={cx('select-container', isOpen && 'container-open')}>
                <div>
                    <ValueContainerText selected={selected} totalLength={totalLength} />
                </div>
                <div>
                    <div>
                        <svg
                            className={'select-dropdown-svg'}
                            height="16"
                            width="18"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                        >
                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
                        </svg>
                    </div>
                </div>
            </div>
        </StyledContainer>
    );
};

export function mergeSelected(flattenData, treeKey, parentKey, selected) {
    let mergedSelected = [...selected];
    const parents = selected.reduce((acc, curr) => {
        if (curr[parentKey]) {
            acc.push(curr[parentKey]);
        }
        return acc;
    }, []);

    const childrenLenCheck = parents.reduce((acc, curr) => {
        const childrenLen = flattenData.filter(v => v[parentKey] === curr).length;
        const selectedLen = selected.filter(v => v[parentKey] === curr).length;
        acc[curr] = childrenLen === selectedLen;
        return acc;
    }, {});

    for (let parent in childrenLenCheck) {
        if (childrenLenCheck.hasOwnProperty(parent)) {
            const checked = childrenLenCheck[parent];
            if (checked) {
                const foundIndex = mergedSelected.findIndex(v => v[parentKey] === parent);
                mergedSelected = mergedSelected.filter(v => v[parentKey] !== parent);
                mergedSelected.splice(
                    foundIndex,
                    0,
                    flattenData.find(v => v[treeKey] === parent),
                );
                return mergeSelected(flattenData, treeKey, parentKey, mergedSelected);
            }
        }
    }
    return mergedSelected;
}

export const ValueContainerText = ({ selected, totalLength }) => {
    const t = useTranslation('TreeSelect');
    const { title, labelKey, treeKey, parentKey, flattenData, emptyText } = useContext(TreeSelectContext);
    const mergedSelection = useMemo(() => {
        return mergeSelected(flattenData, treeKey, parentKey, selected);
    }, [treeKey, parentKey, flattenData, selected]);
    const selectedLength = mergedSelection ? mergedSelection.length : 0;
    const totalSelectedLength = selected ? selected.length : 0;
    return !selectedLength || totalSelectedLength === totalLength ? (
        <strong>
            {title} : {emptyText ? emptyText : t('All')}
        </strong>
    ) : selectedLength === 1 ? (
        <strong>{mergedSelection[0][labelKey]}</strong>
    ) : (
        <>
            <strong>{mergedSelection[0][labelKey]}</strong>
            <span>
                &nbsp;{t('other')} {selectedLength - 1}
            </span>
        </>
    );
};

export const Svg = p => <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />;
export const DropdownIndicator = () => (
    <div className={'select-dropdown-indicator'}>
        <Svg>
            <path
                d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </Svg>
    </div>
);

export const findAllOptions = (options, allOptions = []) => {
    options.forEach(option => {
        if (option.children) {
            findAllOptions(option.children, allOptions);
        } else {
            allOptions.push(option.value);
        }
    });
    return allOptions;
};

export const TreeMenuList = ({ children, getValue, setValue, options, ...restProps }) => {
    const t = useTranslation('TreeSelect');
    const selected = getValue();
    const { valueKey, labelKey, treeKey, parentKey, flattenData } = useContext(TreeSelectContext);
    const mergedSelection = useMemo(() => {
        return mergeSelected(flattenData, treeKey, parentKey, selected);
    }, [treeKey, parentKey, flattenData, selected]);

    const allOptions = useMemo(() => {
        return findAllOptions(options);
    }, [options]);

    const handleChange = e => {
        const { checked } = e.currentTarget;
        setValue(checked ? allOptions : []);
    };

    // // 자기 자신과 하위 노드들을 선택
    // // @param checked 체크된 노드들의 valueKey
    // // @return 하위 노드들을 포함한 노드
    // const makeCheckedDescendantsNode = useCallback(
    //     checked => {
    //         let descendantsNode = [];
    //         checked.forEach(v => {
    //             descendantsNode.push(flattenData.find(original => original[valueKey] === v));
    //             descendantsNode = descendantsNode.concat(
    //                 flattenData.filter(original => ~original[valueKey].indexOf(v + '>')),
    //             );
    //         });
    //         return descendantsNode.map(v => v[valueKey]);
    //     },
    //     [valueKey, flattenData],
    // );
    //
    // // 초기 체크될 값
    // const [checked, setChecked] = useState(selected ? makeCheckedDescendantsNode(selected.map(v => v[valueKey])) : []);
    const [checked, setChecked] = useState(
        selected.map(v => {
            if (typeof v === 'object') {
                return v[valueKey];
            }
            return v;
        }),
    );

    const handleCheck = useCallback(checked => {
        setChecked(checked);
    }, []);

    useEffect(() => {
        setChecked(
            selected.map(v => {
                if (typeof v === 'object') {
                    return v[valueKey];
                }
                return v;
            }),
        );
    }, [selected.length]);

    // useEffect(() => {
    //     const calculatedChecked = [];
    //     checked.forEach(treePath => {
    //         const lastNode = calculatedChecked[calculatedChecked.length - 1];
    //         if (!lastNode || (lastNode && treePath.indexOf(lastNode[valueKey] + '>') !== 0)) {
    //             calculatedChecked.push(flattenData.find(option => option[valueKey] === treePath));
    //         }
    //     });
    //     setValue(calculatedChecked);
    // }, [checked]);

    useEffect(() => {
        const checkedToStr = checked.map(v => v.toString());
        setValue(flattenData.filter(v => checkedToStr.includes(v[valueKey].toString())).map(v => v[valueKey]));
    }, [checked, flattenData, valueKey]);

    return (
        <components.MenuList {...restProps}>
            <div className={'mb-2'}>
                <div className={'select-group-title'}>{t('Selected')}</div>
                {mergedSelection.length ? (
                    mergedSelection.map((v, index) => {
                        return (
                            <div
                                className={'styled-option'}
                                key={v[valueKey] + index}
                                onClick={e => {
                                    const filtered = mergedSelection.filter(
                                        selectedOption => selectedOption[valueKey] !== v[valueKey],
                                    );
                                    // setValue(filtered);
                                    setChecked(filtered.map(selectedOption => selectedOption[valueKey]));
                                }}
                            >
                                <label>
                                    <input
                                        type="checkbox"
                                        style={{ cursor: 'pointer' }}
                                        value={v[valueKey]}
                                        checked
                                        onChange={e => null}
                                    />
                                    {v[labelKey]}
                                </label>
                            </div>
                        );
                    })
                ) : (
                    <div className={'styled-option-label'}>{t('Not Selected')}</div>
                )}
            </div>
            <div className={'select-group-title pt-0 pb-0'}>
                <label className={'text-ellipsis mb-0'} title="All Items">
                    <input type="checkbox" onChange={handleChange} checked={selected.length === allOptions.length} />
                    {t('All Items')}
                </label>
            </div>
            {!selected.length && !options.length ? (
                <div className={'styled-option-label'}>{t('No matches found')}</div>
            ) : (
                <div style={{ padding: '.5rem' }}>
                    <Tree data={options} checked={checked} setChecked={handleCheck} />
                    {/*{children}*/}
                </div>
            )}
        </components.MenuList>
    );
};

const findParentNodeSubRows = (path, searchList) => {
    if (path.length > 1) {
        const currPath = path.shift();
        return findParentNodeSubRows(path, searchList.find(node => node.floorId === currPath).children);
    } else {
        return searchList;
    }
};
