import React, { useMemo, useState } from 'react';
import useTranslation from '../../../../util/hooks/useTranslation';
import SearchableSelect from '../../../Common/Select/SearchableSelect';

const RentalStatusSelect = ({ onChange, selectedOption }) => {
    const t = useTranslation('AssetRentalRequest');

    const searchOptions = useMemo(() => {
        return [
            { value: 'allocation', label: t('allocation') },
            { value: 'cancel_allocation', label: t('cancel_allocation') },
            { value: 'check_out', label: t('check_out') },
            { value: 'confirm_check_out', label: t('confirm_check_out') },
            { value: 'check_in', label: t('check_in') },
            { value: 'confirm_check_in', label: t('confirm_check_in') },
        ];
    }, [t]);

    const handleSelectedOptionChange = selected => {
        onChange(selected.map(v => v.value));
    };

    return (
        <SearchableSelect
            onChange={handleSelectedOptionChange}
            data={searchOptions}
            selected={selectedOption}
            title={'Rental Status'}
        />
    );
};

export default RentalStatusSelect;
