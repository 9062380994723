import React, { useEffect, useState } from 'react';
import useAsync from '../../../../util/hooks/useAsync';
import {
    getAssetInspectionListApi,
    getAssetStatusLogApi,
    getRealtimePresenceInfoApi,
    getSelectedAssetInfoApi,
    getStatusInfoApi,
} from '../../../../api/asset';

import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import Tab from '../../../Common/Tab';
import { returnCategoryProperty } from '../../../../util/common/util';
import useTranslation from '../../../../util/hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { fetchGeofenceList, getCategoryPropertyInfoApi, getSelectedFloorInfoApi } from '../../../../api/common';
import AssetDetailInformation from '../../Assets/AssetDetail/Components/AssetDetailInformation';
import LocationTab from '../../Assets/AssetDetail/Components/LocationTab';
import StatusJournalTab from '../../Assets/AssetDetail/Components/StatusJournalTab';
import InspectionLogTab from '../../Assets/AssetDetail/Components/InspectionLogTab';
import Button from '../../../Common/Button';

const AssetInspectionScheduledDetail = ({ match }) => {
    const { params } = match;
    const history = useHistory();
    const t = useTranslation('Asset Inspection Detail');
    const [assetInfo, setAssetInfo] = useState({});
    const [categoryPropertyInfo, setCategoryPropertyInfo] = useState([]);
    const [floorInfo, setFloorInfo] = useState([]);
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [locationInfo, setLocationInfo] = useState([]);
    const [assetCategoryPropertyInfo, setAssetCategoryPropertyInfo] = useState([]);
    const [open, setOpen] = useState(false);
    const [statusLogInfo, setStatusLogInfo] = useState([]);
    const [statusColorInfo, setStatusColorInfo] = useState({});
    const [floorId, setFloorId] = useState('');
    const [assetInspectionPage, setAssetInspectionPage] = useState(false);
    const [openedTab, setOpenedTab] = useState('inspectionLog');

    const { promise: getAssetDetail } = useAsync({
        promise: getSelectedAssetInfoApi,
        immediate: true,
        resolve: res => {
            setAssetInfo(res);
        },
    });

    const { promise: getCategoryPropertyInfo } = useAsync({
        promise: getCategoryPropertyInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const filteredCategoryPropertyList = res.rows.filter(prop => prop.isListProperty === 'Y');
            setCategoryPropertyInfo(filteredCategoryPropertyList);
        },
    });

    const { promise: getSelectedFloorInfo } = useAsync({
        promise: getSelectedFloorInfoApi,
        resolve: res => {
            setFloorInfo(res);
        },
    });

    const { promise: getGeofenceInfo } = useAsync({
        promise: fetchGeofenceList,
        resolve: res => {
            setGeofenceInfo(res);
        },
    });

    const { promise: getRealtimePresenceInfo } = useAsync({
        promise: getRealtimePresenceInfoApi,
        resolve: res => {
            if (!res) {
                setLocationInfo({ ...locationInfo, tagName: '-', tagGeofence: '-' });
            } else {
                setLocationInfo({
                    ...locationInfo,
                    tagName: res.beacon.beaconName,
                    tagGeofence: res.geofenceInOutState ? res.geofenceInOutState.name : '-',
                });
            }
        },
        reject: res => {
            if (res) {
                setLocationInfo({ ...locationInfo, tagName: '-', tagGeofence: '-' });
            }
        },
    });

    const { promise: getStatusInfo } = useAsync({
        promise: getStatusInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const statusToColor = {};
            if (res.rows) {
                res.rows.forEach(v => {
                    statusToColor[v.statusNum] = v.displayColor;
                });
                setStatusColorInfo(statusToColor);
            }
        },
    });

    const { promise: getAssetStatusLog } = useAsync({
        promise: getAssetStatusLogApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            if (res.rows.length > 0) {
                setStatusLogInfo(res.rows);
            }
        },
    });

    useEffect(() => {
        getStatusInfo();
    }, []);

    useEffect(() => {
        if (params.assetNum) {
            getAssetDetail({ targetKey: params.assetNum });
        }
    }, [params]);

    useEffect(() => {
        if (assetInfo && assetInfo.floorId) {
            setFloorId(assetInfo.floorId);
            getCategoryPropertyInfo({ categoryCode: assetInfo.categoryCode });
            getAssetStatusLog({ targetNum: assetInfo.targetNum });
            //getTransitionInfoByCategory({ categoryCode: assetInfo.categoryCode });
        }
    }, [assetInfo]);

    useEffect(() => {
        if (assetInfo && assetInfo.properties && categoryPropertyInfo.length > 0) {
            let result_map = Object.keys(assetInfo.properties).map(function (key) {
                return { id: String(key), value: assetInfo.properties[key] };
            });
            const tempList = returnCategoryProperty(result_map, categoryPropertyInfo);
            setAssetCategoryPropertyInfo(tempList);
        }
    }, [categoryPropertyInfo]);

    useEffect(() => {
        getSelectedFloorInfo({ floorId: floorId });
        getGeofenceInfo({ floor: floorId });
        getRealtimePresenceInfo({
            targetNum: assetInfo.targetNum,
            floorIds: floorId,
            categoryCodes: assetInfo.categoryCode,
        });
    }, [floorId]);

    const click = e => {
        setOpen(!open);
    };

    const getNewAssetInfo = e => {
        getAssetDetail({ targetKey: params.assetNum });
    };

    const handleOpenAddAssetInspectionPage = open => {
        if (!open) {
            setAssetInspectionPage(false);
        }
    };

    return (
        <div className={'card'}>
            <div className={'mb-3'}>
                <div className="card-header ">
                    <div className="card-header__title">
                        <div className={'title-wrap'}>
                            <h3 className="title__main">{t('Asset scheduled for inspection')}</h3>
                        </div>
                    </div>
                    <div className="card-header__function">
                        <Button
                            className="pnt-btn btn-gray"
                            onClick={e => {
                                history.goBack();
                            }}
                        >
                            {t('Back')}
                        </Button>
                        <Dropdown isOpen={open} toggle={click}>
                            <DropdownToggle className="pnt-btn btn-darkgray btn-icon btn-long btn-dropdown pr-3">
                                {t('Action')}
                                <span className="ml-2 icon-small icon-down m-0" />
                            </DropdownToggle>
                            <DropdownMenu right className={'dropdown-menu-sm'}>
                                <DropdownItem
                                    style={{ whiteSpace: 'normal' }}
                                    onClick={e => {
                                        setOpenedTab('inspectionLog');
                                        setAssetInspectionPage(true);
                                    }}
                                >
                                    {t('Add asset inspection')}
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className={'card-body d-flex w-100 gap-2 d-column-under-900'}>
                <div className={'asset-information-width margin-bottom-under-770 h-100'}>
                    <AssetDetailInformation
                        assetInfo={assetInfo}
                        assetCategoryPropertyInfo={assetCategoryPropertyInfo}
                    />
                </div>
                <div className={'asset-tab-width'}>
                    <Tab
                        handleTabChange={tabId => {
                            setOpenedTab(tabId);
                            if (tabId !== 'inspectionLog') {
                                setAssetInspectionPage(false);
                            }
                        }}
                        activeTabId={openedTab}
                        styles={{ header: { justifyContent: 'center' }, headerItem: { width: '50%' } }}
                        tabs={[
                            {
                                tabId: 'location',
                                title: t('Location'),
                                content: (
                                    <LocationTab
                                        assetInfo={assetInfo}
                                        geofenceInfo={geofenceInfo}
                                        locationInfo={locationInfo}
                                        floorInfo={floorInfo}
                                    />
                                ),
                            },
                            {
                                tabId: 'statusJournal',
                                title: t('Status journal'),
                                content: (
                                    <StatusJournalTab statusColorInfo={statusColorInfo} statusLogInfo={statusLogInfo} />
                                ),
                            },
                            {
                                tabId: 'inspectionLog',
                                title: t('Inspection Log'),
                                content: (
                                    <InspectionLogTab
                                        assetInfo={assetInfo}
                                        assetNum={params.assetNum}
                                        getNewAssetInfo={getNewAssetInfo}
                                        goToAddAssetInspectionPage={assetInspectionPage}
                                        handleOpenAddAssetInspectionPage={handleOpenAddAssetInspectionPage}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default AssetInspectionScheduledDetail;
