import React, { useState } from 'react';
import useTranslation from '../../../../util/hooks/useTranslation';
import Page from '../../../Common/Page';
import RentalStatusSelect from '../Components/RentalStatusSelect';
import Search from '../../Components/Search';
import DepartmentSelect from '../Components/DepartmentSelect';
import Table from '../../../Common/Table';
import Card from '../../../Common/Card';
import useColumns from '../../../../util/hooks/useColumns';
import * as column from '../../util/grid/column';
import useAsync from '../../../../util/hooks/useAsync';
import { useSelector } from 'react-redux';
import { getBorrowedAssetListApi, updateAssetRentalStatusApi } from '../../../../api/asset';

const AssetExport = () => {
    const t = useTranslation('AssetExport');

    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { groupId, groupNum } = useSelector(state => state.UserInfo.userInfo);
    const [tableKey, setTableKey] = useState(0);
    const [selectedSearchOption, setSelectedSearchOption] = useState({
        requestingDepartment: [],
        rentalStatus: ['allocation', 'check_in'],
    });
    const [assetExportList, setAssetExportList] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const columns = useColumns(
        [
            column.assetRentalRequestNum({
                width: 90,
            }),
            column.applicationDate(),
            column.category({
                Cell: ({ value }) => {
                    const category = categoryList.find(({ categoryCode }) => categoryCode === value);
                    if (category) {
                        return <div>{category.categoryNamePath}</div>;
                    } else {
                        return <div>-</div>;
                    }
                },
            }),
            column.assetName(),
            column.assetNumber(),
            column.assetRentalRequestDepart({
                accessor: 'usingDepName',
            }),
            column.assetRentalDepartment({
                accessor: 'ownDepName',
            }),
            column.rentalStatus({
                Cell: ({ value }) => {
                    if (value) {
                        return <div>{t(value, 'AssetRentalRequest')}</div>;
                    } else {
                        return <div>{t('allocation', 'AssetRentalRequest')}</div>;
                    }
                },
            }),
            column.action({
                width: 280,
                Cell: ({ row }) => {
                    const { targetNum, status, reqNum } = row.original;
                    if (status === 'allocation') {
                        return (
                            <div className={'table-btn-container'}>
                                <button
                                    onClick={() =>
                                        updateAssetRentalStatus({
                                            reqNum: reqNum,
                                            targetNum: targetNum,
                                            status: 'cancel_allocation',
                                        })
                                    }
                                    className="pnt-txt txt-border txt-btn-org mr-2"
                                    type={'button'}
                                >
                                    {t('cancel_allocation', 'AssetRentalRequest')}
                                </button>
                                <button
                                    onClick={() =>
                                        updateAssetRentalStatus({
                                            reqNum: reqNum,
                                            targetNum: targetNum,
                                            status: 'check_out',
                                        })
                                    }
                                    className="pnt-txt txt-border txt-btn-org"
                                    type={'button'}
                                >
                                    {t('check_out', 'AssetRentalRequest')}
                                </button>
                            </div>
                        );
                    } else if (status === 'check_in') {
                        return (
                            <div className={'table-btn-container'}>
                                <button
                                    onClick={() =>
                                        updateAssetRentalStatus({
                                            reqNum: reqNum,
                                            targetNum: targetNum,
                                            status: 'confirm_check_in',
                                        })
                                    }
                                    className="pnt-txt txt-border txt-btn-org"
                                    type={'button'}
                                >
                                    {t('confirm_check_in', 'AssetRentalRequest')}
                                </button>
                            </div>
                        );
                    } else {
                        return <div />;
                    }
                },
            }),
        ],
        null,
        [categoryList],
    );

    // 대여상태가 지정, 반납인 자산만 먼저 호출
    const { promise: getBorrowedAssetList, state: assetImportListInfo } = useAsync({
        promise: getBorrowedAssetListApi,
        fixedParam: { pageSize: 20, ownDepNums: groupNum },
        param: { statusList: 'allocation,check_in' },
        keepState: true,
        immediate: true,
        resolve: res => {
            setAssetExportList(res);
        },
    });

    // 자산 상태 변경
    const { promise: updateAssetRentalStatus } = useAsync({
        promise: updateAssetRentalStatusApi,
        resolve: () => {
            getBorrowedAssetList();
        },
    });

    const handleSearchClick = searchInput => {
        setTableKey(prevState => prevState + 1);
        getBorrowedAssetList({
            keyword: searchInput,
            statusList: selectedSearchOption.rentalStatus.length ? selectedSearchOption.rentalStatus.join(',') : null,
            usingDepNums: selectedSearchOption.requestingDepartment.length
                ? selectedSearchOption.requestingDepartment.join(',')
                : null,
        });
    };

    const handleRefreshClick = () => {
        setTableKey(prevState => prevState + 1);
        setSelectedSearchOption({
            requestingDepartment: [],
            rentalStatus: ['allocation', 'check_in'],
        });
        getBorrowedAssetList();
    };

    const handlePageChange = pageIndex => {
        if (assetImportListInfo.request) {
            getBorrowedAssetList({ ...assetImportListInfo.request, page: pageIndex });
        } else {
            getBorrowedAssetList({
                page: pageIndex,
                statusList: 'allocation,check_in',
            });
        }
    };

    return (
        <Page menuPath={[t('Asset Rental Management', 'Menu'), t('Asset Lending', 'Menu')]}>
            <div className="grid-layout-container">
                <Search
                    searchInputPlaceholder={t('Please enter the asset name or asset id.', 'AssetImport')}
                    handleSearchClick={handleSearchClick}
                    handleRefreshClick={handleRefreshClick}
                >
                    <DepartmentSelect
                        title={t('Requesting department', 'AssetRentalProcess')}
                        logInUserDepartment={groupId}
                        selectedOption={selectedSearchOption.requestingDepartment}
                        onChange={selected => {
                            setSelectedSearchOption(prevState => {
                                return { ...prevState, requestingDepartment: selected };
                            });
                        }}
                    />
                    <RentalStatusSelect
                        selectedOption={selectedSearchOption.rentalStatus}
                        onChange={selected => {
                            setSelectedSearchOption(prevState => {
                                return { ...prevState, rentalStatus: selected };
                            });
                        }}
                    />
                </Search>

                <Card
                    header={{
                        title: t('Lending Asset List'),
                    }}
                >
                    <div className={'table-container'}>
                        <Table
                            key={tableKey}
                            data={{ ...assetExportList, pageSize: 20 }}
                            columns={columns}
                            onPageChange={handlePageChange}
                            loading={assetImportListInfo.isLoading}
                        />
                    </div>
                </Card>
            </div>
        </Page>
    );
};

export default AssetExport;
