import React, { useState } from 'react';
import { useSettings } from '../util/useSettings';
import useTranslation from '../../../util/hooks/useTranslation';
import { useDispatch } from 'react-redux';
import { editWidgetSettings } from '../../../reducers/Dashboards/DashboardEdit';
import Label from '../../Common/Label';
import ReactSelect from '../../Common/Select';
import useAsync from '../../../util/hooks/useAsync';
import { getStatusCategoryApi } from '../../../api/asset';
import WidgetSettingModal from '../Components/WidgetSettingModal';

const UtilizationSmallChartSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;
    const dispatch = useDispatch();
    const t = useTranslation();
    const [selectedOption, setSelectedOption] = useState({});
    const [options, setOptions] = useState([]);
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    useAsync({
        promise: getStatusCategoryApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            setOptions(
                res.rows.map(({ statusCategoryNum, statusCategoryName }) => {
                    return { value: statusCategoryNum, label: statusCategoryName };
                }),
            );

            let foundOne;
            if (res.rows && res.rows.length) {
                if (settings.statusCategoryNum) {
                    foundOne =
                        res.rows.find(option => option.statusCategoryNum === settings.statusCategoryNum) || res.rows[0];
                } else {
                    foundOne = res.rows[0];
                }
            } else {
                foundOne = {};
            }
            setSelectedOption({ value: foundOne.statusCategoryNum, label: foundOne.statusCategoryName });
        },
    });

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: {
                        ...customSettings,
                        ...data,
                        selectedOption: selectedOption,
                        intervalTime: data.intervalTime,
                        statusCategoryNum: selectedOption.value,
                        ...updatedWidgetInfo,
                    },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: {
                        ...data,
                        selectedOption: selectedOption,
                        intervalTime: data.intervalTime,
                        statusCategoryNum: selectedOption.value,
                        ...updatedWidgetInfo,
                    },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    const handleChange = selected => {
        setSelectedOption(selected);
    };

    return (
        <WidgetSettingModal
            headerTitle={t('WidgetSetting;Utilization')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <Label
                name={t('WidgetSetting;Option')}
                labelGroupClassName={'w-100'}
                className={'w-100'}
                value={
                    <div className={'pnt-label--group w-100'}>
                        <ReactSelect
                            name={'statusCategoryNum'}
                            options={options}
                            value={selectedOption}
                            onChange={handleChange}
                            className={'w-100'}
                            customOptionStyles={{ width: '100%' }}
                            customControlStyles={{ width: '100%' }}
                        />
                    </div>
                }
            />
        </WidgetSettingModal>
    );
};

export default UtilizationSmallChartSetting;
