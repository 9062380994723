import React, { useMemo, useCallback, useState, useContext, useEffect, useRef } from 'react';
import { GraphView } from 'react-digraph';
import { useSelector } from 'react-redux';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import AddStatusModal from './AddStatusModal';
import AddTransitionModal from './AddTransitionModal';
import useConfirmModal from '../../../../../Common/ConfirmModal/useConfirmModal';
import { FlowSchemeDispatchContext, FlowSchemeStateContext } from '../index';
import _ from 'lodash';
import useAsync from '../../../../../../util/hooks/useAsync';
import { updateFlowSchemeInfosApi } from '../../../../../../api/status';
import { setFlowDrawJson } from '../reducer';
import { ContextMenu, MenuItem, ContextMenuTrigger, showMenu, hideMenu } from 'react-contextmenu';
import styled from 'styled-components';

const NODE_KEY = 'id'; // Allows D3 to correctly update DOM

const CONTEXTMENU_ID = 'actionMenu';

const StyledContextMenu = styled(ContextMenu)`
    &.react-contextmenu {
        background-color: #fff;
        padding: 5px;
        .react-contextmenu-item {
            color: black !important;
            padding: 5px 10px;
            cursor: pointer;
            &:not(:last-child) {
                border-bottom: 1px solid #777;
            }
            &:hover {
                font-weight: bold;
                background-color: rgba(220, 247, 255, 0.5);
            }
        }
    }
`;

const FlowGraph = ({ graphData, selected = {}, toggleTransitionDetailModal }) => {
    const t = useTranslation('Status');
    const selectRef = useRef();
    const [contextMenuItem, setContextMenuItem] = useState([]);
    const dispatch = useContext(FlowSchemeDispatchContext);
    const { flowSchemeInfo, flowStatusList, flowTransitionList } = useContext(FlowSchemeStateContext);
    const { statusListInfo, transitionList } = useSelector(state => state.StatusInfo);
    const { nodes, edges } = useMemo(() => {
        return _.cloneDeep(graphData);
    }, [graphData]);
    const [openDropdown, setOpenDropdown] = useState(false);
    const toggleDropdown = () => {
        setOpenDropdown(!openDropdown);
    };

    const [addStatusModal, setAddStatusModal] = useState(false);
    const toggleAddStatusModal = () => {
        setAddStatusModal(!addStatusModal);
    };

    const { toggleModal: toggleRequestSaveModal, Modal: RequestSaveModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('After selecting and saving the status scheme, you can add status.'),
    });

    const { toggleModal: toggleRequestRequiredModal, Modal: RequestRequiredModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Two or more states are required.'),
    });

    const renderText = useCallback(
        data => {
            const statusList = statusListInfo.rows;
            const statusInfo = statusList.find(v => v.statusNum === data.id);
            if (statusInfo) {
                return (
                    <text className="node-text" textAnchor="middle" xmlns="http://www.w3.org/2000/svg" y={8}>
                        <tspan
                            opacity="1"
                            fill={statusInfo.displayColor}
                            style={{ fontSize: '25px', fontWeight: 'bold' }}
                        >
                            {statusInfo.statusName}
                        </tspan>
                    </text>
                );
            }
            return null;
        },
        [statusListInfo],
    );

    const NodeTypes = useMemo(() => {
        const statusList = statusListInfo.rows;
        return statusList.reduce((acc, curr) => {
            const { statusNum, statusName, displayColor } = curr;
            const key = `s_${statusNum}`;
            acc[statusNum] = {
                typeText: statusName,
                shapeId: `#${key}`,
                shape: (
                    <symbol viewBox="0 0 155 77.5" id={key} key={key}>
                        <rect
                            width="155"
                            height="77.5"
                            rx="5"
                            ry="5"
                            style={{ fill: 'rgb(255, 255, 255)', strokeWidth: 5, stroke: displayColor }}
                        />
                    </symbol>
                ),
                data: curr,
            };
            return acc;
        }, {});
    }, [statusListInfo]);
    const EdgeTypes = useMemo(() => {
        return transitionList.reduce((acc, curr) => {
            const { actionNum, displayColor } = curr;
            const key = `a_${actionNum}`;
            acc[actionNum] = {
                shapeId: `#${key}`,
                shape: (
                    <symbol viewBox={`0 0 90 25`} id={key} key={key}>
                        <rect
                            width={`90`}
                            height="25"
                            rx="5"
                            ry="5"
                            style={{ fill: displayColor, strokeWidth: 3, stroke: displayColor }}
                        />
                    </symbol>
                ),
                data: curr,
            };
            return acc;
        }, {});
    }, [transitionList]);

    const {
        promise: updateFlowSchemeInfos,
        state: { request },
    } = useAsync({
        promise: updateFlowSchemeInfosApi,
        resolve: res => {
            dispatch(setFlowDrawJson(request.flowDrawJson));
        },
    });

    useEffect(() => {
        setTimeout(() => {
            if (document.getElementsByClassName('entities')[0]) {
                document.getElementsByClassName('entities')[0].style.setProperty('display', 'block');
            }
        }, 400);
    }, []);

    return (
        <>
            <div
                id={'graph'}
                className={'h-100'}
                style={{ position: 'relative' }}
                onClick={e => {
                    hideMenu();
                    if (selectRef.current) {
                        const { clientX: x, clientY: y } = e;
                        showMenu({
                            position: {
                                x,
                                y,
                            },
                            id: CONTEXTMENU_ID,
                        });
                        selectRef.current = false;
                    }
                }}
            >
                <GraphView
                    // ref="GraphView"
                    nodeKey={NODE_KEY}
                    nodes={nodes}
                    edges={edges}
                    selected={selected}
                    nodeTypes={NodeTypes}
                    nodeSubtypes={{}}
                    edgeTypes={EdgeTypes}
                    allowMultiselect={false} // true by default, set to false to disable multi select.
                    onSelect={({ nodes, edges, ...rest }, b, c) => {
                        if (nodes.size) {
                            hideMenu();
                            nodes.forEach(({ data, x, y }) => {
                                console.log({ data, x, y });
                            });
                        } else if (edges.size) {
                            edges.forEach(({ data, source, target }) => {
                                const { startStatusNum, endStatusNum } = data;
                                // flowTransitionList.map();
                                const siblingTransitions = flowTransitionList.filter(transition => {
                                    const connectedNodes = [transition.startStatusNum, transition.endStatusNum];
                                    return (
                                        connectedNodes.includes(startStatusNum) && connectedNodes.includes(endStatusNum)
                                    );
                                });
                                if (siblingTransitions.length > 1) {
                                    setContextMenuItem(siblingTransitions);
                                    selectRef.current = true;
                                } else {
                                    toggleTransitionDetailModal(data);
                                }
                            });
                        }
                    }}
                    onUpdateNode={node => {
                        const updatedData = _.cloneDeep(graphData);
                        updatedData.nodes = updatedData.nodes.map(v => {
                            if (node.id === v.id) {
                                return node;
                            }
                            return v;
                        });
                        const { description, flowNum, flowName, isActive } = flowSchemeInfo;
                        // flowDrawJson
                        updateFlowSchemeInfos({
                            flowNum,
                            flowName,
                            isActive,
                            description,
                            flowDrawJson: JSON.stringify(updatedData),
                        });
                    }}
                    // onDeleteNode={this.onDeleteNode}
                    // onSwapEdge={this.onSwapEdge}
                    // onDeleteEdge={this.onDeleteEdge}
                    renderNodeText={renderText}
                    zoomDelay={400}
                    zoomDur={0}
                    showGraphControls={false}
                    rotateEdgeHandle={false}
                    edgeHandleSize={90}
                />
                <Dropdown
                    style={{ position: 'absolute', top: 10, right: 10 }}
                    isOpen={openDropdown}
                    toggle={toggleDropdown}
                >
                    <DropdownToggle className="pnt-btn btn-orange btn-icon-only btn-dropdown">
                        <span className="icon-edit m-0" />
                    </DropdownToggle>
                    <DropdownMenu right className={'dropdown-menu-sm'}>
                        <DropdownItem
                            onClick={e => {
                                // handleActionBtnClick(status);
                                if (flowSchemeInfo.schemeNum) {
                                    toggleAddStatusModal();
                                } else {
                                    toggleRequestSaveModal();
                                }
                            }}
                        >
                            {t('Add Status')}
                        </DropdownItem>
                        <DropdownItem
                            onClick={e => {
                                if (flowStatusList && flowStatusList.length > 1) {
                                    toggleTransitionDetailModal();
                                } else {
                                    toggleRequestRequiredModal();
                                }
                            }}
                        >
                            {t('Add Transition')}
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
            <AddStatusModal isOpen={addStatusModal} toggle={toggleAddStatusModal} />
            <RequestRequiredModal />
            <RequestSaveModal />
            <StyledContextMenu id={CONTEXTMENU_ID}>
                {contextMenuItem.map(transition => {
                    return (
                        <MenuItem
                            key={transition.actionNum}
                            data={transition}
                            onClick={(e, data) => {
                                toggleTransitionDetailModal(data);
                                setContextMenuItem([]);
                            }}
                        >
                            {transition.actionName}
                        </MenuItem>
                    );
                })}
            </StyledContextMenu>
        </>
    );
};

export default FlowGraph;
