import React, { useMemo, useState } from 'react';
import ReactSelect from '../../../Common/Select';
import { useTranslation } from 'react-i18next';
import AssetDetail from '../AssetDetail';
import SearchAutocomplete from '../../../Common/Input/SearchAutocomplete';
import { initialFilterInfo } from '../../../../reducers/FilterInfo';
import { useSelector } from 'react-redux';
import FilterWidget from '../../Filter/FilterWidget';
import Page from '../../../Common/Page';
import { makeFilterParam } from '../../Filter/reducer';
import FilterSearchGroup from '../../../Common/FilterSearchGroup';
import { InputGroup, InputWrap, SearchWrap, SelectGroup } from '../../../Common/FilterSearchGroup/Components/Part';
import SearchButtonArea from '../../../Common/FilterSearchGroup/Components/SearchButtonArea';
import Button from '../../../Common/Button';
import SearchGroup from '../../../Common/FilterSearchGroup/Components/SearchGroup';

const QuickSearch = () => {
    const { t } = useTranslation();
    const { serviceCategoryCodes } = useSelector(state => state.CategoryInfo);
    const searchOptions = useMemo(() => {
        return [
            { value: 'targetName', label: t('Filter;Name') },
            { value: 'targetId', label: t('Filter;ID') },
        ];
    }, [t]);
    const [filterInfo, setFilterInfo] = useState(initialFilterInfo);
    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);
    const [targetInfo, setTargetInfo] = useState(null);
    const [targetNum, setTargetNum] = useState(0);

    const clickSearchBtn = e => {
        if (targetInfo && targetInfo.targetNum) {
            setTargetNum(targetInfo.targetNum);
        }
    };

    const handleSelect = e => {
        setSelectedOption(e);
    };

    const handleTargetNum = selected => {
        if (selected.length > 0) {
            setTargetInfo(selected[0]);
        }
    };

    const enrichCategoryCodes = filterInfo => {
        if (!filterInfo.categoryCodes || !filterInfo.categoryCodes.length) {
            filterInfo.categoryCodes = serviceCategoryCodes;
        }
        return filterInfo;
    };

    const handleFilterConditionChange = selected => {
        const changedFilterInfoCondition = filterInfo.filterInfoCondition.map(conditionInfo => {
            if (conditionInfo.conditionId === selected.conditionId) {
                return { ...conditionInfo, conditionValues: selected.conditionValues };
            }
            return conditionInfo;
        });
        setFilterInfo({ ...filterInfo, filterInfoCondition: changedFilterInfoCondition });
    };

    return (
        <Page menuPath={[t('Menu;Assets'), t('Menu;Quick Search')]} className={'h-100'}>
            <FilterSearchGroup>
                <FilterWidget
                    filterInfo={filterInfo}
                    withContainer={false}
                    changeableFilter={false}
                    handleFilterConditionChange={handleFilterConditionChange}
                />
                <SearchGroup label={t('Search;Search')} className={'bg-brand'}>
                    <SearchWrap>
                        <SelectGroup>
                            <ReactSelect
                                name={'selectInput'}
                                placeholder={t('Search Option')}
                                value={selectedOption}
                                options={searchOptions}
                                onChange={handleSelect}
                                customControlStyles={{
                                    width: '100%',
                                }}
                                customMenuStyles={{ width: '100%' }}
                                customOptionStyles={{ width: '100%' }}
                            />
                        </SelectGroup>
                        <InputWrap>
                            <InputGroup>
                                <SearchAutocomplete
                                    option={selectedOption}
                                    handleSelectedKeyword={handleTargetNum}
                                    filterParam={enrichCategoryCodes(makeFilterParam(filterInfo.filterInfoCondition))}
                                />
                            </InputGroup>
                        </InputWrap>
                    </SearchWrap>
                    <SearchButtonArea>
                        <Button className={'btn-brand btn-icon'} iconClassName={'icon-search'} onClick={clickSearchBtn}>
                            {t('Search;Search')}
                        </Button>
                    </SearchButtonArea>
                </SearchGroup>
            </FilterSearchGroup>
            <AssetDetail quickSearch={true} givenTargetNum={targetNum} />
        </Page>
    );
};

export default QuickSearch;
