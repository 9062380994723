import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const SET_ASSET_LIST = 'assetRentalProcess/SET_ASSET_LIST';
const ADD_LOANABLE_ASSET = 'assetRentalProcess/ADD_LOANABLE_ASSET';
const DELETE_LOANABLE_ASSET = 'assetRentalProcess/DELETE_LOANABLE_ASSET';
const SET_SELECTED_ASSET_RENTAL_PROCESS = 'assetRentalProcess/SET_SELECTED_ASSET_RENTAL_PROCESS';
const SET_BORROWED_ASSET = 'assetRentalProcess/SET_BORROWED_ASSET';

export const { setAssetList, addLoanableAsset, deleteLoanableAsset, setSelectedAssetRentalProcess, setBorrowedAsset } =
    createActions(
        {
            SET_ASSET_LIST: assetList => assetList,
            ADD_LOANABLE_ASSET: selectedAssetProcess => selectedAssetProcess,
            DELETE_LOANABLE_ASSET: selectedAssetProcess => selectedAssetProcess,
            SET_SELECTED_ASSET_RENTAL_PROCESS: selectedRentalProcess => selectedRentalProcess,
            SET_BORROWED_ASSET: borrowedAssetList => borrowedAssetList,
        },
        {
            prefix: 'assetRentalProcess',
        },
    );

export const initialState = {
    assetList: {
        rows: [],
        totalPage: 1,
        totalCount: 0,
    },
    addedAssetList: {
        rows: [],
        totalPage: 1,
        totalCount: 0,
        pageSize: 20,
    },
    selectedAssetRentalProcess: null,
    checkedInAssetCount: null,
};

const calculatePageSize = state => {
    if (state.addedAssetList.rows.length % state.addedAssetList.pageSize === 0) {
        state.addedAssetList.totalPage = Math.trunc(state.addedAssetList.rows.length / state.addedAssetList.pageSize);
    } else {
        state.addedAssetList.totalPage =
            Math.trunc(state.addedAssetList.rows.length / state.addedAssetList.pageSize) + 1;
    }
};

const assetRentalProcessViewReducer = handleActions(
    {
        [SET_ASSET_LIST]: (state, action) => {
            return produce(state, draft => {
                draft.assetList = {
                    ...action.payload,
                    rows: action.payload.rows.map(asset => {
                        return { ...asset, type: 'loanable' };
                    }),
                };
            });
        },
        [ADD_LOANABLE_ASSET]: (state, action) => {
            return produce(state, draft => {
                draft.addedAssetList.rows.push({ ...action.payload, type: 'selected' });
                draft.addedAssetList.totalCount += 1;
                calculatePageSize(draft);
                // const index = draft.assetList.rows.findIndex(asset => asset.targetNum === action.payload.targetNum);
                //
                // draft.assetList.rows.splice(index, 1);
            });
        },
        [DELETE_LOANABLE_ASSET]: (state, action) => {
            return produce(state, draft => {
                // draft.assetList.rows.push({ ...action.payload, type: 'loanable' });

                const index = draft.addedAssetList.rows.findIndex(
                    asset => asset.targetNum === action.payload.targetNum,
                );
                draft.addedAssetList.rows.splice(index, 1);
                draft.addedAssetList.totalCount -= 1;
                calculatePageSize(draft);
            });
        },
        [SET_SELECTED_ASSET_RENTAL_PROCESS]: (state, action) => {
            return produce(state, draft => {
                draft.selectedAssetRentalProcess = action.payload;
            });
        },
        [SET_BORROWED_ASSET]: (state, action) => {
            return produce(state, draft => {
                const rows = action.payload.rows.map(asset => {
                    return { ...asset, type: 'selected' };
                });
                draft.addedAssetList = { ...action.payload, rows: rows, pageSize: 20 };
                draft.checkedInAssetCount = action.payload.rows.filter(v => v.status === 'check_in').length;

                calculatePageSize(draft);
            });
        },
    },
    initialState,
);

export default assetRentalProcessViewReducer;
