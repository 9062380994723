import React, { useState } from 'react';
import Card from '../Components/WidgetCard';
import { useSettings } from '../util/useSettings';
import useMonitor from '../util/useMonitor';
import AvailableBarChart from './Components/AvailableBarChart';
import { getCategoryStatisticApi } from '../../../api/dashboard';
import useAsync from '../../../util/hooks/useAsync';
import { getStatusListInfoApi } from '../../../api/status';
import { useSelector } from 'react-redux';

const defaultData = {
    result: 'success',
    count: null,
    code: '0',
    rows: [
        {
            comNum: 1,
            statusNum: 3,
            statusName: 'Broken',
            statusCount: 20,
            categoryCode: 'ASSET',
            categoryName: 'Asset',
            stayTime: 0,
            endTime: 0,
        },
        {
            comNum: 1,
            statusNum: 5,
            statusName: 'open',
            statusCount: 3,
            categoryCode: 'ASSET',
            categoryName: 'Asset',
            stayTime: 0,
            endTime: 0,
        },
        {
            comNum: 1,
            statusNum: 10,
            statusName: 'test',
            statusCount: 3,
            categoryCode: 'ASSET',
            categoryName: 'Asset',
            stayTime: 0,
            endTime: 0,
        },
        {
            comNum: 1,
            statusNum: 3,
            statusName: 'Broken',
            statusCount: 1,
            categoryCode: 'PEOPLE',
            categoryName: 'People',
            stayTime: 0,
            endTime: 0,
        },
    ],
};

const AvailableAsset = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const [statusList, setStatusList] = useState([]);
    const { mode } = useSelector(state => state.DashboardFrame);
    const { filterParam } = useSelector(state => state.FilterInfo);

    useAsync({
        promise: getStatusListInfoApi,
        immediate: true,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setStatusList(res.rows);
        },
    });

    const { data } = useMonitor({
        config,
        dynamicParam: filterParam,
        defaultData: mode === 'monitoring' ? null : defaultData,
        fetchData: getCategoryStatisticApi,
        makeData: ({ newData }) => {
            const resultData = [];
            if (newData && newData.rows) {
                const tempData = newData.rows.reduce((acc, curr) => {
                    if (acc[curr.categoryCode]) {
                        acc[curr.categoryCode][curr.statusNum] = curr.statusCount;
                    } else {
                        acc[curr.categoryCode] = { name: curr.categoryName, [curr.statusNum]: curr.statusCount };
                    }
                    return acc;
                }, {});

                for (let categoryCode in tempData) {
                    resultData.push(tempData[categoryCode]);
                }
            }
            return resultData;
        },
    });

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <AvailableBarChart data={data} statusList={statusList} />
            {children}
        </Card>
    );
};

export default AvailableAsset;
