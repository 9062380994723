import React, { useEffect, useState } from 'react';
import { getAssetStatusListApi } from '../../../../api/asset';
import useAsync from '../../../../util/hooks/useAsync';
import SearchableSelectWithOption from '../../Components/Select/SearchableSelectWithOption';

const AssetStatusSearchableSelect = ({ selected, changeable, handleChange, hideOption }) => {
    const [assetStatusList, setAssetStatusList] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    useAsync({
        promise: getAssetStatusListApi,
        immediate: true,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setAssetStatusList(
                res.rows.map(v => {
                    return { ...v, statusCategoryNums: v.statusCategoryNum.toString() };
                }),
            );
        },
    });

    useEffect(() => {
        if (!selected || !selected.length) {
            setSelectedOption([]);
        } else if (assetStatusList.length) {
            setSelectedOption(selected.map(v => v.toString()));
        }
    }, [selected, assetStatusList]);

    const handleSelectedOptionChange = (selected, changeable) => {
        handleChange(selected, changeable);
        setSelectedOption(selected.map(v => v.statusCategoryNums));
    };

    return (
        <SearchableSelectWithOption
            data={assetStatusList}
            onChange={handleSelectedOptionChange}
            title={'Asset Status'}
            selected={selectedOption}
            labelKey={'statusCategoryName'}
            valueKey={'statusCategoryNums'}
            className={'mr-2 mb-2 mt-2'}
            changeable={changeable}
            hideOption={hideOption}
        />
    );
};

export default AssetStatusSearchableSelect;
