import React from 'react';
import { Route } from 'react-router-dom';
import { ErrorHandleSwitch } from '../Components/Router';
import Scheme from './Scheme';
import AllocateAssets from './AllocateAssets';
import FlowScheme from './FlowScheme';
import FlowSchemeDetail from './FlowScheme/FlowSchemeDetail';
import AllocateAssetsCreate from './AllocateAssets/AllocateAssetsCreate';
import AllocateAssetsView from './AllocateAssets/AllocateAssetsView';

const Status = ({ match }) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/flow-scheme`} component={FlowScheme} />
            <Route exact path={`${match.path}/flow-scheme/:flowNum([0-9]+)`} component={FlowSchemeDetail} />
            <Route exact path={`${match.path}/flow-scheme/create`} component={FlowSchemeDetail} />
            <Route exact path={`${match.path}/scheme`} component={Scheme} />
            <Route exact path={`${match.path}/allocate`} component={AllocateAssets} />
            <Route exact path={`${match.path}/allocate/create`} component={AllocateAssetsCreate} />
            <Route exact path={`${match.path}/allocate/:flowNum([0-9]+)`} component={AllocateAssetsView} />
        </ErrorHandleSwitch>
    );
};

export default Status;
